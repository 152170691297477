import {combineReducers} from 'redux';

import app from '../modules/app';
import geometricElement from '../modules/geometricElements';
import users from '../modules/users';
import configurationParameter from '../modules/configurationParameter';
import location from '../modules/location';
import attachedFile from '../modules/attachedFile';

const rootReducer = combineReducers ({
    app: app.reducer,
    geometricElement: geometricElement.reducer,
    users: users.reducer,
    configurationParameter: configurationParameter.reducer,
    location: location.reducer,
    attachedFile: attachedFile.reducer
});

export default rootReducer;