import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import * as attachedFileSelectors from '../../attachedFile/selectors';
import { connect } from 'react-redux';
import ExternalImage from '../../common/components/ExternalImage';

const mapStateToProps = function (state) {

    return {
        allAttachedFiles: attachedFileSelectors.getTotalGeneralAttachedFiles(state)
    }
}

const GeographicalViewerLegend = ({ layers, legendShow, closeLegend, ...props }) => {

    let allLayers = [];

    // This must be done in order to maintain the layers order in the legend. 
    layers.reverse().forEach(newLayer => {
        let layerIndex = allLayers.findIndex(layer => layer.get('title') === newLayer.get('title'));
        if (layerIndex === -1 &&
            newLayer.get('legendType')
        ) {
            allLayers.push(newLayer);
        } else {
            if (layerIndex !== -1) {
                if (!allLayers[layerIndex].getVisible() && newLayer.getVisible()) {
                    allLayers[layerIndex] = newLayer;
                }
            }
        }
    });

    // Only show visible layers and layers with legend
    allLayers = allLayers.filter(layer => layer.getVisible() && layer.get('legendType') !== "LEGEND_TYPE_NONE");
    let sourceLayers = null;

    return (
        <div className="legend" style={!legendShow ? {display: 'none'} : {}}>
            <h3 className="col-12">
                <FormattedMessage id="project.elements.legend" />
            </h3>
            <FontAwesomeIcon
                id={"closeLegend"}
                icon={faTimes}
                style={{ cursor: 'pointer' }}
                className="btn-link"
                onClick={closeLegend}
            />
            <div className="accordion" id="legendAccordion">
                {allLayers.map((layer, index) => {
                    sourceLayers = layer.getSource().getParams().layers
                    if (layer.get('showAllLayersOnLegend')) {
                        if (sourceLayers && sourceLayers.length) {
                            sourceLayers = sourceLayers[0].split(',')
                        }
                    } else {
                        if (sourceLayers && sourceLayers.length) {
                            sourceLayers = sourceLayers[0].split(',')
                            sourceLayers = [sourceLayers[0]];
                        }
                    }

                    let legendImages = [];

                    if (layer.get('legendType') === "LEGEND_TYPE_URL") {
                        legendImages[legendImages.length] = <ExternalImage src={layer.get('legendTypeValue')} alt="" />;
                    } else {
                        if (layer.get('legendType') === "LEGEND_TYPE_IMAGE") {
                            //eslint-disable-next-line
                            let generalAttachedFile = props.allAttachedFiles.filter(generalAttachedFile => generalAttachedFile.id == layer.get('legendTypeValue'));
                            if (generalAttachedFile.length) {
                                let image = <ExternalImage className="d-block" src={generalAttachedFile[0].fileUrl} alt="" />
                                legendImages[legendImages.length] = image;
                            } else {
                                legendImages[legendImages.length] = <ExternalImage className="d-block" src="" alt="" />;
                            }
                        } else {
                            if (typeof layer.get('source').getLegendUrl === 'function') {
                                if (sourceLayers) {
                                    sourceLayers.forEach((sourceLayer, imageIndex) => {
                                        legendImages[legendImages.length] = <ExternalImage
                                            key={sourceLayer + imageIndex + index}
                                            className="d-block"
                                            src={layer.get('source').getLegendUrl(null,
                                                {
                                                    layer: sourceLayer,
                                                    styles: layer.get('source').params_.styles
                                                })}
                                            alt=""
                                        />
                                    });

                                } else {
                                    legendImages[legendImages.length] = <ExternalImage
                                        className="d-block"
                                        src={layer.get('source').getLegendUrl(null,
                                            {
                                                layer: layer.getSource().getParams().layers,
                                                styles: layer.get('source').params_.styles
                                            })
                                        }
                                        alt=""
                                    />
                                }
                            } else {
                                legendImages[legendImages.length] = <ExternalImage className="d-block" src="" alt="" />;;
                            }
                        }
                    }

                    return (
                        <div className="card" key={layer.get('title') + index}>
                            <div className="card-header" id={'layer' + index}
                                data-toggle="collapse"
                                data-target={'#collapse' + index}
                                aria-controls={'collapse' + index}
                            >
                                {layer.get('title')}
                            </div>

                            <div id={'collapse' + index} className="collapse" aria-labelledby={'layer' + index} data-parent="#legendAccordion">
                                <div className="card-body">
                                    {legendImages.map(image => image)}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(injectIntl(GeographicalViewerLegend));