import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedDate, injectIntl, FormattedTime } from 'react-intl';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors';
import * as userSelectors from '../../users/selectors';
import * as actions from '../actions';
import * as locationActions from '../../location/actions';
import * as locationSelectors from '../../location/selectors';
import configurationParameter from '../../configurationParameter';
import { groupBy } from 'lodash';
import InternationalizationRender, { getInternationalization } from '../../app/components/InternationalizationRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faImages, faEdit, faMapMarkerAlt, faTrashAlt, faChevronCircleDown, faChevronCircleUp,
    faSearch, faPlus, faFilter, faTimes, faLink, faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import jQuery from 'jquery';
import { DeleteDialog } from '../../common';
import AddGeometricElementFile from './AddGeometricElementFile';
import GeometricElementFileGallery from './GeometricElementFileGallery';
import { Login, SignUp } from '../../users';
import GeometricElementsByGeometricElementTypeSearch from './GeometricElementsByGeometricElementTypeSearch';
import AddModifyGeometricElementRelation from './AddModifyGeometricElementRelation';
import GeometricElementFilesTable from './GeometricElementFilesTable';

const mapStateToProps = function (state) {
    return ({
        authenticatedUser: userSelectors.getUser(state),
        listAllAttributes: selectors.getTotalAttributeType(state),
        allCodes: selectors.getAllCodes(state),
        language: appSelectors.getLanguage(state),
        geometricElementType: selectors.getGeometricElementType(state),
        geometricElements: selectors.getGeometricElementsByElementType(state),
        configurationParameters: configurationParameter.selectors.getTotalConfigurationParameters(state),
        provinces: locationSelectors.getProvinces(state),
        municipalities: locationSelectors.getMunicipalitiesByProvince(state),
        parishes: locationSelectors.getParishesByMunicipality(state)
    });
}

class GeometricElementsByElementType extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            authenticatedUser: null,
            geometricElements: null,
            expandedRows: [],
            errorModalVisible: false,
            searchModalVisible: false,
            backendErrors: null,
            currentPage: 1,
            currentSize: 10,
            currentSortField: 'id',
            currentSortOrder: 'asc',
            deleteGeometricElementFileDialogShow: false,
            deleteGeometricElementFileName: "",
            deleteGeometricElementFileUuid: "",
            deleteGeometricElementDialogShow: false,
            deleteGeometricElementDescription: "",
            deleteGeometricElementId: "",
            addGeometricElementFileGeometricElement: null,
            addGeometricElementFileModalShow: false,
            geometricElementFileGalleryModalShow: false,
            geometricElementFileGalleryFiles: null,
            loginModalShow: false,
            signUpModalShow: false,
            geometricElementType: null,
            provinceNameFilter: "",
            municipalityNameFilter: "",
            parishNameFilter: "",
            relateGeometricElementsModalVisible: false,
            geometricElementToRelate: null,
            geometricElementRelationToModify: null
        }
    }

    setLocationFiltersName = (province, municipality, parish) => {
        this.setState({
            provinceNameFilter: province,
            municipalityNameFilter: municipality,
            parishNameFilter: parish
        });
    }

    componentDidMount() {
        jQuery('#mainFooter').scrollTop(0);
        this.props.dispatch(actions.findGeometricElementTypeById(this.props.match.params.id, () => {
            this.setState({ geometricElementType: this.props.geometricElementType });
        }));

        this.props.dispatch(locationActions.findAllProvinces());
    }

    componentDidUpdate(prevProps) {
        if (this.props.allCodes && this.props.language) {

            jQuery('.modal-dialog').draggable({
                cursor: 'move',
                handle: '.modal-header'
            });

            if (this.state.geometricElements) {
                if (this.props.geometricElements !== this.state.geometricElements) {
                    this.setState({ geometricElements: this.props.geometricElements });
                }
            }

            if (prevProps.match.url !== this.props.match.url) {
                this.setState({ geometricElements: null, geometricElementType: null }, () => {
                    this.props.dispatch(actions.findGeometricElementTypeById(this.props.match.params.id, () => {
                        this.setState({ geometricElementType: this.props.geometricElementType });
                    }));
                });
            }

            if (this.props.authenticatedUser !== this.state.authenticatedUser) {
                this.setState({ authenticatedUser: this.props.authenticatedUser });
            }
        }

    }

    render() {

        let setBackendErrors = (backendErrors) => {
            this.setState({ backendErrors: backendErrors });
        }

        let RELATE_ELEMENTS_TO_PARISHES;
        let CAN_QUERY_GEOMETRIC_ELEMENTS;
        let canAddGeometricElement;

        if (this.props.configurationParameters) {
            RELATE_ELEMENTS_TO_PARISHES = this.props.configurationParameters.filter(
                configurationParameter => configurationParameter.code === "RELATE_ELEMENTS_TO_PARISHES")[0].value;
            CAN_QUERY_GEOMETRIC_ELEMENTS = this.props.configurationParameters.filter(
                configurationParameter => configurationParameter.code === "ENABLE_GEOMETRIC_ELEMENT_QUERY");
            canAddGeometricElement = this.props.configurationParameters.filter(
                configurationParameter => configurationParameter.code === "ENABLE_GEOMETRIC_ELEMENT_INSERTION");
            if (canAddGeometricElement.length > 0) {
                canAddGeometricElement = canAddGeometricElement[0].value;
                canAddGeometricElement = (canAddGeometricElement === 'true');
            } else {
                canAddGeometricElement = false;
            }

        }

        let filterPanelRender = () => {
            let {
                sortField,
                sortOrder,
                geometricElementId,
                description,
                attributes,
                laterCreationDate,
                beforeCreationDate,
                laterModificationDate,
                beforeModificationDate,
                isPublic,
                isReviewed,
                internalComments,
                ownerLogin,
                province,
                municipality,
                parish
            } = this.state.geometricElements;

            let {
                provinceNameFilter,
                municipalityNameFilter,
                parishNameFilter
            } = this.state;

            if ((geometricElementId && geometricElementId !== "") || (description && description !== "")
                || (attributes && Object.keys(attributes).length > 0)
                || (laterCreationDate && laterCreationDate !== "") || (beforeCreationDate && beforeCreationDate !== "")
                || (laterModificationDate && laterModificationDate !== "") || (beforeModificationDate && beforeModificationDate !== "")
                || (
                    isPublic && isPublic !== "" && this.state.authenticatedUser &&
                    !(this.state.authenticatedUser.userRoleDto.code === "USER" && !this.props.history.location.pathname.includes("myElements"))
                )
                || (isReviewed && isReviewed !== "")
                || (internalComments && internalComments !== "") || (ownerLogin && ownerLogin !== "")
                || (province && province !== "") || (municipality && municipality !== "") || (parish && parish !== "")
            ) {
                return (
                    <div className="filtersPanel">
                        <span className="filterPanelTitle">
                            <FontAwesomeIcon icon={faFilter} />
                            &nbsp;
                            <FormattedMessage id="project.common.filters" />
                        </span>

                        <button id="clearFilters"
                            className="actionButton close filterPanelClear"
                            type="button"
                            data-toggle="tooltip"
                            data-placement="right"
                            title={this.props.intl.formatMessage({ id: "project.common.filters.clear" })}
                            onClick={() => {
                                let id = this.props.match.params.id;
                                if (this.state.authenticatedUser) {
                                    if (this.state.authenticatedUser.userRoleDto.code === "ADMIN") {
                                        if (this.props.history.location.pathname.includes("myElements")) {
                                            this.props.dispatch(actions.findAdminGeometricElementsByElementTypeId(id, 0, 10, sortField, sortOrder, null,
                                                this.state.authenticatedUser.id, null, null, null, null, null, null, null, null, null, null,
                                                null, null, null, () => {
                                                    this.setState({ geometricElements: this.props.geometricElements });
                                                }));
                                        } else {
                                            this.props.dispatch(actions.findAdminGeometricElementsByElementTypeId(id, 0, 10, sortField, sortOrder, null,
                                                null, null, null, null, null, null, null, null, null, null, null, null,
                                                null, null, () => {
                                                    this.setState({ geometricElements: this.props.geometricElements });
                                                }))
                                        }
                                    }
                                    else {
                                        if (this.props.history.location.pathname.includes("myElements")) {
                                            this.props.dispatch(actions.findUserGeometricElementsByElementTypeId(id, 0, 10, sortField, sortOrder, null,
                                                this.state.authenticatedUser.id, null, null, null, null, null, null, null, null,
                                                null, null, null, () => {
                                                    this.setState({ geometricElements: this.props.geometricElements });
                                                }));
                                        } else {
                                            this.props.dispatch(actions.findUserGeometricElementsByElementTypeId(id, 0, 10, sortField, sortOrder, true,
                                                null, null, null, null, null, null, null, null, null, null, null, null, () => {
                                                    this.setState({ geometricElements: this.props.geometricElements });
                                                }));
                                        }
                                    }

                                } else {
                                    this.props.dispatch(actions.findPublicGeometricElementsByElementTypeId(id, 0, 10, sortField, sortOrder, null, null,
                                        null, null, null, null, null, null, null, null, () => {
                                            this.setState({ geometricElements: this.props.geometricElements });
                                        }));
                                }
                            }}>
                            <FontAwesomeIcon icon={faTimes} size="sm" />
                        </button>

                        {
                            geometricElementId && geometricElementId !== "" ?
                                <span className="filterPanelItem">
                                    <span>
                                        Id:
                                    </span>
                                    &nbsp;
                                    <span>
                                        {geometricElementId}
                                    </span>
                                </span>
                                : ""
                        }

                        {
                            description && description !== "" ?
                                <span className="filterPanelItem">
                                    <span>
                                        <FormattedMessage id="project.elements.description" />:
                                    </span>
                                    &nbsp;
                                    <span>
                                        {description}
                                    </span>
                                </span>
                                : ""
                        }

                        {
                            attributes && Object.keys(attributes).length > 0 ?
                                Object.keys(attributes).map(attribute => {
                                    if (attributes[attribute] !== "") {
                                        let attributeCode = Object.values(this.props.listAllAttributes).filter(attr => attr.id === Number(attribute));
                                        attributeCode = attributeCode[0].code.code;
                                        return (
                                            <span className="filterPanelItem">
                                                <span>
                                                    {
                                                        getInternationalization(this.props.language, attributeCode, this.props.allCodes)
                                                    }:
                                                </span>
                                                &nbsp;
                                                <span>
                                                    {getInternationalization(this.props.language, attributes[attribute], this.props.allCodes)}
                                                </span>
                                            </span>
                                        )
                                    } else {
                                        return null;
                                    }
                                })
                                : ""
                        }

                        {
                            province && province !== "" ?
                                <span className="filterPanelItem">
                                    <span>
                                        <FormattedMessage id="project.common.location.province" />:
                                    </span>
                                    &nbsp;
                                    <span>
                                        {provinceNameFilter}
                                    </span>
                                </span>
                                : ""
                        }

                        {
                            municipality && municipality !== "" ?
                                <span className="filterPanelItem">
                                    <span>
                                        <FormattedMessage id="project.common.location.municipality" />:
                                    </span>
                                    &nbsp;
                                    <span>
                                        {municipalityNameFilter}
                                    </span>
                                </span>
                                : ""
                        }

                        {
                            parish && parish !== "" ?
                                <span className="filterPanelItem">
                                    <span>
                                        <FormattedMessage id="project.common.location.parish" />:
                                    </span>
                                    &nbsp;
                                    <span>
                                        {parishNameFilter}
                                    </span>
                                </span>
                                : ""
                        }

                        {
                            laterCreationDate && laterCreationDate !== "" ?
                                <span className="filterPanelItem">
                                    <span>
                                        <FormattedMessage id="project.elements.moreCreationDate" />:
                                    </span>
                                    &nbsp;
                                    <span>
                                        <FormattedDate value={laterCreationDate} />
                                    </span>
                                </span>
                                : ""
                        }

                        {
                            beforeCreationDate && beforeCreationDate !== "" ?
                                <span className="filterPanelItem">
                                    <span>
                                        <FormattedMessage id="project.elements.lessCreationDate" />:
                                    </span>
                                    &nbsp;
                                    <span>
                                        <FormattedDate value={beforeCreationDate} />
                                    </span>
                                </span>
                                : ""
                        }

                        {
                            laterModificationDate && laterModificationDate !== "" ?
                                <span className="filterPanelItem">
                                    <span>
                                        <FormattedMessage id="project.elements.moreModificationDate" />:
                                    </span>
                                    &nbsp;
                                    <span>
                                        <FormattedDate value={laterModificationDate} />
                                    </span>
                                </span>
                                : ""
                        }

                        {
                            beforeModificationDate && beforeModificationDate !== "" ?
                                <span className="filterPanelItem">
                                    <span>
                                        <FormattedMessage id="project.elements.lessModificationDate" />:
                                    </span>
                                    &nbsp;
                                    <span>
                                        <FormattedDate value={beforeModificationDate} />
                                    </span>
                                </span>
                                : ""
                        }

                        {
                            isPublic && isPublic !== "" && this.state.authenticatedUser
                                && this.state.authenticatedUser &&
                                !(this.state.authenticatedUser.userRoleDto.code === "USER" && !this.props.history.location.pathname.includes("myElements"))
                                ?
                                <span className="filterPanelItem">
                                    <span>
                                        <FormattedMessage id="project.elements.isPublic" />:
                                            </span>
                                            &nbsp;
                                            <span>
                                        {isPublic === "true"
                                            ?
                                            <FormattedMessage id="project.common.yes" />
                                            :
                                            <FormattedMessage id="project.common.no" />
                                        }
                                    </span>
                                </span>
                                :
                                ""
                        }

                        {
                            isReviewed && isReviewed !== "" ?
                                <span className="filterPanelItem">
                                    <span>
                                        <FormattedMessage id="project.elements.isReviewed" />:
                                    </span>
                                    &nbsp;
                                    <span>
                                        {this.state.geometricElements.isReviewed &&
                                            this.state.geometricElements.isReviewed === "true" ?
                                            <FormattedMessage id="project.common.yes" />
                                            :
                                            <FormattedMessage id="project.common.no" />}
                                    </span>
                                </span>
                                : ""
                        }

                        {
                            internalComments && internalComments !== "" ?
                                <span className="filterPanelItem">
                                    <span>
                                        <FormattedMessage id="project.elements.internalComments" />:
                                    </span>
                                    &nbsp;
                                    <span>
                                        {this.state.geometricElements.internalComments}
                                    </span>
                                </span>
                                : ""
                        }

                        {
                            ownerLogin && ownerLogin !== "" ?
                                <span className="filterPanelItem">
                                    <span>
                                        <FormattedMessage id="project.elements.owner" />:
                                    </span>
                                    &nbsp;
                                    <span>
                                        {this.state.geometricElements.ownerLogin}
                                    </span>
                                </span>
                                : ""
                        }

                    </div>
                );
            } else {
                return null
            }
        }

        let listAttributes;

        if (this.state.geometricElementType)
            listAttributes = this.state.geometricElementType.listAttributeType;

        let handleBtnClick = (row) => {
            if (!this.state.expandedRows.includes(row.id)) {
                this.setState({ expandedRows: [row.id] });
            } else {
                this.setState({ expandedRows: [] });
            }
        }

        if (this.state.geometricElementType && listAttributes && this.props.allCodes &&
            (CAN_QUERY_GEOMETRIC_ELEMENTS ||
                (this.state.authenticatedUser && this.state.authenticatedUser.userRoleDto.code === "ADMIN"))
        ) {

            let handleTableChange = (page, sizePerPage, sortField, sortOrder) => {
                let pageToSearch = page > 0 ? page - 1 : 0;
                if (!this.state.geometricElements) {
                    if (this.state.authenticatedUser) {
                        if (this.state.authenticatedUser.userRoleDto.code === "ADMIN") {
                            if (this.props.match.url.includes("myElements")) {
                                this.props.dispatch(actions.findAdminGeometricElementsByElementTypeId(
                                    this.props.match.params.id, pageToSearch, sizePerPage, sortField,
                                    sortOrder, null, this.state.authenticatedUser.id,
                                    null, null, null, null, null, null, null, null, null, null, null,
                                    null, null, () => {
                                        this.setState({ geometricElements: this.props.geometricElements });
                                    }));
                            } else {
                                this.props.dispatch(actions.findAdminGeometricElementsByElementTypeId(
                                    this.props.match.params.id, pageToSearch, sizePerPage, sortField,
                                    sortOrder, null, null, null, null, null, null, null, null, null, null, null, null, null,
                                    null, null, () => {
                                        this.setState({ geometricElements: this.props.geometricElements });
                                    }));
                            }
                        }
                        else {
                            if (this.props.match.url.includes("myElements")) {
                                this.props.dispatch(actions.findUserGeometricElementsByElementTypeId(
                                    this.props.match.params.id, pageToSearch, sizePerPage, sortField,
                                    sortOrder, null, this.state.authenticatedUser.id, null, null, null, null,
                                    null, null, null, null, null, null, null, () => {
                                        this.setState({ geometricElements: this.props.geometricElements });
                                    }));
                            } else {
                                this.props.dispatch(actions.findUserGeometricElementsByElementTypeId(
                                    this.props.match.params.id, pageToSearch, sizePerPage, sortField,
                                    sortOrder, true, null, null, null, null, null, null, null, null, null, null,
                                    null, null, () => {
                                        this.setState({ geometricElements: this.props.geometricElements });
                                    }));
                            }
                        }
                    } else {
                        this.props.dispatch(actions.findPublicGeometricElementsByElementTypeId(
                            this.props.match.params.id, pageToSearch, sizePerPage, sortField,
                            sortOrder, null, null, null, null, null, null, null, null, null, null,
                            () => {
                                this.setState({ geometricElements: this.props.geometricElements });
                            }));
                    }
                } else {
                    if (this.state.authenticatedUser) {
                        if (this.state.authenticatedUser.userRoleDto.code === "ADMIN") {
                            if (this.props.match.url.includes("myElements")) {
                                this.props.dispatch(actions.findAdminGeometricElementsByElementTypeId(
                                    this.props.match.params.id, pageToSearch, sizePerPage, sortField,
                                    sortOrder, this.state.geometricElements.isPublic, this.state.authenticatedUser.id,
                                    this.state.geometricElements.geometricElementId, this.state.geometricElements.description,
                                    this.state.geometricElements.beforeCreationDate, this.state.geometricElements.laterCreationDate,
                                    this.state.geometricElements.beforeModificationDate, this.state.geometricElements.laterModificationDate,
                                    this.state.geometricElements.isReviewed, this.state.geometricElements.internalComments,
                                    null, this.state.geometricElements.attributes, this.state.geometricElements.province,
                                    this.state.geometricElements.municipality, this.state.geometricElements.parish, () => {
                                        this.setState({ geometricElements: this.props.geometricElements })
                                    }));
                            } else {
                                this.props.dispatch(actions.findAdminGeometricElementsByElementTypeId(
                                    this.props.match.params.id, pageToSearch, sizePerPage, sortField,
                                    sortOrder, this.state.geometricElements.isPublic, null, this.state.geometricElements.geometricElementId,
                                    this.state.geometricElements.description, this.state.geometricElements.beforeCreationDate,
                                    this.state.geometricElements.laterCreationDate, this.state.geometricElements.beforeModificationDate,
                                    this.state.geometricElements.laterModificationDate, this.state.geometricElements.isReviewed,
                                    this.state.geometricElements.internalComments, this.state.geometricElements.ownerLogin,
                                    this.state.geometricElements.attributes, this.state.geometricElements.province,
                                    this.state.geometricElements.municipality, this.state.geometricElements.parish, () => {
                                        this.setState({ geometricElements: this.props.geometricElements })
                                    }));
                            }
                        }
                        else {
                            if (this.props.match.url.includes("myElements")) {
                                this.props.dispatch(actions.findUserGeometricElementsByElementTypeId(
                                    this.props.match.params.id, pageToSearch, sizePerPage, sortField,
                                    sortOrder, this.state.geometricElements.isPublic, this.state.authenticatedUser.id, this.state.geometricElements.geometricElementId, this.state.geometricElements.description,
                                    this.state.geometricElements.beforeCreationDate, this.state.geometricElements.laterCreationDate,
                                    this.state.geometricElements.beforeModificationDate, this.state.geometricElements.laterModificationDate,
                                    this.state.geometricElements.isReviewed, this.state.geometricElements.attributes,
                                    this.state.geometricElements.province, this.state.geometricElements.municipality,
                                    this.state.geometricElements.parish, () => {
                                        this.setState({ geometricElements: this.props.geometricElements })
                                    }));
                            } else {
                                this.props.dispatch(actions.findUserGeometricElementsByElementTypeId(
                                    this.props.match.params.id, pageToSearch, sizePerPage, sortField,
                                    sortOrder, true, null, this.state.geometricElements.geometricElementId, this.state.geometricElements.description,
                                    this.state.geometricElements.beforeCreationDate, this.state.geometricElements.laterCreationDate,
                                    this.state.geometricElements.beforeModificationDate, this.state.geometricElements.laterModificationDate,
                                    this.state.geometricElements.isReviewed, this.state.geometricElements.attributes,
                                    this.state.geometricElements.province, this.state.geometricElements.municipality,
                                    this.state.geometricElements.parish, () => {
                                        this.setState({ geometricElements: this.props.geometricElements })
                                    }));
                            }
                        }
                    } else {
                        this.props.dispatch(actions.findPublicGeometricElementsByElementTypeId(
                            this.props.match.params.id, pageToSearch, sizePerPage, sortField,
                            sortOrder, this.state.geometricElements.geometricElementId, this.state.geometricElements.description,
                            this.state.geometricElements.beforeCreationDate, this.state.geometricElements.laterCreationDate,
                            this.state.geometricElements.beforeModificationDate, this.state.geometricElements.laterModificationDate,
                            this.state.geometricElements.attributes, this.state.geometricElements.province,
                            this.state.geometricElements.municipality, this.state.geometricElements.parish, () => {
                                this.setState({ geometricElements: this.props.geometricElements })
                            }));
                    }
                }
                this.setState({
                    currentPage: page,
                    sizePerPage: sizePerPage,
                    currentSortField: sortField,
                    currentSortOrder: sortOrder
                })
            }

            jQuery(function () {
                jQuery('.actionButton').tooltip({
                    trigger: "hover",
                    title: function () {
                        return jQuery(this).attr('title');
                    }
                });
            });

            jQuery(function () {
                jQuery('.actionButton').tooltip().click(function () {
                    jQuery('.actionButton').tooltip("hide")
                });
            });

            let attributesFormatter = (cellContent, row) => {
                let groupedValues = groupBy(row.listAttributeValueDto.sort(function (a, b) {
                    return (a.elementTypeAttributeType.attributeOrder > b.elementTypeAttributeType.attributeOrder) ||
                        - (a.elementTypeAttributeType.attributeOrder < b.elementTypeAttributeType.attributeOrder)
                }), "elementTypeAttributeType.attributeType.code.code");
                let attributeNames = Object.keys(groupedValues);

                if (attributeNames.length === 0) {
                    return "-"
                }

                return (
                    attributeNames.map(attributeName =>
                        <div className="col-sm text-md-left" key={attributeName}>
                            <b>
                                <InternationalizationRender
                                    locale={this.props.language}
                                    value={attributeName}
                                    listInternationalization={this.props.allCodes}
                                />
                            </b>
                            <br />
                            <p>
                                {groupedValues[attributeName].map((attributeValue, index, array) => {
                                    return (
                                        attributeValue.value && attributeValue.value !== "" ?
                                            <span key={index}>
                                                <InternationalizationRender
                                                    locale={this.props.language}
                                                    value={attributeValue.value}
                                                    listInternationalization={this.props.allCodes}
                                                />
                                                {array.length - 1 !== index ? ", " : ""}
                                            </span>
                                            :
                                            "-"
                                    )
                                }
                                )}
                            </p>
                        </div>
                    )
                );
            }

            const expandRow = {
                renderer: row => (
                    <GeometricElementFilesTable
                        files={row.listGeometricElementFileDto.map((elementFile) =>
                            ({ ...elementFile, owner: row.owner, isPublic: row.isPublic, id: row.id }))}
                        geometricElement={row}
                        handleOpenAddGeometricElementFile={() => {
                            this.setState({
                                addGeometricElementFileGeometricElement: row,
                                addGeometricElementFileModalShow: true
                            })
                        }}
                        handleOpenDeleteGeometricElementFile={(originalFilename, uuid) => {
                            this.setState({
                                deleteGeometricElementFileDialogShow: true,
                                deleteGeometricElementFileName: originalFilename,
                                deleteGeometricElementFileUuid: uuid
                            });
                        }}
                    />
                ),
                expanded: this.state.expandedRows,
                expandByColumnOnly: true
            }

            const columns = [{
                dataField: 'id',
                text: 'Id',
                sort: true,
                headerAlign: 'left'
            }, {
                dataField: 'description',
                text: this.props.intl.formatMessage({ id: "project.elements.description" }),
                headerAlign: 'left',
                sort: true
            }, {
                dataField: 'atributes',
                isDummyField: true,
                text: this.props.intl.formatMessage({ id: "project.common.attributes" }),
                headerAlign: 'left',
                formatter: attributesFormatter
            }, {
                dataField: 'creationDate',
                text: this.props.intl.formatMessage({ id: "project.elements.creationDate" }),
                headerAlign: 'left',
                sort: true,
                formatter: (cellContent, row) => (
                    <span>
                        <FormattedDate
                            value={cellContent}
                            day="numeric"
                            month="numeric"
                            year="numeric"
                        />&nbsp;
                        <FormattedTime
                            value={cellContent}
                        />
                    </span>
                )
            }, {
                dataField: 'modificationDate',
                text: this.props.intl.formatMessage({ id: "project.elements.modificationDate" }),
                headerAlign: 'left',
                sort: true,
                formatter: (cellContent, row) => (
                    <span>
                        <FormattedDate
                            value={cellContent}
                            day="numeric"
                            month="numeric"
                            year="numeric"
                        />&nbsp;
                        <FormattedTime
                            value={cellContent}
                        />
                    </span>
                )
            }, {
                dataField: 'action',
                isDummyField: true,
                text: this.props.intl.formatMessage({ id: "project.common.action" }),
                headerAlign: 'left',
                formatExtraData: this.state.expandedRows,
                formatter: (cellContent, row, rowIndex, formatExtraData) => (
                    <div className="text-left">
                        <span
                            onClick={() => {
                                this.props.history.push({
                                    pathname: `/geometric_element/details/${row.id}`,
                                    state: {
                                        centerFeature: row
                                    }
                                })
                            }}
                            className="actionButton btn-link"
                            style={{
                                cursor: "pointer",
                                marginLeft: "0.5em"
                            }}
                            id={"showGeometricElementDetails" + row.id}
                            data-toggle="tooltip"
                            data-placement="right"
                            title={this.props.intl.formatMessage({ id: "project.common.showDetails" })}
                        >
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                        {row.listGeometricElementFileDto && row.listGeometricElementFileDto.filter(file => file.contentType.startsWith("image/")).length ?
                            <span id={"viewPhotosGallery" + row.id}
                                className="actionButton btn-link"
                                onClick={() =>
                                    this.setState({
                                        geometricElementFileGalleryModalShow: true,
                                        geometricElementFileGalleryFiles: row.listGeometricElementFileDto
                                    })
                                }
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.viewFiles" })}
                            >
                                <FontAwesomeIcon icon={faImages} />
                            </span> : ""}
                        <span id={"centerGeometricElementOnViewer" + row.id}
                            onClick={() => {
                                this.props.history.push({ pathname: "/", state: { centerFeature: row } })
                            }}
                            className="actionButton btn-link"
                            style={{
                                cursor: "pointer",
                                marginLeft: "0.5em"
                            }}
                            data-toggle="tooltip"
                            data-placement="right"
                            title={this.props.intl.formatMessage({ id: "project.common.centerOnViewer" })}
                        >
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                        </span>
                        {this.state.authenticatedUser ?
                            this.state.authenticatedUser.userRoleDto.code === "ADMIN" ||
                                (row.isOwnerUser && !row.isReviewed) ?
                                <span id={"modifyGeometricElementOnViewer" + row.id}
                                    onClick={() => {
                                        this.props.history.push({ pathname: "/", state: { featureToModify: row } })
                                    }}
                                    className="actionButton btn-link"
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: "0.5em"
                                    }}
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title={this.props.intl.formatMessage({ id: "project.common.modify" })}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </span> : "" : ""

                        }
                        {this.state.authenticatedUser ?
                            this.state.authenticatedUser.userRoleDto.code === "ADMIN" ||
                                (row.isOwnerUser && !row.isReviewed) ?
                                <span id={"deleteGeometricElement" + row.id}
                                    onClick={() =>
                                        this.setState({
                                            deleteGeometricElementDialogShow: true,
                                            deleteGeometricElementDescription: row.description,
                                            deleteGeometricElementId: row.id
                                        })
                                    }
                                    className="actionButton btn-link"
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: "0.5em"
                                    }}
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title={this.props.intl.formatMessage({ id: "project.common.delete" })}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </span>
                                :
                                ""
                            : ""
                        }
                        {this.state.authenticatedUser ?
                            <span id={"relateGeometricElement" + row.id}
                                onClick={() => {
                                    this.setState({
                                        geometricElementRelationToModify: null,
                                        geometricElementToRelate: row,
                                        relateGeometricElementsModalVisible: true,
                                        backendErrors: null
                                    });
                                }}
                                className="actionButton btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.elements.relations.relate" })}
                            >
                                <FontAwesomeIcon icon={faLink} />
                            </span>
                            : ""}
                        <span id={"hideShowFiles" + row.id}
                            onClick={() => handleBtnClick(row)}
                            className="actionButton btn-link"
                            style={{
                                cursor: "pointer",
                                marginLeft: "0.5em"
                            }}
                            data-toggle="tooltip"
                            data-placement="right"
                            title={this.props.intl.formatMessage({ id: "project.elements.files.hideShow" })}
                        >
                            <FontAwesomeIcon icon={!formatExtraData.includes(row.id) ? faChevronCircleDown : faChevronCircleUp} />
                        </span>
                    </div>
                )
            },
            ];

            if (RELATE_ELEMENTS_TO_PARISHES === "true") {
                columns.splice(3, 0, {
                    dataField: 'listParishes',
                    text: this.props.intl.formatMessage({ id: 'project.common.location' }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <ul>
                            {cellContent.map(parish =>
                                <li>
                                    {parish.name} - {parish.municipality.name} - {parish.municipality.region.province.name}
                                </li>
                            )}
                        </ul>
                    )
                })
            }

            if (this.state.authenticatedUser) {
                columns.splice(RELATE_ELEMENTS_TO_PARISHES === "true" ? 6 : 5, 0, {
                    dataField: 'isPublic',
                    text: this.props.intl.formatMessage({ id: "project.elements.isPublic" }),
                    headerAlign: 'left',
                    sort: true,
                    formatter: (cellContent, row) => (
                        cellContent ? <span><FormattedMessage id="project.common.yes" /></span> :
                            <span><FormattedMessage id="project.common.no" /></span>
                    )
                });

                columns.splice(RELATE_ELEMENTS_TO_PARISHES === "true" ? 7 : 6, 0, {
                    dataField: 'isReviewed',
                    text: this.props.intl.formatMessage({ id: "project.elements.isReviewed" }),
                    headerAlign: 'left',
                    sort: true,
                    formatter: (cellContent, row) => (
                        cellContent ? <span><FormattedMessage id="project.common.yes" /></span> :
                            <span><FormattedMessage id="project.common.no" /></span>
                    )
                });

                if (this.state.authenticatedUser.userRoleDto.code === "ADMIN") {
                    columns.splice(RELATE_ELEMENTS_TO_PARISHES === "true" ? 8 : 7, 0, {
                        dataField: 'geom',
                        text: this.props.intl.formatMessage({ id: "project.elements.geom" }),
                        headerAlign: 'left',
                        formatter: (cellContent, row) => (
                            cellContent ?
                                <span>{<FormattedMessage id={"project.elements.geom." + cellContent.type} />}</span> : ""
                        )
                    });
                    columns.splice(RELATE_ELEMENTS_TO_PARISHES === "true" ? 9 : 8, 0, {
                        dataField: 'internalComments',
                        text: this.props.intl.formatMessage({ id: "project.elements.internalComments" }),
                        headerAlign: 'left',
                        sort: true,
                        formatter: (cellContent, row) => (
                            cellContent ? cellContent : "-"
                        )
                    });
                    if (!this.props.history.location.pathname.includes("myElements"))
                        columns.splice(RELATE_ELEMENTS_TO_PARISHES === "true" ? 10 : 9, 0, {
                            dataField: 'owner.login',
                            text: this.props.intl.formatMessage({ id: "project.elements.owner" }),
                            headerAlign: 'left'
                        });
                    // fileColumns.splice(0, 0, {
                    //     dataField: 'uuid',
                    //     text: 'UUID',
                    //     sort: true,
                    //     headerAlign: 'left'
                    // })
                    // fileColumns.splice(3, 0, {
                    //     dataField: 'contentType',
                    //     text: this.props.intl.formatMessage({ id: 'project.elements.files.type' }),
                    //     headerAlign: 'left',
                    //     sort: true
                    // })
                }
            }

            let renderDropdown = () => {
                if (this.state.geometricElementType.geometryType === "ANY_GEOMETRY") {
                    return (
                        <div className="dropdown float-right">
                            <a className="btn btn-primary dropdown-toggle" href="." role="button" id="addGeometricElementDropdownLink"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                onClick={() => {
                                    this.props.dispatch(actions.mapCurrentExtent(null));
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                    &nbsp;
                                    <FormattedMessage
                                    id="project.elements.new"
                                    values={{
                                        element: getInternationalization(
                                            this.props.language,
                                            this.state.geometricElementType.code,
                                            this.props.allCodes
                                        ).toLocaleLowerCase()
                                    }}
                                />
                            </a>

                            <div className="dropdown-menu" aria-labelledby="addGeometricElementDropdownLink">
                                <Link className="dropdown-item"
                                    to={{
                                        pathname: "/",
                                        state: {
                                            insertFromNavBar: this.state.geometricElementType.id + "-Point"
                                        }
                                    }}
                                    key={this.state.geometricElementType.id + '-Point'}
                                    id={this.state.geometricElementType.id + '-Point'}
                                >
                                    <FormattedMessage id="project.elements.geom.Point" />
                                </Link>
                                <Link className="dropdown-item"
                                    to={{
                                        pathname: "/",
                                        state: { insertFromNavBar: this.state.geometricElementType.id + "-LineString" }
                                    }}
                                    key={this.state.geometricElementType.id + '-LineString'}
                                    id={this.state.geometricElementType.id + '-LineString'}
                                >
                                    <FormattedMessage id="project.elements.geom.LineString" />
                                </Link>
                                <Link className="dropdown-item"
                                    to={{
                                        pathname: "/",
                                        state: {
                                            insertFromNavBar: this.state.geometricElementType.id + "-Polygon"
                                        }
                                    }}
                                    key={this.state.geometricElementType.id + '-Polygon'}
                                    id={this.state.geometricElementType.id + '-Polygon'}
                                >
                                    <FormattedMessage id="project.elements.geom.Polygon" />
                                </Link>
                            </div>
                        </div>
                    )
                }
                if (this.state.geometricElementType.geometryType === "POINT_POLYGON") {
                    return (
                        <div className="dropdown float-right">
                            <a className="btn btn-primary dropdown-toggle" href="." role="button" id="addGeometricElementDropdownLink"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                    &nbsp;
                                    <FormattedMessage
                                    id="project.elements.new"
                                    values={{
                                        element: getInternationalization(
                                            this.props.language,
                                            this.state.geometricElementType.code,
                                            this.props.allCodes
                                        ).toLocaleLowerCase()
                                    }}
                                />
                            </a>

                            <div className="dropdown-menu" aria-labelledby="addGeometricElementDropdownLink">
                                <Link className="dropdown-item"
                                    to={{
                                        pathname: "/",
                                        state: { insertFromNavBar: this.state.geometricElementType.id + "-Point" }
                                    }}
                                    key={this.state.geometricElementType.id + '-Point'}
                                    id={this.state.geometricElementType.id + '-Point'}
                                >
                                    <FormattedMessage id="project.elements.geom.Point" />
                                </Link>
                                <Link className="dropdown-item"
                                    to={{
                                        pathname: "/",
                                        state: { insertFromNavBar: this.state.geometricElementType.id + "-Polygon" }
                                    }}
                                    key={this.state.geometricElementType.id + '-Polygon'}
                                    id={this.state.geometricElementType.id + '-Polygon'}
                                >
                                    <FormattedMessage id="project.elements.geom.Polygon" />
                                </Link>
                            </div>
                        </div>
                    )
                }
                if (this.state.geometricElementType.geometryType === "POINT_LINE") {
                    return (
                        <div className="dropdown float-right">
                            <a className="btn btn-primary dropdown-toggle" href="." role="button" id="addGeometricElementDropdownLink"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                    &nbsp;
                                    <FormattedMessage
                                    id="project.elements.new"
                                    values={{
                                        element: getInternationalization(
                                            this.props.language,
                                            this.state.geometricElementType.code,
                                            this.props.allCodes
                                        ).toLocaleLowerCase()
                                    }}
                                />
                            </a>

                            <div className="dropdown-menu" aria-labelledby="addGeometricElementDropdownLink">
                                <Link className="dropdown-item"
                                    to={{
                                        pathname: "/",
                                        state: { insertFromNavBar: this.state.geometricElementType.id + "-Point" }
                                    }}
                                    key={this.state.geometricElementType.id + '-Point'}
                                    id={this.state.geometricElementType.id + '-Point'}
                                >
                                    <FormattedMessage id="project.elements.geom.Point" />
                                </Link>
                                <Link className="dropdown-item"
                                    to={{
                                        pathname: "/",
                                        state: { insertFromNavBar: this.state.geometricElementType.id + "-LineString" }
                                    }}
                                    key={this.state.geometricElementType.id + '-LineString'}
                                    id={this.state.geometricElementType.id + '-LineString'}
                                >
                                    <FormattedMessage id="project.elements.geom.LineString" />
                                </Link>
                            </div>
                        </div>
                    )
                }
                if (this.state.geometricElementType.geometryType === "LINE_POLYGON") {
                    return (
                        <div className="dropdown float-right">
                            <a className="btn btn-primary dropdown-toggle" href="." role="button" id="addGeometricElementDropdownLink"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                    &nbsp;
                                    <FormattedMessage
                                    id="project.elements.new"
                                    values={{
                                        element: getInternationalization(
                                            this.props.language,
                                            this.state.geometricElementType.code,
                                            this.props.allCodes
                                        ).toLocaleLowerCase()
                                    }}
                                />
                            </a>

                            <div className="dropdown-menu" aria-labelledby="addGeometricElementDropdownLink">
                                <Link className="dropdown-item"
                                    to={{
                                        pathname: "/",
                                        state: { insertFromNavBar: this.state.geometricElementType.id + "-LineString" }
                                    }}
                                    key={this.state.geometricElementType.id + '-LineString'}
                                    id={this.state.geometricElementType.id + '-LineString'}
                                >
                                    <FormattedMessage id="project.elements.geom.LineString" />
                                </Link>
                                <Link className="dropdown-item"
                                    to={{
                                        pathname: "/",
                                        state: { insertFromNavBar: this.state.geometricElementType.id + "-Polygon" }
                                    }}
                                    key={this.state.geometricElementType.id + '-Polygon'}
                                    id={this.state.geometricElementType.id + '-Polygon'}
                                >
                                    <FormattedMessage id="project.elements.geom.Polygon" />
                                </Link>
                            </div>
                        </div>
                    )
                }
                if (this.state.geometricElementType.geometryType === "POINT") {
                    return (
                        <Link className="btn btn-primary float-right"
                            to={{
                                pathname: "/",
                                state: { insertFromNavBar: this.state.geometricElementType.id + "-Point" }
                            }}
                            key={this.state.geometricElementType.id + '-Point'}
                            id={this.state.geometricElementType.id + '-Point'}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                                &nbsp;
                            <FormattedMessage
                                id="project.elements.new"
                                values={{
                                    element: getInternationalization(
                                        this.props.language,
                                        this.state.geometricElementType.code,
                                        this.props.allCodes
                                    ).toLocaleLowerCase()
                                }}
                            />
                        </Link>
                    )
                }
                if (this.state.geometricElementType.geometryType === "POLYGON") {
                    return (
                        <Link className="btn btn-primary float-right"
                            to={{
                                pathname: "/",
                                state: { insertFromNavBar: this.state.geometricElementType.id + "-Polygon" }
                            }}
                            key={this.state.geometricElementType.id + '-Polygon'}
                            id={this.state.geometricElementType.id + '-Polygon'}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                                &nbsp;
                            <FormattedMessage
                                id="project.elements.new"
                                values={{
                                    element: getInternationalization(
                                        this.props.language,
                                        this.state.geometricElementType.code,
                                        this.props.allCodes
                                    ).toLocaleLowerCase()
                                }}
                            />
                        </Link>
                    )
                }
                if (this.state.geometricElementType.geometryType === "LINE") {
                    return (
                        <Link className="btn btn-primary float-right"
                            to={{
                                pathname: "/",
                                state: { insertFromNavBar: this.state.geometricElementType.id + "-LineString" }
                            }}
                            key={this.state.geometricElementType.id + '-LineString'}
                            id={this.state.geometricElementType.id + '-LineString'}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                                &nbsp;
                            <FormattedMessage
                                id="project.elements.new"
                                values={{
                                    element: getInternationalization(
                                        this.props.language,
                                        this.state.geometricElementType.code,
                                        this.props.allCodes
                                    ).toLocaleLowerCase()
                                }}
                            />
                        </Link>
                    )
                }
            }

            return (
                <div className="card card-body">

                    {this.state.authenticatedUser &&
                        this.state.authenticatedUser.userRoleDto.code === "USER" &&
                        !this.props.history.location.pathname.includes("myElements") ?
                        <div className="alert alert-primary text-left">
                            <FormattedMessage id="project.elements.myElements.message"
                                values={{
                                    link: <Link className="alert-link"
                                        to={"/geometric_elements/" + this.props.match.params.id + "/myElements"}
                                        key={this.props.match.params.id}
                                        id={"myElementsFromType" + this.props.match.params.id}
                                        onClick={() => {
                                            this.props.dispatch(actions.findUserGeometricElementsByElementTypeId(
                                                this.props.match.params.id, 0, 10, "id", "asc", null,
                                                this.state.authenticatedUser.id, null, null, null, null, null, null, null, null,
                                                null, null, null, () => {
                                                    this.setState({ geometricElements: this.props.geometricElements });
                                                })
                                            );
                                        }}
                                    >
                                        <FormattedMessage id="project.app.Header.myContributions" />
                                    </Link>
                                }}
                            />
                        </div>
                        : ""
                    }

                    {!this.state.authenticatedUser ?
                        <div className="alert alert-primary text-left">
                            <FormattedMessage id="project.elements.anonymous.message"
                                values={{
                                    link: <span className="btn-link alert-link"
                                        onClick={() =>
                                            this.setState({
                                                backendErrors: null,
                                                loginModalShow: true
                                            })
                                        }
                                        style={{cursor: 'pointer'}}
                                        id={"loginFromTable"}
                                    >
                                        <FormattedMessage id="project.app.Header.login" />
                                    </span>
                                }}
                            />
                        </div>
                        : ""
                    }

                    <h3>{this.props.history.location.pathname.includes("myElements") && <><FormattedMessage id="project.app.Header.myContributions" /> - </>}<InternationalizationRender value={this.state.geometricElementType.code} /></h3>

                    {/* Action buttons */}
                    {this.state.authenticatedUser && (canAddGeometricElement || this.state.authenticatedUser.userRoleDto.code === "ADMIN") ?
                        <div id="actionButtons" className="text-right">
                            {renderDropdown()}
                            <button id="searchGeometricElements" className="btn btn-primary float-right mr-2" onClick={() => {
                                this.setState({ searchModalVisible: true });
                            }}>
                                <FontAwesomeIcon icon={faSearch} />
                                &nbsp;
                                <FormattedMessage id="project.app.Body.search" />
                            </button>
                        </div>
                        :
                        <div id="actionButtons" className="text-right">
                            <button id="searchGeometricElements" className="btn btn-primary float-right" onClick={() => {
                                this.setState({ searchModalVisible: true });
                            }}>
                                <FontAwesomeIcon icon={faSearch} />
                                &nbsp;
                                <FormattedMessage id="project.app.Body.search" />
                            </button>
                        </div>
                    }

                    {/* Relate geometric elements dialog */}
                    {this.state.authenticatedUser ?
                        <AddModifyGeometricElementRelation
                            modalShow={this.state.relateGeometricElementsModalVisible}
                            geometricElement={this.state.geometricElementToRelate}
                            handleSubmit={() => {
                                this.setState({ relateGeometricElementsModalVisible: false })
                            }}
                            geometricElementRelationToModify={this.state.geometricElementRelationToModify}
                            backendErrors={this.state.backendErrors}
                            setBackendErrors={setBackendErrors}
                            hideModalWindow={() => this.setState({ relateGeometricElementsModalVisible: false })}
                        />
                        : ""}

                    {/* Search dialog */}
                    <GeometricElementsByGeometricElementTypeSearch
                        modalShow={this.state.searchModalVisible}
                        currentPage={1}
                        currentSize={this.state.currentSize}
                        currentSortField={this.state.currentSortField}
                        currentSortOrder={this.state.currentSortOrder}
                        listAttributes={this.state.geometricElementType.listAttributeType}
                        hideModalWindow={() => this.setState({ searchModalVisible: false })}
                        moveToFirstPage={() => this.setState({ currentPage: 1 })}
                        setLocationFiltersName={this.setLocationFiltersName}
                    />

                    {/* Login/SignUp dialog */}
                    <Login
                        modalShow={this.state.loginModalShow}
                        insertFromNavBar={null}
                        backendErrors={this.state.backendErrors}
                        setBackendErrors={setBackendErrors}
                        hideModalWindow={() => this.setState({ loginModalShow: false })}
                        handleOpenSignUpModalWindow={() => {
                            this.setState({
                                backendErrors: null,
                                loginModalShow: false,
                                signUpModalShow: true
                            });
                        }}
                    />
                    <SignUp
                        modalShow={this.state.signUpModalShow}
                        backendErrors={this.state.backendErrors}
                        insertFromNavBar={null}
                        handleAddNewUser={null}
                        setBackendErrors={setBackendErrors}
                        hideModalWindow={() => this.setState({ signUpModalShow: false })}
                    />

                    {/* View photos gallery dialog */}
                    <GeometricElementFileGallery
                        modalShow={this.state.geometricElementFileGalleryModalShow}
                        geometricElementFiles={this.state.geometricElementFileGalleryFiles}
                        hideModalWindow={() => this.setState({ geometricElementFileGalleryModalShow: false })}
                    />

                    {/* Delete geometric element dialog */}
                    <DeleteDialog
                        modalShow={this.state.deleteGeometricElementDialogShow}
                        title={this.props.intl.formatMessage({ id: 'project.common.delete' })}
                        details={
                            this.props.intl.formatMessage(
                                { id: 'project.elements.geometricElement.delete.message' },
                                {
                                    element: <b>{this.state.deleteGeometricElementDescription}</b>,
                                    alert: <div class="alert alert-primary" role="alert">
                                        <FormattedMessage id="project.elements.geometricElement.delete.message.alert" />
                                    </div>
                                })
                        }
                        backendErrors={this.state.backendErrors}
                        hideModalWindow={() => { this.setState({ deleteGeometricElementDialogShow: false }) }}
                        handleSubmit={() => {
                            this.props.dispatch(actions.deleteGeometricElement(this.state.deleteGeometricElementId,
                                () => {
                                    let currentPage = this.state.currentPage;
                                    if (this.state.geometricElements.result.items.length === 1 && this.state.currentPage > 0) {
                                        currentPage = currentPage - 1;
                                    }
                                    handleTableChange(currentPage, this.state.currentSize,
                                        this.state.currentSortField, this.state.currentSortOrder);
                                    this.setState({ deleteGeometricElementDialogShow: false });
                                },
                                (error) => {
                                    setBackendErrors(error);
                                }))
                        }}
                        setBackendErrors={setBackendErrors}
                    />

                    {/* Add geometric element file dialog */}
                    <AddGeometricElementFile
                        modalShow={this.state.addGeometricElementFileModalShow}
                        geometricElement={this.state.addGeometricElementFileGeometricElement}
                        handleSubmit={() => {
                            handleTableChange(this.state.currentPage, this.state.currentSize,
                                this.state.currentSortField, this.state.currentSortOrder);
                            this.setState({ addGeometricElementFileModalShow: false });
                        }}
                        hideModalWindow={() => this.setState({ addGeometricElementFileModalShow: false })}
                    />

                    {/*Delete geometric element file dialog*/}
                    <DeleteDialog
                        modalShow={this.state.deleteGeometricElementFileDialogShow}
                        title={this.props.intl.formatMessage({ id: 'project.common.deleteFile' })}
                        details={this.props.intl.formatMessage(
                            { id: 'project.elements.delete.message' }, { element: <b>{this.state.deleteGeometricElementFileName}</b> })}
                        backendErrors={this.state.backendErrors}
                        hideModalWindow={() => { this.setState({ deleteGeometricElementFileDialogShow: false }) }}
                        handleSubmit={() => {
                            this.props.dispatch(actions.deleteGeometricElementFile(this.state.deleteGeometricElementFileUuid,
                                () => {
                                    handleTableChange(this.state.currentPage, this.state.currentSize,
                                        this.state.currentSortField, this.state.currentSortOrder);
                                    this.setState({ deleteGeometricElementFileDialogShow: false });
                                },
                                (error) => {
                                    setBackendErrors(error);
                                }))
                        }}
                        setBackendErrors={setBackendErrors}
                    />

                    {/* Table render */}
                    {this.state.geometricElements ? filterPanelRender() : ""}
                    <BootstrapTable
                        bootstrap4
                        keyField="id"
                        columns={columns}
                        data={this.state.geometricElements ?
                            this.state.geometricElements.result.items : []
                        }
                        loading={true}
                        defaultSorted={[{ dataField: 'modificationDate', order: 'desc' }]}
                        expandRow={expandRow}
                        noDataIndication={this.state.geometricElements ?
                            this.props.intl.formatMessage({ id: "project.elements.noElements" }) :
                            <div className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        }
                        striped
                        condensed
                        remote
                        rowClasses="text-left"
                        onTableChange={(type, { page, sizePerPage, sortField, sortOrder }) => {
                            handleTableChange(page, sizePerPage, sortField, sortOrder);
                            this.setState({
                                currentPage: page,
                                currentSize: sizePerPage,
                                currentSortField: sortField,
                                currentSortOrder: sortOrder,
                                expandedRows: []
                            });
                        }}
                        pagination={
                            paginationFactory({
                                page: this.state.currentPage,
                                sizePerPage: this.state.currentSize,
                                showTotal: true,
                                hidePageListOnlyOnePage: true,
                                totalSize: this.state.geometricElements ?
                                    this.state.geometricElements.result.totalItems : 0,
                                paginationTotalRenderer: (from, to, size) => {
                                    return <FormattedMessage id="project.common.tables.totalElements"
                                        values={{
                                            from: from,
                                            to: to,
                                            total: size
                                        }
                                        }
                                    />
                                },
                                sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => (
                                    <li
                                        key={text}
                                        role="presentation"
                                        className={`dropdown-item ${this.state.currentSize === Number(text) ? "active" : ""}`}
                                        onMouseDown={(e) => {
                                            e.preventDefault();
                                            onSizePerPageChange(page);
                                        }}
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {text}
                                    </li>
                                ),
                                nextPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.nextPageTitle' }),
                                prePageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.prePageTitle' }),
                                lastPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.lastPageTitle' }),
                                firstPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.firstPageTitle' }),
                            })
                        }
                    />
                    <br />
                </div>
            );
        }

        if (!CAN_QUERY_GEOMETRIC_ELEMENTS ||
            (this.state.authenticatedUser && this.state.authenticatedUser.userRoleDto.code !== "ADMIN")
        ) {
            return (
                <div className="container text-center">
                    <br />
                    <div className="card-body alert alert-danger" role="alert">
                        <h4 className="card-text"><FormattedMessage id="project.common.permissionDenied" /></h4>
                    </div>
                </div>
            );
        }

        return (
            null
        );
    }

}

export default withRouter(connect(mapStateToProps)(injectIntl(GeometricElementsByElementType)));