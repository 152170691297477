import { combineReducers } from 'redux';
import * as actionTypes from './actionTypes';

const initialState = {
    user: null,
    allRoles: null,
    roleByCode: null,
    totalUserAccount: null
}

const user = (state = initialState.user, action) => {

    switch (action.type) {

        case actionTypes.SIGN_UP_COMPLETED:
            return action.user.user;
        case actionTypes.LOGIN_COMPLETED:
            return action.user.user;
        case actionTypes.LOGOUT_COMPLETED:
            return initialState.user;
        default:
            return state;
    }
}

const allRoles = (state = initialState.allRoles, action) => {
    switch (action.type) {
        case actionTypes.FIND_ALL_ROLES_COMPLETED:
            return action.allRoles;
        default:
            return state;
    }
}

const roleByCode = (state = initialState.roleByCode, action) => {
    switch (action.type) {
        case actionTypes.FIND_ROLE_BY_CODE_COMPLETED:
            return action.roleByCode;
        default:
            return state;
    }
}

const totalUserAccount = (state = initialState.totalUserAccount, action) => {
    switch (action.type) {
        case actionTypes.FIND_TOTAL_USER_ACCOUNT_COMPLETED:
            return action.totalUserAccount ? action.totalUserAccount.items : action.totalUserAccount;
        case actionTypes.CREATE_USER_COMPLETED:
            return [...state, action.newUserAccount];
        case actionTypes.LOCK_USER_ACCOUNT_COMPLETED:
            let index = state.map(function (userAccount) { return userAccount.id }).indexOf(action.lockedUserAccount.id);
            return state.map((currentUserAccount, currentIndex) => index === currentIndex ? action.lockedUserAccount : currentUserAccount);
        default:
            return state;
    }
}

const reducer = combineReducers({
    user,
    allRoles,
    roleByCode,
    totalUserAccount
});

export default reducer;