import en from './messages_en';
import es from './messages_es';
import gl from './messages_gl';


const messages = {
    'en': en,
    'es': es,
    'gl': gl
};

export default messages;