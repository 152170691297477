import * as actionTypes from './actionTypes';
import * as appSelectors from '../app/selectors';
import * as selectors from './selectors';
import * as appActions from '../app/actions';
import backend from '../../backend';

const findGeometricElementTypeByIdCompleted = geometricElementType => ({
    type: actionTypes.FIND_GEOMETRIC_ELEMENT_TYPE_BY_ID_COMPLETED,
    geometricElementType
});

export const findGeometricElementTypeById = (id, onSuccess) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.getElementTypeById(id,
        (geometricElementType) => {
            dispatch(findGeometricElementTypeByIdCompleted(geometricElementType));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        },
        appSelectors.getLanguage(getState())
    );
}

const findPublicGeometricElementsByElementTypeIdCompleted = geometricElementsByElementType => ({
    type: actionTypes.FIND_PUBLIC_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED,
    geometricElementsByElementType
});

export const findPublicGeometricElementsByElementTypeId = (id, page, size, sortField, sortOrder, geometricElementId, description,
    beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate, attributes, province, municipality, parish,
    onSuccess) => (dispatch, getState) => {
        dispatch(appActions.loading());
        backend.elementService.findPublicGeometricElementsByGeometricElementType(id, page, size, sortField, sortOrder, geometricElementId,
            description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate, attributes, province,
            municipality, parish, appSelectors.getLanguage(getState()), (result) => {
                dispatch(findPublicGeometricElementsByElementTypeIdCompleted({
                    id, page, size, sortField, sortOrder,
                    geometricElementId, description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate,
                    province, municipality, parish, attributes, result
                }));
                onSuccess();
                dispatch(appActions.loaded());
            },
            () => {
                dispatch(appActions.loaded());
            }
        );
    }

const findUserGeometricElementsByElementTypeIdCompleted = geometricElementsByElementType => ({
    type: actionTypes.FIND_USER_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED,
    geometricElementsByElementType
});

export const findUserGeometricElementsByElementTypeId = (id, page, size, sortField, sortOrder, isPublic, ownerId,
    geometricElementId, description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate, isReviewed, attributes,
    province, municipality, parish, onSuccess) => (dispatch, getState) => {
        dispatch(appActions.loading());
        backend.elementService.findUserGeometricElementsByGeometricElementType(id, page, size, sortField, sortOrder, ownerId, isPublic,
            geometricElementId, description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate, isReviewed,
            attributes, province, municipality, parish, appSelectors.getLanguage(getState()), (result) => {
                dispatch(findUserGeometricElementsByElementTypeIdCompleted({
                    id, page, size, sortField, sortOrder, isPublic, ownerId,
                    geometricElementId, description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate,
                    isReviewed, attributes, province, municipality, parish, result
                }));
                onSuccess();
                dispatch(appActions.loaded());
            },
            () => {
                dispatch(appActions.loaded());
            }
        );
    }

const findAdminGeometricElementsByElementTypeIdCompleted = geometricElementsByElementType => ({
    type: actionTypes.FIND_ADMIN_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED,
    geometricElementsByElementType
});

export const findAdminGeometricElementsByElementTypeId = (id, page, size, sortField, sortOrder, isPublic, ownerId, geometricElementId,
    description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate, isReviewed, internalComments,
    ownerLogin, attributes, province, municipality, parish, onSuccess) => (dispatch, getState) => {
        dispatch(appActions.loading());
        backend.elementService.findAdminGeometricElementsByGeometricElementType(id, page, size, sortField, sortOrder, ownerId, isPublic,
            geometricElementId, description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate, isReviewed,
            internalComments, ownerLogin, attributes, province, municipality, parish, appSelectors.getLanguage(getState()),
            (result) => {
                dispatch(findAdminGeometricElementsByElementTypeIdCompleted({
                    id, page, size, sortOrder, sortField, isPublic, ownerId,
                    geometricElementId, description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate,
                    isReviewed, internalComments, ownerLogin, attributes, province, municipality, parish, result
                }));
                onSuccess();
                dispatch(appActions.loaded());
            },
            () => {
                dispatch(appActions.loaded());
            }
        );
    }

const findAllCodesCompleted = allCodes => ({
    type: actionTypes.FIND_ALL_CODES_COMPLETED,
    allCodes
});

export const findAllCodes = (onSuccess) => (dispatch, getState) => {
    backend.elementService.findAllCodes(
        allCodes => {
            dispatch(findAllCodesCompleted(allCodes));
            onSuccess();
        }, appSelectors.getLanguage(getState())
    );
}

export const addGeometricElement = (json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.addGeoemtricElement(json, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

export const modifyGeometricElement = (id, json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.modifyGeometricElement(id, json, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

const findTotalGeometricElementTypeCompleted = totalGeometricElementType => ({
    type: actionTypes.FIND_TOTAL_GEOMETRIC_ELEMENT_TYPE_COMPLETED,
    totalGeometricElementType
});

export const findTotalGeometricElementType = (page, size, sortField, sortOrder, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    dispatch(findTotalGeometricElementTypeCompleted(null));
    backend.elementService.findAllGeometricElementType(page, size, sortField, sortOrder, appSelectors.getLanguage(getState()),
        result => {
            dispatch(findTotalGeometricElementTypeCompleted(result));
            if (typeof onSuccess == 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        },
        (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

const deleteGeometicElementTypeCompleted = (geometricElementTypeId) => ({
    type: actionTypes.DELETE_GEOMETRIC_ELEMENT_TYPE_COMPLETED,
    geometricElementTypeId
});

export const deleteGeometricElementType = (id, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.deleteGeometricElementType(id,
        () => {
            dispatch(deleteGeometicElementTypeCompleted(id));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        }, appSelectors.getLanguage(getState()));
}

export const deleteGeometricElement = (id, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.deleteGeometricElement(id, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

const findTotalAttributeTypeCompleted = totalAttributeType => ({
    type: actionTypes.FIND_TOTAL_ATTRIBUTE_TYPE_COMPLETED,
    totalAttributeType
});

export const findTotalAttributeType = (page, size, sortField, sortOrder, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.findAllAttributeType(page, size, sortField, sortOrder, appSelectors.getLanguage(getState()), (result) => {
        dispatch(findTotalAttributeTypeCompleted(result));
        if (typeof onSuccess === "function") {
            onSuccess();
        }
        dispatch(appActions.loaded());
    }, (errors) => {
        if (typeof onErrors === 'function') {
            onErrors(errors);
        }
        dispatch(appActions.loaded());
    });
}

const deleteAttributeTypeCompleted = (attributeTypeId) => ({
    type: actionTypes.DELETE_ATTRIBUTE_TYPE_COMPLETED,
    attributeTypeId
})

export const deleteAttributeType = (id, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.deleteAttributeType(id, appSelectors.getLanguage(getState()),
        () => {
            dispatch(deleteAttributeTypeCompleted(id));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

const addAttributeTypeCompleted = (addedAttributeType) => ({
    type: actionTypes.ADD_ATTRIBUTE_TYPE_COMPLETED,
    addedAttributeType
})

export const addAttributeType = (json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.addAttributeType(json, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(addAttributeTypeCompleted(result));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

const addGeometricElementTypeCompleted = (addedGeometricElementType) => ({
    type: actionTypes.ADD_GEOMETRIC_ELEMENT_TYPE_COMPLETED,
    addedGeometricElementType
})

export const addGeometricElementType = (json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.addGeometricElementType(json, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(addGeometricElementTypeCompleted(result));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

const modifyAttributeTypeCompleted = (modifiedAttributeType) => ({
    type: actionTypes.MODIFY_ATTRIBUTE_TYPE_COMPLETED,
    modifiedAttributeType
})

export const modifyAttributeType = (id, json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.modifyAttributeType(id, json, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(modifyAttributeTypeCompleted(result));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

const modifyGeometricElementTypeCompleted = (modifiedGeometricElementType) => ({
    type: actionTypes.MODIFY_GEOMETRIC_ELEMENT_TYPE_COMPLETED,
    modifiedGeometricElementType
});

export const modifyGeometricElementType = (id, json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.modifyGeometricElementType(id, json, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(modifyGeometricElementTypeCompleted(result));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

export const deleteGeometricElementFile = (uuid, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.deleteGeometricElementFile(uuid, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

//Center by entity populations actions

const listProvincesCompleted = listProvinces => ({
    type: actionTypes.LIST_PROVINCES,
    listProvinces
})

export const listProvinces = (listProvinces) => (dispatch, getState) => {

    if (!selectors.getListProvinces(getState())) {
        dispatch(listProvincesCompleted(listProvinces));
    }
};

const listCouncilsCompleted = listCouncils => ({
    type: actionTypes.LIST_COUNCILS,
    listCouncils
});


export const listCouncils = (listCouncils) => (dispatch, getState) => {

    if (!selectors.getListCouncils(getState())) {
        dispatch(listCouncilsCompleted(listCouncils));
    }
};

const listParishesCompleted = listParishes => ({
    type: actionTypes.LIST_PARISHES,
    listParishes
});

export const listParishes = (listParishes) => (dispatch, getState) => {

    if (!selectors.getListParishes(getState())) {
        dispatch(listParishesCompleted(listParishes));
    }
};

const listEntityPopulationsCompleted = listEntityPopulations => ({
    type: actionTypes.LIST_ENTITY_POPULATIONS,
    listEntityPopulations
});

export const listEntityPopulations = (listEntityPopulations) => (dispatch, getState) => {

    if (!selectors.getListEntityPopulations(getState())) {
        dispatch(listEntityPopulationsCompleted(listEntityPopulations));
    }
};

const listCountiesCompleted = listCounties => ({
    type: actionTypes.LIST_COUNTIES,
    listCounties
});

export const listCounties = (listCounties) => (dispatch, getState) => {

    if (!selectors.getListCounties(getState())) {
        dispatch(listCountiesCompleted(listCounties));
    }
};

//GeometricLayerService actions
const findTotalGeometricLayerGroupCompleted = totalGeometricLayerGroup => ({
    type: actionTypes.FIND_TOTAL_GEOMETRIC_LAYER_GROUP_COMPLETED,
    totalGeometricLayerGroup
})

export const findTotalGeometricLayerGroup = (page, size, sortField, sortOrder, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.geometricLayerService.findAllGeometricLayerGroup(page, size, sortField, sortOrder, appSelectors.getLanguage(getState()),
        result => {
            dispatch(findTotalGeometricLayerGroupCompleted(result));
            if (typeof onSuccess === "function") {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, onErrors);
}

const findTotalGeometricLayerCompleted = totalGeometricLayer => ({
    type: actionTypes.FIND_TOTAL_GEOMETRIC_LAYER_COMPLETED,
    totalGeometricLayer
})

export const findTotalGeometricLayer = (page, size, sortField, sortOrder, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.geometricLayerService.findAllGeometricLayer(page, size, sortField, sortOrder, appSelectors.getLanguage(getState()),
        result => {
            dispatch(findTotalGeometricLayerCompleted(result));
            if (typeof onSuccess === "function") {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, onErrors);
}

const addGeometricLayerGroupCompleted = (addedGeometricLayerGroup) => ({
    type: actionTypes.ADD_GEOMETRIC_LAYER_GROUP_COMPLETED,
    addedGeometricLayerGroup
});

export const addGeometricLayerGroup = (json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.geometricLayerService.addGeometricLayerGroup(json, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(addGeometricLayerGroupCompleted(result))
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

const addGeometricLayerCompleted = (addedGeometricLayer) => ({
    type: actionTypes.ADD_GEOMETRIC_LAYER_COMPLETED,
    addedGeometricLayer
});

export const addGeometricLayer = (json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.geometricLayerService.addGeometricLayer(json, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(addGeometricLayerCompleted(result));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

const modifyGeometricLayerGroupCompleted = (modifiedGeometricLayerGroup) => ({
    type: actionTypes.MODIFY_GEOMETRIC_LAYER_GROUP_COMPLETED,
    modifiedGeometricLayerGroup
});

export const modifyGeometricLayerGroup = (id, json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.geometricLayerService.modifyGeometricLayerGroup(id, json, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(modifyGeometricLayerGroupCompleted(result));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

const modifyGeometricLayerCompleted = (modifiedGeometricLayer) => ({
    type: actionTypes.MODIFY_GEOMETRIC_LAYER_COMPLETED,
    modifiedGeometricLayer
});

export const modifyGeometricLayer = (id, json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.geometricLayerService.modifyGeometricLayer(id, json, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(modifyGeometricLayerCompleted(result));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

const deleteGeometricLayerGroupCompleted = (geometricLayerGroupId) => ({
    type: actionTypes.DELETE_GEOMETRIC_LAYER_GROUP_COMPLETED,
    geometricLayerGroupId
});

export const deleteGeometricLayerGroup = (id, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.geometricLayerService.deleteGeometricLayerGroup(id, appSelectors.getLanguage(getState()),
        () => {
            dispatch(deleteGeometricLayerGroupCompleted(id));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

const deleteGeometricLayerCompleted = (geometricLayerId) => ({
    type: actionTypes.DELETE_GEOMETRIC_LAYER_COMPLETED,
    geometricLayerId
});

export const deleteGeometricLayer = (id, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.geometricLayerService.deleteGeometricLayer(id, appSelectors.getLanguage(getState()),
        () => {
            dispatch(deleteGeometricLayerCompleted(id));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

// Map current zoom
export const mapCurrentExtent = mapCurrentExtent => ({
    type: actionTypes.MAP_CURRENT_EXTENT,
    mapCurrentExtent
});

//Geometric element relation services
export const addGeometricElementRelation = (geometricElementRelation, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.addGeometricElementRelation(geometricElementRelation, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

export const updateGeometricElementRelation = (id, geometricElementRelation, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.updateGeometricElementRelation(id, geometricElementRelation, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}


export const deleteGeometricElementRelation = (id, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.deleteGeometricElementRelation(id, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

// Geometric element relations
export const findPublicGeometricElementRelationsCompleted = geometricElementRelations => ({
    type: actionTypes.FIND_PUBLIC_GEOMETRIC_ELEMENT_RELATION_COMPLETED,
    geometricElementRelations
});

export const findPublicGeometricElementRelation = (geometricElementId, page, size, sortField, sortOrder, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.findPublicGeometricElementRelation(geometricElementId, page, size, sortField, sortOrder, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(findPublicGeometricElementRelationsCompleted({ geometricElementId, page, size, sortField, sortOrder, result }))
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

export const findUserGeometricElementRelationsCompleted = geometricElementRelations => ({
    type: actionTypes.FIND_USER_GEOMETRIC_ELEMENT_RELATION_COMPLETED,
    geometricElementRelations
});

export const findUserGeometricElementRelation = (geometricElementId, page, size, sortField, sortOrder, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.findUserGeometricElementRelation(geometricElementId, page, size, sortField, sortOrder, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(findUserGeometricElementRelationsCompleted({ geometricElementId, page, size, sortField, sortOrder, result }))
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

export const findAdminGeometricElementRelationsCompleted = geometricElementRelations => ({
    type: actionTypes.FIND_ADMIN_GEOMETRIC_ELEMENT_RELATION_COMPLETED,
    geometricElementRelations
});

export const findAdminGeometricElementRelation = (geometricElementId, page, size, sortField, sortOrder, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.findAdminGeometricElementRelation(geometricElementId, page, size, sortField, sortOrder, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(findAdminGeometricElementRelationsCompleted({ geometricElementId, page, size, sortField, sortOrder, result }))
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

//Geometric element by id
export const findPublicGeometricElementByIdCompleted = geometricElement => ({
    type: actionTypes.FIND_PUBLIC_GEOMETRIC_ELEMENT_BY_ID_COMPLETED,
    geometricElement
});

export const findPublicGeometricElementById = (id, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.findPublicGeometricElementById(id, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(findPublicGeometricElementByIdCompleted(result))
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

export const findUserGeometricElementByIdCompleted = geometricElement => ({
    type: actionTypes.FIND_USER_GEOMETRIC_ELEMENT_BY_ID_COMPLETED,
    geometricElement
});

export const findUserGeometricElementById = (id, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.findUserGeometricElementById(id, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(findUserGeometricElementByIdCompleted(result))
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

export const findAdminGeometricElementByIdCompleted = geometricElement => ({
    type: actionTypes.FIND_ADMIN_GEOMETRIC_ELEMENT_BY_ID_COMPLETED,
    geometricElement
});

export const findAdminGeometricElementById = (id, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.findAdminGeometricElementById(id, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(findAdminGeometricElementByIdCompleted(result))
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}