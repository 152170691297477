import React from 'react';
import { connect } from 'react-redux'
import { Modal, ModalBody } from 'react-bootstrap';
import * as appSelectors from '../../app/selectors'
import './LoadingModal.css';

const mapStateToProps = function (state) {
    return {
        loading: appSelectors.isLoading(state)
    }
}

const LoadingDialog = ({ loading }) => {
    if (loading) {
        return (
            <Modal centered={true} show={loading} size={'sm'} dialogClassName="loadingModal">
                <ModalBody>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border loadingSpinner" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    return null;
}

export default connect(mapStateToProps)(LoadingDialog);