import React from 'react';
import { ErrorMessage, Field } from 'formik';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import * as FilePond from 'react-filepond'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import * as selectors from '../selectors';
import { connect } from 'react-redux';

const mapStateToProps = function (state) {
    return ({
        allFileFormat: selectors.getAllFileFormat(state)
    });
}

export const validateGeneralAttachedFileField = (mustValidate, mustBeImage, allFileFormat, values) => {
    let file = values.file;

    if (!mustValidate) {
        return;
    }

    if (!file) {
        return {
            files: <FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.attachedFiles.add.file" />,
                }}
            />
        }
    }

    let fileFormat = allFileFormat.filter(fileFormat =>
        fileFormat.mainMimeType === file.type || (fileFormat.additionalMimeType && fileFormat.additionalMimeType.includes(file.type)));

    if (!fileFormat.length) {
        return {
            files: <FormattedMessage
                id="project.attachedFiles.errors.formatNotPermitted"
                values={{
                    filename: <em>{file.filename}</em>
                }}
            />
        };
    }

    fileFormat = fileFormat[0];

    if (mustBeImage && !fileFormat.image) {
        return {
            files: <FormattedMessage
                id="project.attachedFiles.errors.mustBeImage"
                values={{
                    filename: <em>{file.filename}</em>
                }}
            />
        };
    }

    if (!fileFormat.allowedForGeneralAttachments) {
        return {
            files: <FormattedMessage
                id="project.attachedFiles.errors.formatNotPermitted"
                values={{
                    filename: <em>{file.filename}</em>
                }}
            />
        };
    }

    if (file.size > fileFormat.generalMaxSize) {
        let maxSize = fileFormat.generalMaxSize;
        return {
            files: <FormattedMessage
                id="project.attachedFiles.errors.maxSize"
                values={{
                    //eslint-disable-next-line
                    maxSize: maxSize >= 1024 * 1024 ? <FormattedNumber value={maxSize/(1024 * 1024)} style='unit' unit="megabyte" />
                        :
                        maxSize >= 1024 ?
                            //eslint-disable-next-line
                            <FormattedNumber value={maxSize/1024} style="unit" unit="kilobyte" />
                            :
                            //eslint-disable-next-line
                            <FormattedNumber value={maxSize} style="unit" unit="byte" />,
                    filename: <em>{file.filename}</em>
                }}
            />
        };
    }
}

const AddModifyGeneralAttachedFileFormFields = ({ mustBeImage, setFieldError, setFieldValue, errors, setHasAddedFiles, resetValues, files, ...props }) => {
    FilePond.registerPlugin(FilePondPluginFileEncode);

    return (
        <>
            <div className="form-group">
                <label htmlFor="description" className="font-weight-bold required">
                    <FormattedMessage id="project.elements.description" />
                </label>
                <Field as="textarea" name="description" className="form-control"
                    id="description" rows="3" />
            </div>
            <ErrorMessage name="description" render={(msg) =>
                <div id='descriptionError' className="alert alert-danger alert-dismissible fade show" role="alert"
                    hidden={typeof errors.description === "undefined"}
                >
                    <button type="button" className="close" aria-label="Close"
                        onClick={() => setFieldError("description", undefined)}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    {msg}
                </div>
            }
            />

            <div className="form-group">
                <label className="font-weight-bold required" htmlFor="file">
                    <FormattedMessage id="project.attachedFiles.add.file" />
                </label>
                <FilePond.FilePond
                    id="file"
                    instantUpload={false}
                    allowMultiple={false}
                    labelIdle={props.intl.formatMessage({ id: 'project.common.filepond.labelIdle' })}
                    labelTapToCancel={props.intl.formatMessage({ id: 'project.common.filepond.labelTapCancel' })}
                    labelFileLoading={props.intl.formatMessage({ id: 'project.common.filepond.labelLoading' })}
                    labelFileWaitingForSize={props.intl.formatMessage({ id: 'project.common.filepond.labelWaitForSize' })}
                    onaddfile={(error, file) => {
                        setHasAddedFiles(true);
                        setFieldValue("files", [file], false);
                        setFieldValue("file", {
                            filename: file.filename,
                            size: file.fileSize,
                            type: file.fileType,
                            base64: file.getFileEncodeBase64String()
                        })
                    }}
                    onremovefile={() => {
                        setHasAddedFiles(false);
                        setFieldValue("files", [], false);
                        setFieldValue("file", null);
                    }}
                    files={files}
                />
            </div>
            <ErrorMessage name="files" render={(msg) =>
                <div id='fileError' className="alert alert-danger alert-dismissible fade show" role="alert"
                    hidden={typeof errors.files === "undefined"}
                >
                    <button type="button" className="close" aria-label="Close"
                        onClick={() => setFieldError("files", undefined)}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    {msg}
                </div>
            }
            />
        </>
    )
}

export default connect(mapStateToProps)(injectIntl(AddModifyGeneralAttachedFileFormFields));