import { combineReducers } from 'redux';

import * as actionTypes from './actionTypes';
import * as geometricElementActionTypes from '../geometricElements/actionTypes';

const initialState = {
    totalGeneralAttachedFiles: null,
    allFileFormat: null
};

const allFileFormat = (state = initialState.allFileFormat, action) => {
    switch (action.type) {
        case actionTypes.FIND_ALL_FILE_FORMAT:
            return action.allFileFormat;
        default:
            return state;
    }
}

const totalGeneralAttachedFiles = (state = initialState.totalGeneralAttachedFiles, action) => {
    switch (action.type) {
        case actionTypes.FIND_TOTAL_GENERAL_ATTACHED_FILES_COMPLETED:
            return action.totalGeneralAttachedFiles ?
                action.totalGeneralAttachedFiles.items.map(generalAttachedFile =>
                ({
                    ...generalAttachedFile,
                    fileUrl: `${process.env.REACT_APP_BACKEND_URL}/attached_files/general/${generalAttachedFile.id}`
                }))
                : [];
        case actionTypes.ADD_GENERAL_ATTACHED_FILE_COMPLETED:
            let newGeneralAttachedFile = action.addedGeneralAttachedFile;
            newGeneralAttachedFile.fileUrl = `${process.env.REACT_APP_BACKEND_URL}/attached_files/general/${newGeneralAttachedFile.id}`
            return [...state, newGeneralAttachedFile];
        case actionTypes.MODIFY_GENERAL_ATTACHED_FILE_COMPLETED:
            let modifiedGeneralAttachedFile = action.modifiedGeneralAttachedFile;
            let index = state.map(function (generalAttachedFile) { return generalAttachedFile.id })
                .indexOf(modifiedGeneralAttachedFile.id);
            modifiedGeneralAttachedFile.fileUrl = `${process.env.REACT_APP_BACKEND_URL}/attached_files/general/${modifiedGeneralAttachedFile.id}?${new Date().getTime()}`
            state[index] = modifiedGeneralAttachedFile;
            return state;
        case actionTypes.DELETE_GENERAL_ATTACHED_FILE_COMPLETED:
            return state.filter(generalAttachedFile => generalAttachedFile.id !== action.generalAttachedFileId);
        case geometricElementActionTypes.ADD_GEOMETRIC_ELEMENT_TYPE_COMPLETED:
            if (action.addedGeometricElementType.legendFile) {
                let newGeneralAttachedFile = action.addedGeometricElementType.legendFile;
                newGeneralAttachedFile.fileUrl = `${process.env.REACT_APP_BACKEND_URL}/attached_files/general/${newGeneralAttachedFile.id}`
                return [...state, newGeneralAttachedFile];
            } else {
                return state;
            }
        case geometricElementActionTypes.MODIFY_GEOMETRIC_ELEMENT_TYPE_COMPLETED:
            if (action.modifiedGeometricElementType.legendFile) {
                let newGeneralAttachedFile = action.modifiedGeometricElementType.legendFile;
                newGeneralAttachedFile.fileUrl = `${process.env.REACT_APP_BACKEND_URL}/attached_files/general/${newGeneralAttachedFile.id}`
                return [...state, newGeneralAttachedFile];
            } else {
                return state;
            }
        case geometricElementActionTypes.ADD_GEOMETRIC_LAYER_COMPLETED:
            if (action.addedGeometricLayer.legendFile) {
                let newGeneralAttachedFile = action.addedGeometricLayer.legendFile;
                newGeneralAttachedFile.fileUrl = `${process.env.REACT_APP_BACKEND_URL}/attached_files/general/${newGeneralAttachedFile.id}`
                return [...state, newGeneralAttachedFile];
            } else {
                return state;
            }
        case geometricElementActionTypes.MODIFY_GEOMETRIC_LAYER_COMPLETED:
            if (action.modifiedGeometricLayer.legendFile) {
                let newGeneralAttachedFile = action.modifiedGeometricLayer.legendFile;
                newGeneralAttachedFile.fileUrl = `${process.env.REACT_APP_BACKEND_URL}/attached_files/general/${newGeneralAttachedFile.id}`
                return [...state, newGeneralAttachedFile];
            } else {
                return state;
            }
        default:
            return state;
    }
}

const reducer = combineReducers({
    totalGeneralAttachedFiles,
    allFileFormat
});

export default reducer;