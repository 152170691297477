import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Modal } from 'react-bootstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const GeometricElementFileGallery = ({ modalShow, geometricElementFiles, hideModalWindow, ...props }) => {

    const [imageIndex, setImageIndex] = useState(0);

    if (modalShow && geometricElementFiles) {
        let imagesList = geometricElementFiles.filter(file => file.contentType.startsWith("image/"));
        return (
            <Modal show={modalShow} size="xl" onHide={hideModalWindow}>
                <Modal.Header closeButton>
                    <Modal.Title>{imagesList[imageIndex].originalFilename}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carousel showStatus={false} useKeyboardArrows={true} infiniteLoop={true} dynamicHeight={true}
                        onChange={(index) => {
                            setImageIndex(index);
                        }}
                    >
                        {imagesList.map(file =>
                            <img key={file.uuid + "img"} className="img-fluid" src={file.fileUrl} alt="" />
                        )}
                    </Carousel>
                </Modal.Body>
            </Modal>
        );
    }

    return null;

}

export default GeometricElementFileGallery;