import * as actionTypes from './actionTypes';
import * as selectors from './selectors';

export const error = error => ({
    type: actionTypes.ERROR,
    error
});

export const loading = () => ({
    type: actionTypes.LOADING
});

export const loaded = () => ({
    type: actionTypes.LOADED
});

export const languageCompleted = language => ({
    type: actionTypes.APP_LANGUAGE,
    language
});

export const language = language => (dispatch, getState) => {
    const currentLanguage = selectors.getLanguage(getState());

    if(currentLanguage){
        if(currentLanguage!==language){
            dispatch(languageCompleted(language));
        }
    } else {
        dispatch(languageCompleted(language));
    }
};