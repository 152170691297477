import React from 'react';
import * as actions from '../actions';
import * as selectors from '../selectors';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import * as appSelectors from '../../app/selectors';
import * as userSelectors from '../../users/selectors';
import * as configurationParameterSelectors from '../../configurationParameter/selectors';
import InternationalizationRender, { getInternationalization } from '../../app/components/InternationalizationRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faListAlt, faChevronCircleDown, faChevronCircleUp, faPlus, faEye } from '@fortawesome/free-solid-svg-icons'
import jQuery from 'jquery';
import filterFactory, { textFilter, selectFilter, Comparator } from 'react-bootstrap-table2-filter';
import { DeleteDialog } from '../../common';
import AddModifyGeometricElementType from './AddModifyGeometricElementType';
import LegendPreviewComponent from './LegendPreviewComponent';

const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        user: userSelectors.getUser(state),
        allCodes: selectors.getAllCodes(state),
        totalGeometricElementType: selectors.getTotalGeometricElementType(state),
        allConfigurationParameters: configurationParameterSelectors.getTotalConfigurationParameters(state)
    }
}

class AllGeometricElementType extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filters: {},
            sizePerPage: 10,
            expandedRows: [],
            backendErrors: null,
            deleteGeometricElementTypeDialogShow: false,
            deleteGeometricElementTypeId: "",
            deleteGeometricElementTypeCode: "",
            addModifyGeometricElementTypeModalShow: false,
            geometricElementTypeToModify: null,
            totalGeometricElementType: null,
            previewLegendComponent: null,
            previewLegendModalShow: false
        }
    }

    componentDidMount() {
        this.setState({ totalGeometricElementType: this.props.totalGeometricElementType })
    }

    componentDidUpdate() {
        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });
        if (!this.state.totalGeometricElementType && this.props.totalGeometricElementType)
            this.setState({ totalGeometricElementType: this.props.totalGeometricElementType });
    }

    render() {

        let setBackendErrors = (backendError) => {
            this.setState({ backendErrors: backendError });
        }

        let handleBtnClick = (row) => {
            if (!this.state.expandedRows.includes(row.id)) {
                this.setState({ expandedRows: [row.id] });
            } else {
                this.setState({ expandedRows: [] });
            }
        }

        let clearAllFilters = (geometricElementTypeId) => {
            let { idFilter, codeFilter, nameFilter, geometryTypeFilter, legendFilter, styleFilter, opacityFilter } = this.state.filters;
            if (geometricElementTypeId) {
                idFilter(`${geometricElementTypeId}`)
            } else {
                idFilter('');
            }
            codeFilter('');
            nameFilter('');
            geometryTypeFilter('');
            legendFilter('')
            styleFilter('')
            opacityFilter('')
        }

        let allowChangeOpacityConfigurationParameter = false;
        if (this.props.allConfigurationParameters) {
            allowChangeOpacityConfigurationParameter = Object.values(this.props.allConfigurationParameters).filter(parameter =>
                parameter.code.indexOf("ALLOW_CONFIGURE_LAYERS_OPACITY") !== -1);
            if (allowChangeOpacityConfigurationParameter.length > 0) {
                allowChangeOpacityConfigurationParameter = allowChangeOpacityConfigurationParameter[0].value;
                allowChangeOpacityConfigurationParameter = (allowChangeOpacityConfigurationParameter === 'true');
            } else {
                allowChangeOpacityConfigurationParameter = true;
            }
        }

        if (this.props.user) {

            if (this.props.user.userRoleDto.code === "ADMIN" && this.props.allCodes) {

                let geometryTypeFilterOptions = [];
                let allGeometryType = this.props.allCodes.filter(code => code.codeGroup.groupCode.indexOf('GEOMETRY_TYPE') !== -1);
                allGeometryType.forEach((geometryType, index) => {
                    geometryTypeFilterOptions[index] = {
                        value: geometryType.code,
                        label: getInternationalization(this.props.language, geometryType.code, this.props.allCodes)
                    }
                });

                let legendTypeFilterOptions = [];
                let allLegendType = this.props.allCodes.filter(code => code.codeGroup.groupCode.indexOf('LEGEND_TYPE_GROUP_CODE') !== -1);
                allLegendType.forEach((legendType, index) => {
                    legendTypeFilterOptions[index] = {
                        value: legendType.code,
                        label: getInternationalization(this.props.language, legendType.code, this.props.allCodes)
                    }
                });

                jQuery(function () {
                    jQuery('.actionButton').tooltip({ trigger: "hover" });
                });

                jQuery(function () {
                    jQuery('.actionButton').tooltip().click(function () {
                        jQuery('.actionButton').tooltip("hide");
                    });
                });

                const attributeColumns = [{
                    dataField: 'attributeType.id',
                    text: 'Id',
                    sort: true,
                    headerAlign: 'left'
                },
                {
                    dataField: 'attributeType.description',
                    text: this.props.intl.formatMessage({ id: "project.elements.name" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={row.attributeType.code.code} listInternationalization={this.props.allCodes} />
                    )
                }, {
                    dataField: 'attributeType.dataType',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.dataType" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={row.attributeType.dataType} listInternationalization={this.props.allCodes} />
                    )
                }, {
                    dataField: 'mandatory',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.mandatory" }),
                    headerAlign: 'left',
                    sort: true,
                    formatter: (cellContent, row) => (
                        <FormattedMessage id={cellContent ? "project.common.yes" : "project.common.no"} />
                    )
                }, {
                    dataField: 'attributeOrder',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.attributeOrder" }),
                    headerAlign: 'left',
                    sort: true
                }
                ];

                const expandRow = {
                    renderer: row => (
                        <div className="card card-body" key={row.id + "container"}>
                            <h6 className="card-title text-left" key={row.id + "title"}>
                                <FormattedMessage id="project.common.attributes" />
                            </h6>
                            <BootstrapTable
                                bootstrap4
                                keyField='id'
                                columns={attributeColumns}
                                data={row.listAttributeType}
                                rowClasses="text-left"
                                defaultSorted={[{ dataField: 'attributeType.id', order: 'asc' }]}
                                noDataIndication={<FormattedMessage id="project.elements.attributes.noAttributes" />}
                                striped
                                condensed
                                key={row.id + 'table'}
                            />
                        </div>
                    ),
                    expandByColumnOnly: true,
                    showExpandColumn: false,
                    onlyOneExpanding: true,
                    expanded: this.state.expandedRows
                }

                const columns = [{
                    dataField: 'id',
                    text: 'Id',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    headerAlign: 'left',
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: "id" }),
                        comparator: Comparator.EQ,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.idFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'code',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.code" }),
                    headerAlign: 'left',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.attributes.code" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.codeFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'name',
                    text: this.props.intl.formatMessage({ id: "project.elements.name" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={row.code} listInternationalization={this.props.allCodes} />
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.name" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.nameFilter = filter;
                                return { filters }
                            })
                        }
                    }),
                    filterValue: (cell, row) => getInternationalization(this.props.language, row.code, this.props.allCodes)
                }, {
                    dataField: 'geometryType',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometryType" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={cellContent} listInternationalization={this.props.allCodes} />
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometryType" }).toLocaleLowerCase() }),
                        options: geometryTypeFilterOptions,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.geometryTypeFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'styles',
                    text: this.props.intl.formatMessage({ id: "project.elements.styles" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <>
                            <ul className="list-unstyled">
                                {row.geometryType.includes("ANY_GEOMETRY") || row.geometryType.includes("POINT") ?
                                    <li>
                                        {row.geometryType.includes("ANY") || row.geometryType.includes("POLYGON") || row.geometryType.includes("LINE") ?
                                            < b > <FormattedMessage id="project.elements.geom.Point" /></b>
                                            : ""
                                        }
                                        <ul className="list-unstyled">
                                            <li>{row.pointStyleName ? row.pointStyleName : "-"}</li>
                                        </ul>
                                    </li>
                                    :
                                    ""
                                }
                                {row.geometryType.includes("ANY_GEOMETRY") || row.geometryType.includes("LINE") ?
                                    <li>
                                        {row.geometryType.includes("ANY") || row.geometryType.includes("POLYGON") || row.geometryType.includes("POINT") ?
                                            <b><FormattedMessage id="project.elements.geom.LineString" /></b>
                                            : ""
                                        }
                                        <ul className="list-unstyled">
                                            <li>{row.lineStyleName ? row.lineStyleName : "-"}</li>
                                        </ul>
                                    </li>
                                    :
                                    ""
                                }
                                {row.geometryType.includes("ANY_GEOMETRY") || row.geometryType.includes("POLYGON") ?
                                    <li>
                                        {row.geometryType.includes("ANY") || row.geometryType.includes("POINT") || row.geometryType.includes("LINE") ?
                                            <b><FormattedMessage id="project.elements.geom.Polygon" /></b>
                                            : ""
                                        }
                                        <ul className="list-unstyled">
                                            <li>{row.polygonStyleName ? row.polygonStyleName : "-"}</li>
                                        </ul>
                                    </li>
                                    :
                                    ""
                                }
                            </ul>
                        </>
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.styles" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.styleFilter = filter;
                                return { filters }
                            })
                        }
                    }),
                    filterValue: (cell, row) => `${row.pointStyleName} ${row.lineStyleName} ${row.polygonStyleName}`
                }, {
                    dataField: 'opacity',
                    text: this.props.intl.formatMessage({ id: "project.elements.opacity" }),
                    headerAlign: 'left',
                    formatExtraData: allowChangeOpacityConfigurationParameter,
                    formatter: (cellContent, row, index, formatExtraData) => (
                        <ul className="list-unstyled">
                            <li>
                                <b><FormattedMessage id="project.elements.opacity.defaultOpacity" />:</b>
                                <ul className="list-unstyled">
                                    <li>{row.defaultOpacity != null ? `${row.defaultOpacity * 100}%` : 1 * 100 + '%'}</li>
                                </ul>
                            </li>

                            {formatExtraData && <li>
                                <b><FormattedMessage id="project.elements.opacity.allowChangeOpacity.table" />:</b>
                                <ul className="list-unstyled">
                                    <li>
                                        {row.allowChangeOpacity ?
                                            this.props.intl.formatMessage({ id: "project.common.yes" }) :
                                            this.props.intl.formatMessage({ id: "project.common.no" })}
                                    </li>
                                </ul>
                            </li>}
                        </ul>
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.opacity" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.opacityFilter = filter;
                                return { filters }
                            })
                        },
                    }),
                    filterValue: (cell, row) =>
                        `${row.defaultOpacity != null ? `${row.defaultOpacity * 100}%` : 1 * 100 + '%'} ${row.allowChangeOpacity ?
                            this.props.intl.formatMessage({ id: "project.common.yes" }) :
                            this.props.intl.formatMessage({ id: "project.common.no" })}`
                }, {
                    dataField: 'legendType',
                    text: this.props.intl.formatMessage({ id: "project.elements.legend" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <>
                            <div className="d-block">
                                {row.legendType !== "LEGEND_TYPE_NONE" ?
                                    <>
                                        <span onClick={() => {
                                            this.setState({
                                                previewLegendModalShow: true,
                                                previewLegendComponent: {
                                                    type: "geometricElementType",
                                                    component: row
                                                }
                                            })
                                        }}
                                            className="actionButton btn-link"
                                            style={{
                                                cursor: "pointer"
                                            }}
                                            id={"modify" + row.id}
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title={this.props.intl.formatMessage({ id: "project.elements.legend.show" })}
                                        >
                                            <FontAwesomeIcon icon={faEye} />
                                        </span>
                                    &nbsp;
                                </> : ""
                                }
                                <InternationalizationRender value={cellContent} />
                            </div>
                            {row.legendType === "LEGEND_TYPE_URL" ?
                                <div className="formattedUrl d-block">
                                    {row.legendTypeValue}
                                </div>
                                : ""
                            }
                        </>
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.legend" }).toLocaleLowerCase() }),
                        options: legendTypeFilterOptions,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.legendFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'action',
                    isDummyField: true,
                    text: this.props.intl.formatMessage({ id: "project.common.action" }),
                    headerAlign: 'left',
                    formatExtraData: this.state.expandedRows,
                    formatter: (cellContent, row, rowIndex, formatExtraData) => (
                        <div className="text-left">
                            <span onClick={() => {
                                this.props.history.push({
                                    pathname: `/geometric_elements/${row.id}`,
                                    state: {
                                        row: row
                                    }
                                })
                                this.props.dispatch(actions.findAdminGeometricElementsByElementTypeId(row.id, 0, 10, null, null, null, null,
                                    null, null, null, null, null, null, null, null, null, null, null, null, null, () => { }));
                            }
                            }
                                className="actionButton btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"viewElements" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.viewElements" })}
                            >
                                <FontAwesomeIcon icon={faListAlt} />
                            </span>
                            <span
                                onClick={() => {
                                    this.props.dispatch(actions.findAdminGeometricElementsByElementTypeId(row.id, 0, 10, null, null, null, null, null,
                                        null, null, null, null, null, null, null, null, null, null, null, null,
                                        () => {
                                            this.setState({
                                                backendErrors: null,
                                                addModifyGeometricElementTypeModalShow: true,
                                                geometricElementTypeToModify: row
                                            })
                                        }));
                                }}
                                className="actionButton btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"modify" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.modify" })}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </span>
                            <span onClick={() =>
                                this.setState({
                                    backendErrors: null,
                                    deleteGeometricElementTypeDialogShow: true,
                                    deleteGeometricElementTypeId: row.id,
                                    deleteGeometricElementTypeCode: row.code
                                })
                            }
                                className="actionButton btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"delete" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.delete" })}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </span>
                            {row.listAttributeType.length ?
                                <span id={"showHideAttributes" + row.id}
                                    className="actionButton btn-link"
                                    onClick={() => handleBtnClick(row)}
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: "0.5em"
                                    }}
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title={this.props.intl.formatMessage({ id: "project.elements.type.showAttributes" })}
                                >
                                    {<FontAwesomeIcon icon={!formatExtraData.includes(row.id) ? faChevronCircleDown : faChevronCircleUp} />}
                                </span>
                                :
                                ""
                            }
                        </div>
                    )
                },
                ];

                return (
                    <div className="card">
                        <br />
                        <div className="card-body">
                            <h3><FormattedMessage id="project.app.Header.admin.elementType" /></h3>
                            <div className="text-right">
                                <button className="btn btn-primary" onClick={() => {
                                    this.setState({
                                        backendErrors: null,
                                        addModifyGeometricElementTypeModalShow: true,
                                        geometricElementTypeToModify: null
                                    })
                                }}
                                    id="addGeometricElementType"
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                    &nbsp;
                                    <FormattedMessage id="project.elements.type.add" />
                                </button>
                            </div>

                            {/* Add/Modify geometric element type dialog */}
                            <AddModifyGeometricElementType
                                modalShow={this.state.addModifyGeometricElementTypeModalShow}
                                geometricElementTypeToModify={this.state.geometricElementTypeToModify}
                                backendErrors={this.state.backendErrors}
                                setBackendErrors={setBackendErrors}
                                handleSubmit={() => {
                                    this.setState({
                                        expandedRows: [],
                                        addModifyGeometricElementTypeModalShow: false,
                                        totalGeometricElementType: this.props.totalGeometricElementType
                                    });
                                    if (this.state.geometricElementTypeToModify) {
                                        clearAllFilters(this.state.geometricElementTypeToModify.id);
                                    } else {
                                        let newGeometricElementType =
                                            this.props.totalGeometricElementType[this.props.totalGeometricElementType.length - 1];
                                        if (newGeometricElementType && newGeometricElementType.id) {
                                            clearAllFilters(newGeometricElementType.id);
                                        } else {
                                            clearAllFilters();
                                        }
                                    }
                                }}
                                hideModalWindow={() => this.setState({ addModifyGeometricElementTypeModalShow: false })}
                            />

                            {/* Delete geometric element type dialog */}
                            <DeleteDialog
                                modalShow={this.state.deleteGeometricElementTypeDialogShow}
                                title={this.props.intl.formatMessage({ id: 'project.common.delete' })}
                                details={this.props.intl.formatMessage(
                                    { id: 'project.elements.delete.message' },
                                    { element: <b>{this.state.deleteGeometricElementTypeCode}</b> })
                                }
                                backendErrors={this.state.backendErrors}
                                hideModalWindow={() => { this.setState({ deleteGeometricElementTypeDialogShow: false }) }}
                                handleSubmit={() => {
                                    this.props.dispatch(actions.deleteGeometricElementType(this.state.deleteGeometricElementTypeId,
                                        () => {
                                            this.setState({
                                                deleteGeometricElementTypeDialogShow: false,
                                                totalGeometricElementType: this.props.totalGeometricElementType
                                            });
                                        },
                                        (error) => setBackendErrors(error)))
                                }}
                                setBackendErrors={setBackendErrors}
                            />
                            {/* Preview legend */}
                            <LegendPreviewComponent
                                modalShow={this.state.previewLegendModalShow}
                                previewLegendComponent={this.state.previewLegendComponent}
                                hideModalWindow={() => {
                                    this.setState({
                                        previewLegendModalShow: false,
                                        previewLegendComponent: null
                                    });
                                }}
                            />
                            <br />
                            <BootstrapTable
                                bootstrap4
                                ref="table"
                                keyField="id"
                                columns={columns}
                                rowClasses="text-left"
                                data={
                                    this.state.totalGeometricElementType ?
                                        this.state.totalGeometricElementType : []
                                }
                                noDataIndication={this.state.totalGeometricElementType ?
                                    this.props.intl.formatMessage({ id: "project.elements.types.noElements" }) :
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                }
                                defaultSorted={[{
                                    dataField: 'id',
                                    order: 'asc'
                                }]}
                                pagination={paginationFactory({
                                    sizePerPage: this.state.sizePerPage,
                                    hidePageListOnlyOnePage: true,
                                    alwaysShowAllBtns: true,
                                    onPageChange: (page, sizePerPage) => {
                                        this.setState({
                                            expandedRows: [],
                                            sizePerPage
                                        })
                                    },
                                    onSizePerPageChange: (sizePerPage, page) => {
                                        this.setState({
                                            sizePerPage
                                        });
                                    },
                                    showTotal: true,
                                    paginationTotalRenderer: (from, to, size) => {
                                        return <FormattedMessage id="project.common.tables.totalElements"
                                            values={{
                                                from: from,
                                                to: to,
                                                total: size
                                            }
                                            }
                                        />
                                    },
                                    nextPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.nextPageTitle' }),
                                    prePageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.prePageTitle' }),
                                    lastPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.lastPageTitle' }),
                                    firstPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.firstPageTitle' }),
                                    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => (
                                        <li
                                            key={text}
                                            role="presentation"
                                            className={`dropdown-item ${this.state.sizePerPage === Number(text) ? "active" : ""}`}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                onSizePerPageChange(page);
                                            }}
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {text}
                                        </li>
                                    )
                                })}
                                expandRow={expandRow}
                                filter={filterFactory()}
                                filterPosition={"top"}
                                striped
                                condensed
                            />

                        </div>
                        <br />
                    </div>
                );
            }

            if (this.props.user.userRoleDto.code !== "ADMIN") {
                return (
                    <div className="container text-center">
                        <br />
                        <div className="card-body alert alert-danger" role="alert">
                            <h4 className="card-text"><FormattedMessage id="project.common.permissionDenied" /></h4>
                        </div>
                    </div>
                );
            }

            return null;

        }

        if (!this.props.user) {
            return (
                <div className="container text-center">
                    <br />
                    <div className="card-body alert alert-danger" role="alert">
                        <h4 className="card-text"><FormattedMessage id="project.common.mustLogin" /></h4>
                    </div>
                </div>
            );
        }

        return null;
    }

}


export default withRouter(connect(mapStateToProps)(injectIntl(AllGeometricElementType)));