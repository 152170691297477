import { config, appFetch } from './appFetch';

export const findAllFileFormat = (language, onSuccess, onErrors) =>
    appFetch(`/attached_files/file_format/all`, config('GET', null, language), onSuccess, onErrors);

export const findTotalGeneralAttachedFiles = (page, size, sortField, sortOrder, language, onSuccess, onErrors) => {
    let path = `/attached_files/general`;

    if (page) {
        path += `?page=${page}`;
    }

    if (size) {
        if (page) {
            path += `&size=${size}`;
        } else {
            path += `?size=${size}`;
        }
    }

    if (sortField) {
        if (page || size) {
            path += `&sortField=${sortField}`;
        } else {
            path += `?sortField=${sortField}`;
        }
    }

    if (sortOrder) {
        if (page || size || sortField) {
            path += `&sortOrder=${sortOrder}`;
        } else {
            path += `?sortOrder=${sortOrder}`;
        }
    }

    appFetch(path, config('GET', null, language), onSuccess, onErrors);
}

export const addGeneralAttachedFile = (json, language, onSuccess, onErrors) =>
    appFetch(`/attached_files/general/`, config('POST', json, language), onSuccess, onErrors);

export const modifyGeneralAttachedFile = (id, json, language, onSuccess, onErrors) =>
    appFetch(`/attached_files/general/${id}`, config('POST', json, language), onSuccess, onErrors);

export const deleteGeneralAttachedFile = (id, language, onSuccess, onErrors) =>
    appFetch(`/attached_files/general/${id}`, config('DELETE', null, language), onSuccess, onErrors);