import {config, appFetch} from './appFetch';

export const findAllConfigurationParameter = (page, size, sortField, sortOrder, language, onSuccess, onErrors) => {
    let path = `/configuration_parameter/all`;

    if (page) {
        path += `?page=${page}`;
    }

    if (size) {
        if (page) {
            path += `&size=${size}`;
        } else {
            path += `?size=${size}`;
        }
    }

    if (sortField) {
        if (page || size) {
            path += `&sortField=${sortField}`;
        } else {
            path += `?sortField=${sortField}`;
        }
    }

    if (sortOrder) {
        if (page || size || sortField) {
            path += `&sortOrder=${sortOrder}`;
        } else {
            path += `?sortOrder=${sortOrder}`;
        }
    }

    appFetch(path, config('GET', null, language), onSuccess, onErrors);
}

export const updateConfigurationParameter = (id, json, language, onSuccess, onErrors) => {
    appFetch(`/configuration_parameter/update/${id}`, config('POST', json, language), onSuccess, onErrors);
}