export const FIND_GEOMETRIC_ELEMENT_TYPE_BY_ID_COMPLETED = "elementType/findGeometricElementTypeByIdCompleted";
export const FIND_ADMIN_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED = "elementType/findAdminGeometricElementsByElementTypeIdCompleted";
export const FIND_USER_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED = "elementType/findUserGeometricElementsByElementTypeIdCompleted";
export const FIND_PUBLIC_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED = "elementType/findPublicGeometricElementsByElementTypeIdCompleted";
export const FIND_ALL_CODES_COMPLETED = "elementType/findAllCodesCompleted";
export const INSERT_ATTRIBUTE_TYPE_COMPLETED = "geometricElements/insertGeometricElementTypeCompleted";
export const LIST_PROVINCES = "geometricElements/listProvinces";
export const LIST_COUNCILS = "geometricElements/listCouncils";
export const LIST_PARISHES = "geometricElements/listParishes";
export const LIST_COUNTIES = "geometricElements/listCounties";
export const LIST_ENTITY_POPULATIONS = "geometricElements/listEntityPopulations";
export const MAP_CURRENT_EXTENT = "openlayersmap/mapCurrentExtent";
export const FIND_PUBLIC_GEOMETRIC_ELEMENT_RELATION_COMPLETED = "geometricElements/relations/findPublicGeometricElementRelationCompleted";
export const FIND_USER_GEOMETRIC_ELEMENT_RELATION_COMPLETED = "geometricElements/relations/findUserGeometricElementRelationCompleted";
export const FIND_ADMIN_GEOMETRIC_ELEMENT_RELATION_COMPLETED = "geometricElements/relations/findAdminGeometricElementRelationCompleted";
export const FIND_PUBLIC_GEOMETRIC_ELEMENT_BY_ID_COMPLETED = "geometricElements/findPublicGeometricElementByIdCompleted";
export const FIND_USER_GEOMETRIC_ELEMENT_BY_ID_COMPLETED = "geometricElements/findUserGeometricElementByIdCompleted";
export const FIND_ADMIN_GEOMETRIC_ELEMENT_BY_ID_COMPLETED = "geometricElements/findAdminGeometricElementByIdCompleted";
export const FIND_TOTAL_GEOMETRIC_ELEMENT_TYPE_COMPLETED = "elementType/findTotalGeometricElementTypeCompleted";
export const ADD_GEOMETRIC_ELEMENT_TYPE_COMPLETED = "geometricElements/addGeometricElementTypeCompleted";
export const MODIFY_GEOMETRIC_ELEMENT_TYPE_COMPLETED = "geometricElements/modifyGeometricElementTypeCompleted";
export const DELETE_GEOMETRIC_ELEMENT_TYPE_COMPLETED = "geometricElements/deleteGeometricElementTypeCompleted";
export const FIND_TOTAL_ATTRIBUTE_TYPE_COMPLETED = "elementType/findTotalAttributeTypeCompleted";
export const ADD_ATTRIBUTE_TYPE_COMPLETED = "geometricElements/addAttributeTypeCompleted";
export const MODIFY_ATTRIBUTE_TYPE_COMPLETED = "geometricElements/modifyAttributeTypeCompleted";
export const DELETE_ATTRIBUTE_TYPE_COMPLETED = "geometricElements/deleteAttributeTypeCompleted";
export const FIND_TOTAL_GEOMETRIC_LAYER_GROUP_COMPLETED = "geometricLayers/findTotalGeometricLayerGroupCompleted";
export const ADD_GEOMETRIC_LAYER_GROUP_COMPLETED = "geometricLayers/addGeometricLayerGroupCompleted";
export const MODIFY_GEOMETRIC_LAYER_GROUP_COMPLETED = "geometricLayers/modifyGeometricLayerGroupCompleted";
export const DELETE_GEOMETRIC_LAYER_GROUP_COMPLETED = "geometricLayers/deleteGeometricLayerGroupCompleted";
export const FIND_TOTAL_GEOMETRIC_LAYER_COMPLETED = "geometricLayers/findTotalGeometricLayerCompleted";
export const ADD_GEOMETRIC_LAYER_COMPLETED = "geometricLayers/addGeometricLayerCompleted";
export const MODIFY_GEOMETRIC_LAYER_COMPLETED = "geometricLayers/modifyGeometricLayerCompleted";
export const DELETE_GEOMETRIC_LAYER_COMPLETED = "geometricLayers/deleteGeometricLayerCompleted";