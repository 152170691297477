import { combineReducers } from 'redux';
import * as actionTypes from './actionTypes';

const initialState = {
    provinces: null,
    municipalitiesByProvince: null,
    parishesByMunicipality: null
}

const provinces = (state = initialState.provinces, action) => {
    switch (action.type) {
        case actionTypes.FIND_ALL_PROVINCES_COMPLETED:
            return action.provinces;
        default:
            return state;
    }
} 

const municipalitiesByProvince = (state = initialState.municipalitiesByProvince, action) => {
    switch (action.type) {
        case actionTypes.FIND_MUNICIPALITIES_BY_PROVINCE_COMPLETED:
            return action.municipalitiesByProvince;
        default:
            return state;
    }
} 

const parishesByMunicipality = (state = initialState.parishesByMunicipality, action) => {
    switch (action.type) {
        case actionTypes.FIND_PARISHES_BY_MUNICIPALITY_COMPLETED:
            return action.parishesByMunicipality;
        default:
            return state;
    }
} 


const reducer = combineReducers({
    provinces,
    municipalitiesByProvince,
    parishesByMunicipality
});

export default reducer;