import React, { Component } from "react";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import ImageLayer from "ol/layer/Image";
import OSM from "ol/source/OSM";
import TileWMS from "ol/source/TileWMS";
import ImageWMS from "ol/source/ImageWMS";
import LayerSwitcher from "ol-layerswitcher";
import Group from 'ol/layer/Group';
import XYZ from 'ol/source/XYZ';
import { Modify } from 'ol/interaction';
import {
  AddGeometricElementController, AreaMeasureController,
  LengthMeasureController, ZoomToExtentController, PopupController, EntityPopulationCenterControl, ShowLegendControl, ExportToPDFControl
} from './OpenlayersCustomControllers';
import { FullScreen, ZoomSlider, OverviewMap, ZoomToExtent, Zoom, ScaleLine, MousePosition } from 'ol/control';
import './popup.css';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Overlay, Collection, Feature } from "ol";
import jQuery from 'jquery';
import { GeoJSON, WFS } from 'ol/format';
import { equalTo as equalToFilter } from 'ol/format/filter';
import { boundingExtent, buffer, getHeight } from 'ol/extent';
import { transform } from 'ol/proj';
import * as userSelectors from '../../users/selectors'
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AddGeometricElement from './AddGeometricElement';
import * as elementService from '../../../backend/elementService';
import * as configurationParameterSelectors from '../../configurationParameter/selectors';
import { FormattedMessage, injectIntl } from "react-intl";
import * as actions from '../actions';
import * as appActions from '../../app/actions';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js'
import { Circle as CircleStyle, Style, Fill, Stroke } from "ol/style";
import GeometricElementFileGallery from "./GeometricElementFileGallery";
import { getInternationalization } from "../../app/components/InternationalizationRender";
import GeographicalViewerLegend from "./GeographicalViewerLegend";
import { Modal } from "react-bootstrap";
import GeographicalViewerExportMenu from "./GeographicalViewerExportMenu";
import { format } from "ol/coordinate";

const MAP_ID = "map";
const CLICK_PIXEL_TOLERANCE = 10;
const MAP_CRS = 'EPSG:3857';
const FEATURES_CRS = 'EPSG:4326';
export const WMS_VERSION = '1.3.0';

const mapStateToProps = function (state) {
  return {
    user: userSelectors.getUser(state),
    locale: appSelectors.getLanguage(state),
    allCodes: selectors.getAllCodes(state),
    geometricElementType: selectors.getGeometricElementType(state),
    allGeometricElementType: selectors.getTotalGeometricElementType(state),
    parameters: configurationParameterSelectors.getTotalConfigurationParameters(state),
    listProvinces: selectors.getListProvinces(state),
    listCouncils: selectors.getListCouncils(state),
    listParishes: selectors.getListParishes(state),
    listEntityPopulations: selectors.getListEntityPopulations(state),
    listCounties: selectors.getListCounties(state),
    listAllGeometricLayerGroup: selectors.getTotalGeometricLayerGroup(state),
    mapCurrentExtent: selectors.getMapCurrentExtent(state)
  }
}

let hideAllOverlays = (map) => {
  map.getOverlays().forEach(overlay => {
    overlay.setPosition(null);
  });
}

const insertGeometricLayerGroup = (geometricLayerGroup, map, otherLayersGroup, locale, allCodes, allowConfigureLayersOpacity) => {
  if (geometricLayerGroup.listGeometricLayer.length) {
    let group = new Group({
      title: getInternationalization(locale, geometricLayerGroup.code.code, allCodes),
      fold: geometricLayerGroup.initiallyOpen ? 'open' : 'close'
    });
    geometricLayerGroup.includeInsideGroupLayer ?
      otherLayersGroup.getLayers().push(group)
      :
      map.addLayer(group);
    geometricLayerGroup.listGeometricLayer.sort((a, b) => {
      return a.order - b.order;
    }).forEach(geometricLayer => {
      let source = null;
      if (geometricLayer.type.code === 'WMS') {
        source = new TileWMS({
          projection: MAP_CRS,
          url: geometricLayer.serverUrl ?
            geometricLayer.serverUrl
            :
            `${process.env.REACT_APP_BACKEND_URL}/mapserver`,
          params: {
            'layers': [geometricLayer.internalName],
            'VERSION': geometricLayer.version ? geometricLayer.version : WMS_VERSION
          },
          // Prevent CORS error when exporting to PDF
          crossOrigin: 'anonymous'
        });
      }
      if (geometricLayer.type.code === "OSM") {
        source = new OSM({
          // Prevent CORS error when exporting to PDF
          crossOrigin: 'anonymous'
        });
      }
      if (geometricLayer.type.code === "XYZ") {
        source = new XYZ({
          url: geometricLayer.serverUrl,
          // Prevent CORS error when exporting to PDF
          crossOrigin: 'anonymous'
        });
      }
      if (source) {
        let layer = new TileLayer({
          title: getInternationalization(locale, geometricLayer.code.code, allCodes),
          enableOpacitySlider: allowConfigureLayersOpacity && geometricLayer.allowChangeOpacity,
          opacity: geometricLayer.defaultOpacity ? geometricLayer.defaultOpacity : 1,
          visible: geometricLayer.initiallyVisible ? true : false,
          legendType: geometricLayer.legendType,
          legendTypeValue: geometricLayer.legendTypeValue,
          showAllLayersOnLegend: geometricLayer.showAllLayersOnLegend,
          source: source,
          type: geometricLayer.geometricLayerGroup.code.code === "BACKGROUND_LAYER_GROUP" && 'base'
        })
        group.getLayers().insertAt(0, layer);
      }
    });
  }
}

class GeographicalViewer extends Component {

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      center: [0, 0],
      zoom: 1,
      geom: null,
      insertFromNavBar: props.location.state && props.location.state.insertFromNavBar ? props.location.state.insertFromNavBar : null,
      featureToModify: props.featureToModify ?
        props.featureToModify :
        props.location.state && props.location.state.featureToModify ? props.location.state.featureToModify : null,
      centerFeature: props.centerFeature ?
        props.centerFeature :
        props.location.state && props.location.state.centerFeature ? props.location.state.centerFeature : null,
      user: props.user,
      locale: props.locale,
      geometricElementFileGalleryModalShow: false,
      geometricElementFileGalleryFiles: null,
      drawInteraction: null,
      dragZoomInteraction: null,
      modifyInteraction: null,
      snapInteraction: null,
      showLegend: false,
      generatePdfErrorModalShow: false,
      generatePdfMenuModalShow: false
    };

    this.hideShowLegend = () => {
      this.setState(prevState => ({
        showLegend: !prevState.showLegend
      }))
    }

    this.drawSource = new VectorSource({
      format: new GeoJSON(),
      wrapX: false
    });

    this.handleGeom = geom => {
      this.setState({ geom: geom })
    };

    this.resetModifyFeature = () => {
      this.setState({ featureToModify: null })
    }

    this.resetInsertFromNavBar = () => {
      this.setState({ insertFromNavBar: null })
    }

    this.elementsGroup = new Group({
      title: this.props.intl.formatMessage({ id: 'project.elements.map.elements' }),
      fold: 'open'
    })

    this.myElementsGroup = new Group({
      title: this.props.intl.formatMessage({ id: 'project.elements.map.myElements' }),
      fold: 'open'
    })

    this.layerSwitcherControl = new LayerSwitcher({
      activationMode: 'click',
      groupSelectStyle: 'children',
      startActive: true,
      tipLabel: this.props.intl.formatMessage({ id: 'project.elements.map.layersTip.show' }),
      collapseTipLabel: this.props.intl.formatMessage({ id: 'project.elements.map.layersTip.hide' }),
      opacityTipLabel: this.props.intl.formatMessage({ id: 'project.elements.map.opacityLabelTip' })
    });

    this.otherLayersGroup = new Group({
      title: this.props.intl.formatMessage({ id: 'project.elements.map.otherLayers' }),
      fold: 'open',
      layers: [],
    });

    let fullScreenLabel = document.createElement('span');
    fullScreenLabel.innerHTML = '<i class="fas fa-expand-alt"></i>';
    let fullScreenLabelActive = document.createElement('span');
    fullScreenLabelActive.innerHTML = '<i class="fas fa-compress-alt"></i>';
    let zoomInLabel = document.createElement('span');
    zoomInLabel.innerHTML = '<i class="fas fa-plus"></i>';
    let zoomOutLabel = document.createElement('span');
    zoomOutLabel.innerHTML = '<i class="fas fa-minus"></i>';

    this.olmap = new Map({
      controls: [
        new FullScreen({
          label: fullScreenLabel,
          labelActive: fullScreenLabelActive,
          tipLabel: this.props.intl.formatMessage({ id: 'project.elements.map.fullScreenTip' })
        }),
        new Zoom({
          zoomInLabel: zoomInLabel,
          zoomInTipLabel: this.props.intl.formatMessage({ id: 'project.elements.map.zoomInTipLabel' }),
          zoomOutLabel: zoomOutLabel,
          zoomOutTipLabel: this.props.intl.formatMessage({ id: 'project.elements.map.zoomOutTipLabel' }),
        }),
        new ZoomSlider(),
        new ZoomToExtent({
          extent: this.props.maxExtentParameter,
          label: this.props.maxExtentIcon,
          tipLabel: this.props.intl.formatMessage({ id: 'project.elements.map.fitToExtentTip' })
        })
      ],
      target: null,
      layers: [],
      view: new View({
        projection: MAP_CRS,
        center: this.state.center,
        zoom: this.state.zoom,
        extent: this.props.maxExtentParameter
      })
    });
  }

  componentDidMount() {

    this.olmap.on('moveend', () => {
      this.props.dispatch(actions.mapCurrentExtent(this.olmap.getView().calculateExtent()));
    })

    this.removeAllAddedInteractions = () => {
      this.olmap.removeInteraction(this.state.drawInteraction);
      this.olmap.removeInteraction(this.state.dragZoomInteraction);
      this.olmap.removeInteraction(this.state.modifyInteraction);
      this.olmap.removeInteraction(this.state.snapInteraction);
    }

    this.setInteractions = (drawInteraction, dragZoomInteraction, modifyInteraction, snapInteraction) => {
      this.setState({
        drawInteraction, dragZoomInteraction, modifyInteraction, snapInteraction
      });
    }

    this._isMounted = true;
    var map = this.olmap;
    var allCodes = this.props.allCodes;
    this.olmap.setTarget("map");
    let openAddGeometricElementForm = this.props.openAddGeometricElementForm

    let allGeometricElementType = this.props.allGeometricElementType;

    var container = document.getElementById('popup');
    var content = document.getElementById('popup-content');
    var closer = document.getElementById('popup-closer');

    var overlay = new Overlay({
      element: container,
      autoPan: true,
      offset: [0, -10],
      positioning: 'top-right'
    });

    map.addOverlay(overlay);

    closer.onclick = function () {
      overlay.setPosition(undefined);
      closer.blur();
      return false;
    }

    this.clearDrawSource = () => {
      this.drawSource.clear({ fast: true })
    }

    var drawVector = new VectorLayer({
      source: this.drawSource,
      style: new Style({
        fill: new Fill({
          color: 'rgba(200, 0, 0, 0.5)',
        }),
        stroke: new Stroke({
          color: 'rgba(180, 0, 0, 1)',
          width: 3
        }),
        image: new CircleStyle({
          radius: 5,
          fill: new Fill({
            color: 'rgba(200, 0, 0, 0.5)',
          }),
          stroke: new Stroke({
            color: 'rgba(180, 0, 0, 1)',
            width: 2
          })
        })
      })
    });

    var measureTooltipElement = document.createElement('div');
    measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
    var measureTooltipOverlay = new Overlay({
      element: measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center'
    });

    map.addOverlay(measureTooltipOverlay);

    this.handleGeom = geom => {
      this.setState({ geom: geom })
    }

    this.handleModifyFeature = (modifyFeature) => {
      this.setState({ featureToModify: modifyFeature })
    }

    let handleGeom = this.handleGeom;
    let handleModifyFeature = this.handleModifyFeature;
    let locale = this.state.locale;
    let user = this.props.user;
    let dispatch = this.props.dispatch;
    let setInteractions = this.setInteractions;

    let modifyFeature = function (map, source, feature) {
      if (jQuery('#popupcontrollerdiv').hasClass('ol-control-active'))
        jQuery("#poupcontrollerbutton").click();

      if (!(feature instanceof Feature)) {
        if (jQuery('.layer-switcher').hasClass('shown'))
          jQuery('.layer-switcher button').click();
        var featureRequest = new WFS().writeGetFeature({
          srsName: FEATURES_CRS,
          featureNS: `${process.env.REACT_APP_BACKEND_URL}/mapserver`,
          featureTypes: ['geometric_element_point', 'geometric_element_line', 'geometric_element_polygon'],
          outputFormat: 'application/json',
          geometryName: FEATURES_CRS,
          filter: equalToFilter('id', feature.id)
        });

        let configFetch = {
          method: 'POST',
          body: new XMLSerializer().serializeToString(featureRequest)
        }

        if (configFetch.headers) {
          configFetch.headers['Authorization'] = `Bearer ${sessionStorage.getItem("serviceToken")}`;
        } else {
          configFetch.headers = { 'Authorization': `Bearer ${sessionStorage.getItem("serviceToken")}` };
        }

        fetch(`${process.env.REACT_APP_BACKEND_URL}/mapserver?SERVICE=WFS`, configFetch)
          .then(function (response) {
            return response.json();
          }).then(function (json) {
            var features = new GeoJSON().readFeatures(json);
            let featureToModify = features[0];
            let clonedFeature = featureToModify.clone();
            clonedFeature.getGeometry().transform(FEATURES_CRS, MAP_CRS);
            let featureCollection = new Collection([]);
            featureCollection.getArray().splice(0, 0, clonedFeature);
            let modifyInteraction = new Modify({
              source: source
            });
            setInteractions(null, null, modifyInteraction, null);
            map.addInteraction(modifyInteraction);
            source.addFeature(clonedFeature);
            modifyInteraction.on('modifyend', function (event) {
              var features = event.features;
              var featureToTransform = features.array_[0].clone()
              var geometry = featureToTransform.getGeometry();
              geometry.transform(MAP_CRS, FEATURES_CRS)
              handleGeom({
                "type": `${geometry.getType()}`,
                "coordinates": geometry.getCoordinates()
              });
            });
            openAddGeometricElementForm(map, clonedFeature.getGeometry());
          });
      } else {
        let clonedFeature = feature.clone();
        let startModifyFeature = (featureToModify) => {
          if (jQuery('.layer-switcher').hasClass('shown'))
            jQuery('.layer-switcher button').click();
          handleModifyFeature(featureToModify);
          openAddGeometricElementForm(map, clonedFeature.getGeometry());
          clonedFeature.getGeometry().transform(FEATURES_CRS, MAP_CRS);
          let featureCollection = new Collection([]);
          featureCollection.getArray().splice(0, 0, clonedFeature);
          let modifyInteraction = new Modify({
            source: source
          });
          setInteractions(null, null, modifyInteraction, null);
          map.addInteraction(modifyInteraction);
          source.addFeature(clonedFeature);
          modifyInteraction.on('modifyend', function (event) {
            var features = event.features;
            var featureToTransform = features.array_[0].clone();
            var geometry = featureToTransform.getGeometry();
            geometry.transform(MAP_CRS, FEATURES_CRS);
            handleGeom({
              "type": `${geometry.getType()}`,
              "coordinates": geometry.getCoordinates()
            });
          });
        }
        if (user) {
          if (user.userRoleDto.code === "ADMIN") {
            elementService.findAdminGeometricElementById(clonedFeature.get('id'), locale,
              (featureToModify) => {
                startModifyFeature(featureToModify);
              }, () => {
                dispatch(appActions.error({
                  message:
                    <FormattedMessage
                      id="project.elements.modify.error"
                    />
                }));
              });
          } else {
            elementService.findUserGeometricElementById(clonedFeature.get('id'), locale,
              (featureToModify) => {
                startModifyFeature(featureToModify);
              }, () => {
                dispatch(appActions.error({
                  message:
                    <FormattedMessage
                      id="project.elements.modify.error"
                    />
                }));
              });
          }
        } else {
          dispatch(appActions.error({
            message:
              <FormattedMessage
                id="project.elements.modify.error"
              />
          }));
        }
      }
    };

    let removeCenterFeature = () => {
      this.setState({ centerFeature: null });
    }

    let centerFeature = function (map, feature) {
      let geometry = null;
      if (feature instanceof Feature) {
        geometry = feature.clone().getGeometry();
      } else {
        geometry = new GeoJSON().readGeometry(feature.geom);
      }
      geometry.transform(FEATURES_CRS, MAP_CRS)

      if (geometry.getType() === 'Point') {
        map.getView().fit(
          buffer(geometry.getExtent(), 50),
          {
            size: map.getSize(),
            duration: 100,
            callback: function () {
              overlay.panIntoView({ margin: 20 });
            }
          }
        );
      } else {
        map.getView().fit(
          buffer(geometry.getExtent(), getHeight(geometry.getExtent())),
          {
            size: map.getSize(),
            duration: 100,
            callback: function () {
              overlay.panIntoView({ margin: 20 });
            }
          }
        );
      }
      removeCenterFeature()
    };

    this.showPopup = (evt, formatMessage, findGeometricElementType, history) => {
      if (!(jQuery('#popupcontrollerdiv').hasClass('ol-control-active'))) {
        return;
      }
      var extent = map.getView().calculateExtent();
      var offsetWidth = document.getElementById(MAP_ID).offsetWidth;
      var metersPerPixel = Math.abs(extent[0] - extent[2]) / offsetWidth;
      var tolerance = this.props.clickPixelTolerance ? this.props.clickPixelTolerance * metersPerPixel : CLICK_PIXEL_TOLERANCE * metersPerPixel;
      let setGeometricElementFileGalleryModalShow = (modalShow) => {
        this.setState({ geometricElementFileGalleryModalShow: modalShow });
      }
      let setGeometricElementFileGalleryFiles = (files) => {
        this.setState({ geometricElementFileGalleryFiles: files });
      }
      var featureRequest = new WFS().writeGetFeature({
        srsName: FEATURES_CRS,
        featureNS: `${process.env.REACT_APP_BACKEND_URL}/mapserver`,
        featureTypes: ['geometric_element_point', 'geometric_element_line', 'geometric_element_polygon'],
        outputFormat: 'application/json',
        geometryName: FEATURES_CRS,
        bbox: boundingExtent(
          [
            transform([
              evt.coordinate[0] - tolerance,
              evt.coordinate[1] - tolerance
            ],
              MAP_CRS, FEATURES_CRS),
            transform([
              evt.coordinate[0] + tolerance,
              evt.coordinate[1] + tolerance
            ],
              MAP_CRS, FEATURES_CRS)
          ]
        ),
      });

      let configFetch = {
        method: 'POST',
        body: new XMLSerializer().serializeToString(featureRequest)
      }

      let authorization = {
        method: 'GET',
      }


      let getGeometricElementUrl = (featureId) => {
        return `${process.env.REACT_APP_BACKEND_URL}/elements/geometric_element/${featureId}`
      }

      if (this.state.user) {
        if (configFetch.headers) {
          configFetch.headers['Authorization'] = `Bearer ${sessionStorage.getItem("serviceToken")}`;
        } else {
          configFetch.headers = { 'Authorization': `Bearer ${sessionStorage.getItem("serviceToken")}` };
        }

        if (authorization.headers) {
          authorization.headers['Authorization'] = `Bearer ${sessionStorage.getItem("serviceToken")}`;
        } else {
          authorization.headers = { 'Authorization': `Bearer ${sessionStorage.getItem("serviceToken")}` };
        }
        if (this.state.user.userRoleDto.code === "ADMIN") {
          getGeometricElementUrl = (featureId) => {
            return `${process.env.REACT_APP_BACKEND_URL}/elements/geometric_element/${featureId}/admin`
          }
        } else {
          getGeometricElementUrl = (featureId) => {
            return `${process.env.REACT_APP_BACKEND_URL}/elements/geometric_element/${featureId}/user`
          }
        }
      }

      let source = this.drawSource;

      fetch(`${process.env.REACT_APP_BACKEND_URL}/mapserver?SERVICE=WFS`,
        configFetch
      ).then(function (response) {
        return response.json();
      }).then(function (json) {
        var features = new GeoJSON().readFeatures(json);

        if (features.length !== 0) {

          let popupContent = "";
          if (features.length === 1) {
            jQuery(content).html('<div id="accordion">');
            var feature = features[0];
            fetch(getGeometricElementUrl(`${feature.get('id')}`),
              authorization
            ).then(function (response) {
              return response.json();
            }).then(function (json) {
              let geometricElement = json;
              popupContent = '<div class="card">' +
                '<div class="card-header text-center" id="heading' + feature.get('id') + '">' +
                '<h5 class="card-title">';
              var geometricElementType = allGeometricElementType.filter(geometricElementType =>
                geometricElementType.id === feature.get("geometric_element_type_id"));
              popupContent += getInternationalization(locale, geometricElementType[0].code, allCodes) +
                " " + feature.get('id') + "<br/>" + feature.get('description') +
                '</h5>' +
                '</div>' +
                '<div class="card-body">' +
                '<div class="justify-content-start">';

              let values = feature.get('value');
              let key = null;
              if (values[0] !== null) {
                values.forEach(value => {
                  key = Object.keys(value)[0];
                  popupContent += '<div class="text-left">' +
                    '<b class="card-title">' + getInternationalization(locale, key, allCodes)
                    + '</b>';
                  popupContent += '<p class="card-text">';
                  value[key].forEach((value, index, array) => {
                    if (value && value !== "") {
                      popupContent += getInternationalization(locale, value, allCodes);
                    } else {
                      popupContent += "-";
                    }
                    popupContent += array.length - 1 !== index ? ', ' : '';
                  });
                  popupContent += '</p></div>';
                });
              }

              let internalComments = feature.get('internal_comments');
              internalComments = internalComments && internalComments !== "" ? internalComments : "-";

              if (user) {
                if (user.userRoleDto.code === "ADMIN") {
                  popupContent += '<b class="card-title">' + formatMessage({ id: 'project.elements.internalComments' }) + '</b>';
                  popupContent += '<p class="card-text">' + internalComments + '</p>';
                }
              }

              popupContent += '</div>';

              if (geometricElement) {
                if (!history.location.pathname.includes(String(geometricElement.id)))
                  popupContent +=
                    '<span class="actionButton btn-link" id="showDetails' + feature.get('id') + '" data-toggle="tooltip"' +
                    'data-placement="right" title="' + formatMessage({ id: "project.common.showDetails" }) + '">' +
                    '<i class="fas fa-info-circle"></i>' +
                    '</span>';
                if (geometricElement.listGeometricElementFileDto && geometricElement.listGeometricElementFileDto.filter(
                  file => file.contentType.startsWith("image/")).length) {
                  popupContent += '<span class="actionButton btn-link" id="viewFiles' + feature.get('id') + '"data-toggle="tooltip"' +
                    'data-placement="right" title="' + formatMessage({ id: "project.common.viewFiles" }) + '">' +
                    '<i class="fas fa-images"></i>' +
                    '</span>';
                }
              }

              popupContent +=
                '<span class="actionButton btn-link" id="center' + feature.get('id') + '" data-toggle="tooltip"' +
                'data-placement="right" title=' + formatMessage({ id: "project.common.center" }) + '>' +
                '<i class="fas fa-map-marker-alt"></i>' +
                '</span>';

              if (user) {
                if ((user.id === feature.get("user_account_id") && !feature.get('is_reviewed')) || user.userRoleDto.code === "ADMIN") {
                  popupContent += '<span class="actionButton btn-link" id="modify' + feature.get('id') + '"data-toggle="tooltip"' +
                    'data-placement="right" title=' + formatMessage({ id: "project.common.modify" }) + '>' +
                    '<i class="fas fa-edit"></i>' +
                    '</span>';
                }
              }

              popupContent += '</div>' +
                '</div>';
              jQuery("#accordion").append(popupContent);
              jQuery(function () {
                jQuery('.actionButton').tooltip({ trigger: "hover" });
              });
              jQuery(function () {
                jQuery('.actionButton').tooltip().click(function () {
                  jQuery('.actionButton').tooltip("hide")
                });
              });
              jQuery("#showDetails" + feature.get('id')).bind("click", () => {
                jQuery('.actionButton').tooltip("hide");
                history.push("/geometric_element/details/" + geometricElement.id);
              });
              jQuery("#center" + feature.get('id')).bind("click", () => centerFeature(map, feature));
              jQuery("#modify" + feature.get('id')).bind("click", () => {
                findGeometricElementType(feature.get('geometric_element_type_id'))
                modifyFeature(map, source, feature)
              });
              jQuery("#viewFiles" + feature.get('id')).bind("click", () => {
                setGeometricElementFileGalleryModalShow(true);
                setGeometricElementFileGalleryFiles(geometricElement.listGeometricElementFileDto);
              });
            });
          } else {
            jQuery(content).html('<div id="accordion">');
            features.forEach(feature => {
              fetch(getGeometricElementUrl(`${feature.get('id')}`),
                authorization
              ).then(function (response) {
                return response.json();
              }).then(function (json) {
                let geometricElement = json;
                popupContent = '<div class="card">' +
                  '<div class="card-header" id="heading' + feature.get('id') + '">' +
                  '<h5 class="mb-0">' +
                  '<button class="btn btn-link collapsed" data-toggle="collapse" ' +
                  'data-target="#collapse' + feature.get('id') + '" ' +
                  'aria-expanded="false" ' +
                  'aria-controls="collapse' + feature.get('id') + '">';
                var geometricElementType = allGeometricElementType.filter(geometricElementType =>
                  geometricElementType.id === feature.get("geometric_element_type_id")
                );
                popupContent += getInternationalization(locale, geometricElementType[0].code, allCodes) +
                  " " + feature.get('id') + "<br/>" + feature.get('description') +
                  '</button>' +
                  '</h5>' +
                  '</div>' +
                  '<div id="collapse' + feature.get('id') + '" class="collapse" ' +
                  'aria-labelledby="heading' + feature.get('id') + '"' +
                  'data-parent="#accordion">' +
                  '<div class="card-body">' +
                  '<div class="justify-content-start">';

                let values = feature.get('value');
                let key = null;
                if (values[0] !== null) {
                  values.forEach(value => {
                    key = Object.keys(value)[0];
                    popupContent += '<div class="text-left">' +
                      '<b class="card-title">' + getInternationalization(locale, key, allCodes)
                      + '</b>';
                    popupContent += '<p class="card-text">';
                    value[key].forEach((value, index, array) => {
                      if (value && value !== "") {
                        popupContent += getInternationalization(locale, value, allCodes);
                      } else {
                        popupContent += "-";
                      }
                      popupContent += array.length - 1 !== index ? "," : "";
                    });
                    popupContent += '</p></div>'
                  });
                }

                let internalComments = feature.get('internal_comments');
                internalComments = internalComments && internalComments !== "" ? internalComments : "-";

                if (user) {
                  if (user.userRoleDto.code === "ADMIN") {
                    popupContent += '<b class="card-title">' + formatMessage({ id: 'project.elements.internalComments' }) + '</b>';
                    popupContent += '<p class="card-text">' + internalComments + '</p>';
                  }
                }

                popupContent += '</div>';

                if (geometricElement) {
                  if (!history.location.pathname.includes(String(geometricElement.id)))
                    popupContent +=
                      '<span class="actionButton btn-link" id="showDetails' + feature.get('id') + '" data-toggle="tooltip"' +
                      'data-placement="right" title="' + formatMessage({ id: "project.common.showDetails" }) + '">' +
                      '<i class="fas fa-info-circle"></i>' +
                      '</span>';
                  if (geometricElement.listGeometricElementFileDto && geometricElement.listGeometricElementFileDto.filter(
                    file => file.contentType.startsWith("image/")).length) {
                    popupContent += '<span class="actionButton btn-link" id="viewFiles' + feature.get('id') + '"data-toggle="tooltip"' +
                      'data-placement="right" title="' + formatMessage({ id: "project.common.viewFiles" }) + '">' +
                      '<i class="fas fa-images"></i>' +
                      '</span>';
                  }
                }

                popupContent +=
                  '<span class="actionButton btn-link" id="center' + feature.get('id') + '"data-toggle="tooltip"' +
                  'data-placement="right" title=' + formatMessage({ id: "project.common.center" }) + '>' +
                  '<i class="fas fa-map-marker-alt"></i>' +
                  '</span>';

                if (user) {
                  if (user.id === feature.get("user_account_id") || user.userRoleDto.code === "ADMIN") {
                    popupContent += '<span class="actionButton btn-link" id="modify' + feature.get('id') + '" data-toggle="tooltip"' +
                      'data-placement="right" title=' + formatMessage({ id: "project.common.modify" }) + '>' +
                      '<i class="fas fa-edit"></i>' +
                      '</span>';
                  }
                }

                popupContent += '</div></div>' +
                  '</div>';
                jQuery("#accordion").append(popupContent);
                jQuery(function () {
                  jQuery('.actionButton').tooltip({ trigger: "hover" });
                });

                jQuery(function () {
                  jQuery('.actionButton').tooltip().click(function () {
                    jQuery('.actionButton').tooltip("hide")
                  });
                });
                jQuery("#center" + feature.get('id')).bind("click", () => centerFeature(map, feature));
                jQuery("#showDetails" + feature.get('id')).bind("click", () => {
                  jQuery('.actionButton').tooltip("hide");
                  history.push("/geometric_element/details/" + geometricElement.id);
                });
                jQuery("#modify" + feature.get('id')).bind("click", () => {
                  findGeometricElementType(feature.get('geometric_element_type_id'))
                  modifyFeature(map, source, feature);
                });
                jQuery("#viewFiles" + feature.get('id')).bind("click", () => {
                  setGeometricElementFileGalleryModalShow(true);
                  setGeometricElementFileGalleryFiles(geometricElement.listGeometricElementFileDto);
                });
              });
            });
          }

          overlay.setPosition(evt.coordinate);
          map.getView().setCenter(evt.coordinate);
        }
      });
    }

    let findGeometricElementType = (id) => {
      this.props.dispatch(actions.findGeometricElementTypeById(id));
    }

    if (this.props.canQueryGeometricElement) {
      map.addControl(new PopupController({
        overlay: overlay,
        content: content,
        source: this.drawSource,
        allCodes: allCodes,
        locale: this.state.locale,
        user: this.state.user,
        disableAllInteractions: this.removeAllAddedInteractions,
        hideAllOverlays: hideAllOverlays,
        closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
        openAddGeometricElementForm: openAddGeometricElementForm,
        handleGeom: this.handleGeom,
        handleModifyFeature: this.handleModifyFeature,
        formatMessage: this.props.intl.formatMessage,
      }));
      map.on('singleclick', (event) => this.showPopup(event, this.props.intl.formatMessage, findGeometricElementType, this.props.history));
    }

    map.addControl(new AreaMeasureController({
      source: this.drawSource,
      setInteractions: this.setInteractions,
      overlay: measureTooltipOverlay,
      overlayElement: measureTooltipElement,
      disableAllInteractions: this.removeAllAddedInteractions,
      closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
      hideAllOverlays: hideAllOverlays,
      formatMessage: this.props.intl.formatMessage
    }));
    map.addControl(new LengthMeasureController({
      source: this.drawSource,
      setInteractions: this.setInteractions,
      overlay: measureTooltipOverlay,
      overlayElement: measureTooltipElement,
      disableAllInteractions: this.removeAllAddedInteractions,
      closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
      hideAllOverlays: hideAllOverlays,
      formatMessage: this.props.intl.formatMessage
    }));
    map.addControl(new ZoomToExtentController({
      source: this.drawSource,
      setInteractions: this.setInteractions,
      disableAllInteractions: this.removeAllAddedInteractions,
      closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
      hideAllOverlays: hideAllOverlays,
      formatMessage: this.props.intl.formatMessage
    }));
    map.addControl(new OverviewMap({
      layers: [new TileLayer({
        source: new OSM()
      })]
    }));
    map.addControl(new EntityPopulationCenterControl({
      source: this.drawSource,
      setInteractions: this.setInteractions,
      disableAllInteractions: this.removeAllAddedInteractions,
      closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
      hideAllOverlays: hideAllOverlays,
      formatMessage: this.props.intl.formatMessage,
      listProvinces: this.props.listProvinces,
      listCouncils: this.props.listCouncils,
      listParishes: this.props.listParishes,
      listEntityPopulations: this.props.listEntityPopulations,
      listCounties: this.props.listCounties,
      provincesBuffer: this.props.provincesBuffer,
      councilsBuffer: this.props.councilsBuffer,
      parishesBuffer: this.props.parishesBuffer,
      entityPopulationsBuffer: this.props.entityPopulationsBuffer,
      countiesBuffer: this.props.countiesBuffer
    }));
    map.addControl(new ShowLegendControl({
      hideShowLegend: this.hideShowLegend,
      formatMessage: this.props.intl.formatMessage
    }))
    if (this.props.enableGeographicalViewerScale) {
      map.addControl(new ScaleLine({
        bar: true,
        steps: 2
      }));
    }
    if (this.props.allowExportGeographicalViewerToPDF) {
      map.addControl(new ExportToPDFControl({
        formatMessage: this.props.intl.formatMessage,
        showExportPDFMenu: () => { this.setState({ generatePdfMenuModalShow: true }) }
      }));
    }

    let mousePositionControl = new MousePosition({
      coordinateFormat: function(coordinate) {
        return format(coordinate, '{x}:{y} - EPSG:25829', 1)
      },
      undefinedHTML: null,
      projection: 'EPSG:25829'
    });
    map.addControl(mousePositionControl);

    if (this.state.user && this.props.canAddGeometricElement !== false) {
      if (jQuery("#addGeometricElementRootDiv").length === 0) {
        map.addControl(new AddGeometricElementController({
          source: this.drawSource,
          setInteractions: this.setInteractions,
          allGeometricElementType: this.props.allGeometricElementType,
          handleGeom: this.handleGeom,
          openAddGeometricElementForm: openAddGeometricElementForm,
          closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
          dispatch: this.props.dispatch,
          disableAllInteractions: this.removeAllAddedInteractions,
          hideAllOverlays: hideAllOverlays,
          allCodes: this.props.allCodes,
          locale: this.state.locale,
          formatMessage: this.props.intl.formatMessage
        }));
      }
    }

    let addAuthenticationWMSFunction = function (tile, src) {
      var client = new XMLHttpRequest();
      client.open('GET', src);
      client.responseType = "arraybuffer";
      if (sessionStorage.getItem("serviceToken")) {
        client.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("serviceToken"));
      }
      client.onload = function () {
        const arrayBufferView = new Uint8Array(this.response);
        const blob = new Blob([arrayBufferView], { type: 'image/png' });
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);
        tile.getImage().src = imageUrl;
      }
      client.send();
    }

    let placeAfterOtherLayersGeometricLayerGroup = this.props.listAllGeometricLayerGroup.filter(layerGroup => layerGroup.placeBehindOtherLayers);
    placeAfterOtherLayersGeometricLayerGroup = placeAfterOtherLayersGeometricLayerGroup.sort((a, b) => {
      return b.order - a.order;
    });

    let placeInsideOtherLayersGeometricLayerGroup = this.props.listAllGeometricLayerGroup.filter(layerGroup => layerGroup.includeInsideGroupLayer);
    placeInsideOtherLayersGeometricLayerGroup = placeInsideOtherLayersGeometricLayerGroup.sort((a, b) => {
      return b.order - a.order;
    });

    let placeBeforeOtherLayersGeometricLayerGroup = this.props.listAllGeometricLayerGroup.filter(layerGroup => !layerGroup.placeBehindOtherLayers && !layerGroup.includeInsideGroupLayer);
    placeBeforeOtherLayersGeometricLayerGroup = placeBeforeOtherLayersGeometricLayerGroup.sort((a, b) => {
      return b.order - a.order;
    });

    placeAfterOtherLayersGeometricLayerGroup.forEach(geometricLayerGroup => {
      insertGeometricLayerGroup(geometricLayerGroup, this.olmap, this.otherLayersGroup,
        this.props.locale, this.props.allCodes, this.props.allowConfigureLayersOpacity);
    });

    if (this.props.listAllGeometricLayerGroup.filter(geometricLayerGroup => (geometricLayerGroup.includeInsideGroupLayer && geometricLayerGroup.listGeometricLayer.length > 0)).length > 0) {
      placeInsideOtherLayersGeometricLayerGroup.forEach(geometricLayerGroup => {
        insertGeometricLayerGroup(geometricLayerGroup, this.olmap, this.otherLayersGroup,
          this.props.locale, this.props.allCodes, this.props.allowConfigureLayersOpacity);
      });
      this.olmap.addLayer(this.otherLayersGroup);
    }

    placeBeforeOtherLayersGeometricLayerGroup.forEach(geometricLayerGroup => {
      insertGeometricLayerGroup(geometricLayerGroup, this.olmap, this.otherLayersGroup,
        this.props.locale, this.props.allCodes, this.props.allowConfigureLayersOpacity);
    });

    this.refreshLayersCollection = () => {
      layersCollection.forEach(layer => {
        let params = layer.getSource().getParams();
        params.t = new Date().getMilliseconds();
        layer.getSource().updateParams(params);
      });
      myLayersColletion.forEach(layer => {
        let params = layer.getSource().getParams();
        params.t = new Date().getMilliseconds();
        layer.getSource().updateParams(params);
      });
    }

    //One layer per geometric element type
    let layersCollection = new Collection([]);
    //One layer per geometric element type (My elements)
    let myLayersColletion = new Collection([]);
    if (this.props.canQueryGeometricElement) {
      this.props.allGeometricElementType.forEach((geometricElementType, index) => {
        let styles = [];
        let geometryType = geometricElementType.geometryType;
        if (geometryType === "ANY_GEOMETRY") {
          styles = [
            geometricElementType.pointStyleName ? geometricElementType.pointStyleName : "",
            geometricElementType.lineStyleName ? geometricElementType.lineStyleName : "",
            geometricElementType.polygonStyleName ? geometricElementType.polygonStyleName : "",
          ]
        } else {
          if (geometryType.includes("POINT")) {
            styles.push(geometricElementType.pointStyleName ? geometricElementType.pointStyleName : "")
          }
          if (geometryType.includes("LINE")) {
            styles.push(geometricElementType.lineStyleName ? geometricElementType.lineStyleName : "")
          }
          if (geometryType.includes("POLYGON")) {
            styles.push(geometricElementType.polygonStyleName ? geometricElementType.polygonStyleName : "")
          }
        }
        layersCollection.insertAt(index, new ImageLayer({
          title: getInternationalization(
            this.state.locale,
            geometricElementType.code,
            this.props.allCodes
          ),
          enableOpacitySlider: this.props.allowConfigureLayersOpacity && geometricElementType.allowChangeOpacity,
          opacity: geometricElementType.defaultOpacity ? geometricElementType.defaultOpacity : 1,
          source: new ImageWMS({
            url: `${process.env.REACT_APP_BACKEND_URL}/mapserver`,
            params: {
              'layers': [geometricElementType.code],
              'VERSION': WMS_VERSION,
              'REQUEST': 'GetMap',
              'TILED': true,
              'styles': styles
            },
            projection: MAP_CRS,
            imageLoadFunction: addAuthenticationWMSFunction,
            // Prevent CORS error when exporting to PDF
            crossOrigin: 'anonymous'
          }),
          projection: MAP_CRS,
          legendType: geometricElementType.legendType,
          legendTypeValue: geometricElementType.legendTypeValue
        }))

        myLayersColletion.insertAt(index, new ImageLayer({
          title: getInternationalization(
            this.state.locale,
            geometricElementType.code,
            this.props.allCodes
          ),
          enableOpacitySlider: this.props.allowConfigureLayersOpacity && geometricElementType.allowChangeOpacity,
          opacity: geometricElementType.defaultOpacity ? geometricElementType.defaultOpacity : 1,
          source: new ImageWMS({
            url: `${process.env.REACT_APP_BACKEND_URL}/mapserver`,
            params: {
              'layers': ["MY_" + geometricElementType.code],
              'VERSION': WMS_VERSION,
              'REQUEST': 'GetMap',
              'TILED': true,
              'styles': styles
            },
            projection: MAP_CRS,
            imageLoadFunction: addAuthenticationWMSFunction,
            // Prevent CORS error when exporting to PDF
            crossOrigin: 'anonymous'
          }),
          projection: MAP_CRS,
          legendType: geometricElementType.legendType,
          legendTypeValue: geometricElementType.legendTypeValue
        }))
      });

      this.elementsGroup.setLayers(layersCollection);

      this.olmap.addLayer(this.elementsGroup);
      if (this.state.user) {
        this.olmap.addLayer(this.myElementsGroup);
        this.myElementsGroup.setLayers(myLayersColletion);
      }
    }

    map.addLayer(drawVector);

    this.olmap.addControl(this.layerSwitcherControl);

    if (this.state.insertFromNavBar !== null && this.state.insertFromNavBar === this.props.location.state.insertFromNavBar && this.state.user) {
      jQuery("#addGeometricElementSelector").val(this.state.insertFromNavBar).change();
      if (!jQuery("#addGeometricElementDiv").hasClass("ol-control-active")) {
        jQuery("#addGeometricElementButton").click();
      }
    }

    if (this.props.mapCurrentExtent) {
      map.getView().fit(this.props.mapCurrentExtent);
    } else {
      if (this.props.initialExtendParameter) {
        map.getView().fit(this.props.initialExtendParameter);
      }
    }

    if (this.state.centerFeature) {
      centerFeature(this.olmap, this.state.centerFeature)
    }

    if (this.state.featureToModify && this.state.user) {
      modifyFeature(this.olmap, this.drawSource, this.state.featureToModify);
      centerFeature(this.olmap, this.state.featureToModify);
    }

    this.olmap.getLayerGroup().getLayers().forEach(baseLayer => {
      baseLayer.getLayersArray().forEach(layer => {
        layer.on('change:visible', () => this.forceUpdate());
      })
    })

  }

  componentDidUpdate(prevProps) {

    // Move legend
    jQuery('.legend').draggable({
      cursor: 'move'
    });


    if (this.props.location.state) {
      if (typeof prevProps.location.state === 'undefined') {
        if (this.props.location.state.insertFromNavBar) {
          this.setState({ insertFromNavBar: this.props.location.state.insertFromNavBar });
        }
        if (this.props.location.state.featureToModify) {
          this.setState({ featureToModify: this.props.location.state.featureToModify });
        }
      } else {
        if (prevProps.location.state.insertFromNavBar !== this.props.location.state.insertFromNavBar) {
          this.setState({ insertFromNavBar: this.props.location.state.insertFromNavBar });
        }
        if (prevProps.location.state.featureToModify !== this.props.location.state.featureToModify) {
          this.setState({ featureToModify: this.props.location.state.featureToModify });
        }
      }
    }

    if (this.state.user !== this.props.user) {
      this.setState({ user: this.props.user });
    }

    if (this.state.locale !== this.props.locale) {
      this.setState({ locale: this.props.locale });
    }
  }

  render() {

    return (
      <>
        {/* View photos gallery dialog */}
        <GeometricElementFileGallery
          modalShow={this.state.geometricElementFileGalleryModalShow}
          geometricElementFiles={this.state.geometricElementFileGalleryFiles}
          hideModalWindow={() => this.setState({ geometricElementFileGalleryModalShow: false })}
        />
        <div id="map-container">
          <div id="map" className="openlayers-map"
            style={{
              width: this.props.style && this.props.style.width ? this.props.style.width : "100%",
              height: this.props.style && this.props.style.height ? this.props.style.height : "82vh"
            }}
          >
          </div>
          <div id="popup" className="ol-popup">
            <a href="." className="ol-popup-closer btn-link" id="popup-closer">
              <i className="fas fa-times"></i>
            </a>
            <div id="popup-content" className="ol-popup-content"></div>
          </div>
        </div>
        {this.props.allowExportGeographicalViewerToPDF ?
          <>
            {/* Export to pdf menu */}
            <GeographicalViewerExportMenu
              modalShow={this.state.generatePdfMenuModalShow}
              closeModalWindow={() => this.setState({ generatePdfMenuModalShow: false })}
              showError={() => { this.setState({ generatePdfErrorModalShow: true }) }}
              map={this.olmap}
            />
            {/* Error modal window on generate PDF */}
            <Modal show={this.state.generatePdfErrorModalShow}
              onHide={() => this.setState({ generatePdfErrorModalShow: false })}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <FormattedMessage id="project.common.ErrorDialog.title" />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormattedMessage id="project.elements.map.exportPDFError" />
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-primary" onClick={() => this.setState({ generatePdfErrorModalShow: false })}>
                  <FormattedMessage id="project.common.close" />
                </button>
              </Modal.Footer>
            </Modal>
          </>
          :
          ""}
        <AddGeometricElement
          geom={this.state.geom}
          closeAddGeometricElementForm={this.props.closeAddGeometricElementForm}
          clearDrawSource={this.clearDrawSource}
          removeAllAddedInteractions={this.removeAllAddedInteractions}
          refreshLayersCollection={this.refreshLayersCollection}
          restartAddGeometricElementInteraction={this.state.restartAddGeometricElementInteraction}
          modifyFeature={this.state.featureToModify}
          map={this.olmap}
          resetModifyFeature={this.resetModifyFeature}
          resetInsertFromNavBar={this.resetInsertFromNavBar}
          formatMessage={this.props.intl.formatMessage}
          submitModifyFromDetailsPage={this.props.submitModifyFromDetailsPage}
          cancelModifyFromDetailsPage={this.props.cancelModifyFromDetailsPage}
        />
        <GeographicalViewerLegend
          layers={this.olmap.getLayerGroup().getLayersArray()}
          legendShow={this.state.showLegend}
          closeLegend={this.hideShowLegend}
        />
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps)(injectIntl(GeographicalViewer)));