import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import * as actions from '../../users/actions';
import * as appActions from '../../app/actions';
import * as geometricElementActions from '../../geometricElements/actions'
import jQuery from 'jquery';

const Logout = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(actions.logout());
        dispatch(geometricElementActions.mapCurrentExtent(null));
        history.push('/');
        jQuery('#languageSelector').val('gl-ES').change();
        dispatch(appActions.language('gl'));
    });

    return null;

}

export default Logout;