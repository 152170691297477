export default {
    "project.global.exceptions.NetworkError": 'No se pudo conectar con el servidor',
    'project.app.Header.riskzones': 'Zonas de Riesgo',
    'project.app.Header.viewer': 'Visor geográfico',
    'project.app.Header.geometricElements': 'Tablas de elementos',
    'project.app.Header.admin': 'Administración',
    'project.app.Header.admin.users': 'Usuarios',
    'project.app.Header.admin.elementType': 'Tipos de elementos',
    'project.app.Header.admin.attributeType': 'Tipos de atributos',
    'project.app.Header.admin.configurationParameters': 'Parámetros de configuración',
    'project.app.Header.admin.layer': 'Capas',
    'project.app.Header.admin.layerGroup': 'Grupos de capas',
    'project.app.Header.admin.attachedFile': 'Ficheros adjuntos',
    'project.app.Header.myContributions': 'Mis contribuciones',
    'project.app.Header.addGeometricElement': 'Añadir nuevo elemento',
    'project.app.Header.login': 'Iniciar sesión',
    'project.app.Header.signUp': 'Crear cuenta',
    'project.app.Header.logout': 'Cerrar sesión',
    'project.app.Header.password': 'Contraseña',
    'project.app.Body.search': 'Buscar',
    'project.app.Body.accept': 'Aceptar',
    'project.app.Body.cancel': 'Cancelar',
    'project.app.Body.back': 'Anterior',
    'project.app.Body.next': 'Siguiente',
    'project.app.Body.save': 'Guardar',
    'project.app.Body.return': '← Volver a la página principal',
    'project.app.Body.field.required': 'El campo "{field}" es obligatorio',
    'project.app.Body.field.positive': 'El campo "{field}" debe ser positivo',

    'project.common.close': 'Cerrar',
    'project.common.ErrorDialog.title': 'Error',
    'project.common.permissionDenied': 'No tiene permiso para acceder a esta página',
    'project.common.mustLogin': 'Debe iniciar sesión para poder acceder a esta página',
    'project.common.action': "Acciones",
    'project.common.modify': 'Modificar',
    'project.common.center': 'Centrar',
    'project.common.modifyOnViewer': 'Modificar en el visor geográfico',
    'project.common.centerOnViewer': 'Ver en el mapa',
    'project.common.selectOneOption': 'Selecciona una opción',
    'project.common.viewElements': 'Ver elementos',
    'project.common.attributes': 'Atributos',
    'project.common.delete': 'Eliminar',
    'project.common.deleteSomething': 'Eliminar {something}',
    'project.common.download': 'Descargar',
    'project.common.viewFiles': 'Ver fotos',
    'project.common.deleteFile': 'Eliminar fichero',
    'project.common.addFilesButton': 'Añadir ficheros',
    'project.common.addFiles': 'Añadir ficheros al elemento {id}',
    'project.common.yes': 'Sí',
    'project.common.no': 'No',
    'project.common.filters': 'Filtros',
    'project.common.filters.clear': 'Quitar todos los filtros',
    'project.common.filepond.labelIdle': 'Arrastra y suelta tus ficheros o <span class="filepond--label-action">Examina</span>',
    'project.common.filepond.labelTapCancel': 'Pulsa para cancelar',
    'project.common.filepond.labelLoading': 'Cargando',
    'project.common.filepond.labelWaitForSize': 'Esperando por el tamaño',
    'project.common.tables.totalElements': 'Mostrando del {from} al {to} de {total} ',
    'project.common.tables.filter.text.placeholder': 'Buscar {column}',
    'project.common.tables.filter.select.placeholder': 'Seleccionar {column}',
    'project.common.location': 'Ubicación',
    'project.common.location.province': 'Provincia',
    'project.common.location.municipality': 'Municipio',
    'project.common.location.parish': 'Parroquia',
    'project.common.dates': 'Fechas',
    'project.common.showDetails': 'Ver en detalle',
    'project.common.pagination.firstPageTitle': 'Primera página',
    'project.common.pagination.lastPageTitle': 'Última página',
    'project.common.pagination.nextPageTitle': 'Página siguiente',
    'project.common.pagination.prePageTitle': 'Página anterior',
    'project.common.legendType': 'Tipo de leyenda',
    'project.common.legendType.url': 'URL de la leyenda',
    'project.common.legendType.file': 'Fichero de la imagen',
    'project.common.legendType.file.error': 'Debe seleccionar un fichero',
    'project.common.legendType.file.existent': 'Imagen existente',
    'project.common.legendType.file.unexistent': 'Nueva imagen',
    'project.common.images.error': 'No fue posible cargar la imagen',

    'project.elements.data': 'Datos del elemento',
    'project.elements.new': 'Crear {element}',
    'project.elements.myElements.message': "En esta tabla se muestran los elementos públicos. Consulte los creados por usted en {link}",
    'project.elements.anonymous.message': "Para introducir un nuevo elemento debe {link}",
    'project.elements.addGeometricElement': 'Añadir nuevo elemento',
    'project.elements.modify': 'Modificar {element}',
    'project.elements.modify.error': 'No se pudo iniciar la modificación del elemento',
    'project.elements.noElements': 'No existen elementos para el tipo seleccionado',
    'project.elements.types.noElements': 'No existen tipos de elementos',
    'project.elements.attributes.noAttributes': 'Este tipo de elemento no tiene tipos de atributos asociados',
    'project.elements.map.zoomInTipLabel': 'Acercarse',
    'project.elements.map.zoomOutTipLabel': 'Alejarse',
    'project.elements.map.fitToExtentTip': 'Ver la máxima extensión',
    'project.elements.map.zoomToExtentTip': 'Zoom por encuadre',
    'project.elements.map.popupTip': 'Consultar información sobre elementos mediante clic',
    'project.elements.map.measureLengthTip': 'Medir distancias',
    'project.elements.map.measureAreaTip': 'Medir áreas',
    'project.elements.map.addElementTip': 'Añadir nuevo elemento',
    'project.elements.map.fullScreenTip': 'Pantalla completa',
    'project.elements.map.layersTip.show': 'Mostrar capas',
    'project.elements.map.layersTip.hide': 'Ocultar capas',
    'project.elements.map.legendTip': 'Leyenda',
    'project.elements.map.otherLayers': 'Otras capas',
    'project.elements.map.myElements': 'Mis contribuciones',
    'project.elements.map.elements': 'Elementos',
    'project.elements.map.provinces': 'Provincias',
    'project.elements.map.councils': 'Ayuntamientos',
    'project.elements.map.parishes': 'Parroquias',
    'project.elements.map.entityPopulations': 'Entidades de población',
    'project.elements.map.counties': 'Comarcas',
    'project.elements.map.entityPopulation': 'Entidad de población',
    'project.elements.map.selectProvince': '-- Seleccione provincia --',
    'project.elements.map.selectCouncil': '-- Seleccione municipio --',
    'project.elements.map.selectParish': '-- Seleccione parroquia --',
    'project.elements.map.selectEntityPopulation': '-- Seleccione entidad de población --',
    'project.elements.map.searchEntityPopulation': 'Buscar',
    'project.elements.map.progressiveCenter': 'Búsqueda progresiva',
    'project.elements.map.searchCenter': 'Búsqueda directa',
    'project.elements.map.searchType': 'Tipo de búsqueda',
    'project.elements.map.opacityLabelTip': 'Opacidad',
    'project.elements.map.exportPDFTip': 'Exportar a PDF',
    'project.elements.map.exportPDF': 'Exportar a PDF',
    'project.elements.map.exportPDF.size': 'Tamaño de página',
    'project.elements.map.exportPDF.size.a4': 'A4',
    'project.elements.map.exportPDF.size.a3': 'A3',
    'project.elements.map.exportPDF.orientation': 'Orientación',
    'project.elements.map.exportPDF.orientation.landscape': 'Horizontal',
    'project.elements.map.exportPDF.orientation.portrait': 'Vertical',
    'project.elements.map.exportPDF.includeLegend': 'Incluir leyenda',
    'project.elements.map.exportPDF.includeScale': 'Incluir escala',
    'project.elements.map.exportPDF.save': 'Exportar',
    'project.elements.map.exportPDFError': 'Se ha producido un error al generar el PDF',
    'project.elements.selectOneOption': 'Selecciona una opción',
    'project.elements.files': 'Ficheros asociados',
    'project.elements.files.hideShow': 'Mostrar/Ocultar ficheros asociados',
    'project.elements.files.noFiles': 'Este elemento no tiene ficheros asociados',
    'project.elements.values': 'Valores',
    'project.elements.name': 'Nombre',
    'project.elements.geometryType': 'Tipo de geometría',
    'project.elements.styles': 'Estilos',
    'project.elements.pointStyleName': 'Nombre del estilo para punto',
    'project.elements.lineStyleName': 'Nombre del estilo para línea',
    'project.elements.polygonStyleName': 'Nombre del estilo para polígono',
    'project.elements.description': 'Descripción',
    'project.elements.geom': 'Geometría',
    'project.elements.geom.LineString': 'Línea',
    'project.elements.geom.Point': 'Punto',
    'project.elements.geom.Polygon': 'Polígono',
    'project.elements.geom.LinearRing': 'Anillo lineal',
    'project.elements.geom.MultiPoint': 'Multipunto',
    'project.elements.geom.MultiLineString': 'Multilínea',
    'project.elements.geom.MultiPolygon': 'Multipolígono',
    'project.elements.geom.Circle': 'Círculo',
    'project.elements.files.filename': 'Nombre',
    'project.elements.files.format': 'Formato',
    'project.elements.files.type': 'Tipo mime',
    'project.elements.files.size': 'Tamaño',
    'project.elements.values.attribute': 'Atributo',
    'project.elements.values.value': 'Valor',
    'project.elements.values.order': 'Orden',
    'project.elements.creationDate': 'Introducción',
    'project.elements.modificationDate': 'Última modificación',
    'project.elements.lessCreationDate': 'Fecha de introducción igual o anterior a',
    'project.elements.lessModificationDate': 'Fecha de modificación igual o anterior a',
    'project.elements.moreCreationDate': 'Fecha de introducción igual o posterior a',
    'project.elements.moreModificationDate': 'Fecha de modificación igual o posterior a',
    'project.elements.isPublic': '¿Es público?',
    'project.elements.isReviewed': '¿Está revisado?',
    'project.elements.internalComments': 'Obs. internas',
    'project.elements.owner': 'Autor',
    'project.elements.type.add': 'Añadir tipo de elemento',
    'project.elements.type.add.name': 'Nombre del tipo de elemento',
    'project.elements.type.modify': 'Modificar tipo de elemento',
    'project.elements.type.modify.name': 'Nombre del tipo de elemento',
    'project.elements.type.add.attribute': 'Atributo',
    'project.elements.type.showAttributes': 'Mostrar/Ocultar atributos asociados',
    'project.elements.delete.message': `¿Va a eliminar "{element}", ¿Desea continuar?`,
    'project.elements.geometricElement.delete.message': `¿Va a eliminar "{element}", ¿Desea continuar?
    {alert}
    `,
    'project.elements.geometricElement.delete.message.alert': 'Al hacer la eliminación, se eliminaran también las relaciones que tenga este elemento con otros elementos y los ficheros asociados a este elemento',
    "project.elements.files.fileNoExistsHeader": 'El fichero no existe',
    "project.elements.files.fileNoExistsBody": 'No se encontró el fichero',
    'project.elements.legend': 'Leyenda',
    'project.elements.legend.title': 'Leyenda del tipo de elemento {element}',
    'project.elements.legend.show': 'Mostrar leyenda',
    'project.elements.opacity': 'Opacidad',
    'project.elements.opacity.defaultOpacity': 'Opacidad inicial de la capa',
    'project.elements.opacity.defaultOpacity.help': 'El valor debe estar entre 0 y 100. Si no se especifica ningún valor la capa se mostrará con una opacidad del 100%',
    'project.elements.opacity.defaultOpacity.error': 'El valor del campo "Opacidad inicial de la capa" debe estar entre 0 y 100',
    'project.elements.opacity.allowChangeOpacity.table': 'Mostrar el control en el visor',
    'project.elements.opacity.allowChangeOpacity.form': 'Mostrar el control de cambio de opacidad en el visor',

    'project.elements.relations': 'Relaciones con otros elementos',
    'project.elements.relations.noRelations': 'Este elemento no tiene relación con otros elementos',
    'project.elements.relations.geometricElementType': 'Tipo de elemento',
    'project.elements.relations.geometricElementId': 'Id del elemento',
    'project.elements.relations.geometricElementDescription': 'Descripción del elemento',
    'project.elements.relations.geometricElementAttributes': 'Atributos del elemento',
    "project.elements.relations.relate": 'Relacionar con otro elemento',
    'project.elements.relations.add': 'Relacionar elementos',
    'project.elements.relations.modify': 'Modificar relación de elementos',
    'project.elements.relations.delete.message': 'Va a eliminar la relación entre los elementos "{geometricElement1}" y "{geometricElement2}", ¿Desea continuar?',
    'project.elements.relations.author': 'Autor relación',
    'project.elements.relations.id': 'Id relación',
    'project.elements.relations.geometricElement1': 'Id primer elemento',
    'project.elements.relations.geometricElement2': 'Id segundo elemento',
    'project.elemens.relations.geometricElement.showDetails': 'Ver elemento en detalle',
    'project.elements.relations.isPublic': '¿La relación es pública?',
    'project.elements.relations.isReviewed': '¿La relación está revisada?',
    'project.elements.relations.comments': 'Observaciones de la relación',

    'project.elements.attributes.noElements': 'No existen tipos de atributos',
    'project.elements.attributes.dataType': 'Tipo de dato',
    'project.elements.attributes.dataType.pick': 'Selecciona el tipo de dato de la lista',
    'project.elements.attributes.attributeOrder': 'Orden',
    'project.elements.attributes.code': 'Código',
    'project.elements.attributes.values': 'Posibles valores',
    'project.elements.attributes.showHideValues': 'Mostrar/Ocultar posibles valores',
    'project.elements.attributes.add': 'Añadir tipo de atributo',
    'project.elements.attributes.modify': 'Modificar tipo de atributo',
    'project.elements.attributes.attributeName': 'Nombre del atributo',
    'project.elements.attributes.help': 'Ayuda',
    'project.elements.attributes.translations': 'Internacionalización',
    'project.elements.attributes.maxSize': 'Tamaño máximo',
    'project.elements.attributes.mandatory': 'Obligatorio',
    'project.elements.attributes.type': 'Tipo de atributo',
    'project.elements.attributes.description': 'Descripción',
    'project.elements.attributes.name': 'Nombre',

    'project.elements.geometricLayer.layerGroup.name': 'Nombre público',
    'project.elements.geometricLayer.layerGroup.includesInsideLayerGroup': '¿Subgrupo de "Otras capas"?',
    'project.elements.geometricLayer.layerGroup.order': 'Orden',
    'project.elements.geometricLayer.layerGroup.initiallyOpen': '¿Inicialmente abierto?',
    'project.elements.geometricLayer.layerGroup.showLayers': 'Mostrar/Ocultar capas asociadas',
    'project.elements.geometricLayer.layerGroup.placeBehindOtherLayers': 'Colocar después de "Otras capas"',
    'project.elements.geometricLayer.layerGroup.add': 'Añadir grupo de capas',
    'project.elements.geometricLayer.layerGroup.deleteMessage': 'Va a eliminar el grupo de capas "{element}", ¿Desea continuar?',
    'project.elements.geometricLayer.layerGroup.noElements': 'No existe ningún grupo de capas',
    'project.elements.geometricLayer.layer.internalName': 'Nombre interno',
    'project.elements.geometricLayer.layer.name': 'Nombre público',
    'project.elements.geometricLayer.layer.order': 'Orden',
    'project.elements.geometricLayer.layer.initiallyVisible': '¿Inicialmente visible?',
    'project.elements.geometricLayer.layer.isInternal': '¿Es una capa interna?',
    'project.elements.geometricLayer.layer.serverUrl': 'URL del servidor',
    'project.elements.geometricLayer.layer.serverUrl.internalLayer': 'Capa interna',
    'project.elements.geometricLayer.layer.type': 'Tipo',
    'project.elements.geometricLayer.layer.type.help': `<ul>
    <li><b>WMS:</b> se creará una capa de tipo WMS. Se deben cubrir los campos <i>URL del servidor</i> y <i>Nombre interno</i>, pudiendo poner varios nombres de capas separados por comas.</li>
    <li><b>OpenStreetMap:</b> se creará la capa <i>estándar</i> de OpenStreetMap.</li>
    <li><b>XYZ:</b> se creará una capa de tipo XYZ. Se debe cubrir el campo <i>URL del servidor</i> con una URL del tipo <i>http://xxxxx?x={x}&y={y}&z={z}</i>. Por ejemplo: 
    <ul>
    <li>Capa <i>estándar</i> de Google <i>http://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}</i></li>
    <li>Capa de ortofoto de Google <i>http://mt0.google.com/vt/lyrs=m&hl=gl&x={x}&y={y}&z={z}</i></li>
    </ul>
    </li>
    </ul>`,
    'project.elements.geometricLayer.layer.showAllLayersOnLegend': 'Mostrar todas las capas en la leyenda',
    'project.elements.geometricLayer.layer.showAllLayersOnLegend.help': 'Si se marca esta opción, en la leyenda que se muestra en el visor geográfico para esta capa, sólo se mostrará la leyenda de la primera de las capas configuradas en "Nombre interno"',
    'project.elements.geometricLayer.layer.internalComments': 'Observaciones internas',
    'project.elements.geometricLayer.layer.legend': 'Leyenda',
    'project.elements.geometricLayer.layer.legend.title': 'Leyenda de la capa {layer}',
    'project.elements.geometricLayer.layer.legend.show': 'Mostrar leyenda',
    'project.elements.geometricLayer.layer.geometricLayerGroup': 'Grupo de capas',
    'project.elements.geometricLayer.layer.version': 'Versión',
    'project.elements.geometricLayer.layer.version.help': 'Si no se selecciona ninguna versión, se utilizará por defecto la versión 1.3.0',
    'project.elements.geometricLayer.layer.add': 'Añadir capa',
    'project.elements.geometricLayer.layer.modify': 'Modificar capa',
    'project.elements.geometricLayer.layer.noElements': 'No existe ninguna capa',
    'project.elements.geometricLayer.layer.deleteMessage': 'Va a eliminar la capa "{element}", ¿Desea continuar?',
    'project.elements.geometricLayer.layer.opacity': 'Opacidad',
    'project.elements.geometricLayer.layer.opacity.defaultOpacity': 'Opacidad inicial de la capa',
    'project.elements.geometricLayer.layer.opacity.defaultOpacity.error': 'El valor del campo "Opacidad inicial de la capa" debe estar entre 0 y 100',
    'project.elements.geometricLayer.layer.opacity.defaultOpacity.help': 'El valor debe estar entre 0 y 100. Si no se especifica ningún valor la capa se mostrará con una opacidad del 100%',
    'project.elements.geometricLayer.layer.opacity.allowChangeOpacity.table': 'Mostrar el control en el visor',
    'project.elements.geometricLayer.layer.opacity.allowChangeOpacity.form': 'Mostrar el control de cambio de opacidad en el visor',
    'project.elements.geometricLayer.layerGroup.order.help': 'El orden debe ser único dentro de cada uno de  tres los bloques de grupos capas: antes del grupo "Otras capas", dentro del grupo "Otras capas" y después del grupo "Otras capas"',

    'project.configurationParameter.name': 'Nombre',
    'project.configurationParameter.code': 'Código',
    'project.configurationParameter.value': 'Valor',
    'project.configurationParameter.userInterface': '¿Interfaz de usuario?',
    'project.configurationParameter.noDataIndication': 'No existen parámetros de configuración',
    'project.configurationParameter.modify': 'Modificar parámetro "{parameter}"',
    'project.configurationParameter.required': 'El valor no puede ser nulo',
    'project.configurationParameter.delete': 'Eliminar valor',
    'project.configurationParameter.delete.message': '¿Desea eliminar el valor del parámetro de configuración {configurationParameter}?',

    'project.users.password': 'Contraseña',
    'project.users.confirmPassword': 'Confirmar contraseña',
    'project.users.confirmPassword.error': 'Las contraseñas no coinciden',
    'project.users.locale': 'Idioma',
    'project.users.locale.pick': 'Selecciona un idioma de la lista',
    'project.users.locale.galician': 'Gallego',
    'project.users.locale.spanish': 'Español',
    'project.users.locale.english': 'Inglés',
    'project.users.currentPassword': 'Contraseña actual',
    'project.users.newPassword': 'Nueva contraseña',
    'project.users.confirmNewPassword': 'Confirme la nueva contraseña',
    'project.users.modal.modifyUser': '{user} {login}',
    'project.users.login': 'Nombre de usuario',
    'project.riskzone.back': 'Atrás',
    'project.users.lock': 'Bloquear cuenta',
    'project.users.user': 'Usuario',
    'project.users.username': "Nombre de usuario",
    'project.users.locked': "¿Está bloqueado?",
    'project.users.role': "Rol",
    'project.users.changePassword': 'Cambiar contraseña',
    'project.users.createUser': 'Crear usuario',
    'project.users.noDataIndication': 'No existen cuentas de usuario',

    'project.attachedFiles.add': 'Añadir fichero adjunto',
    'project.attachedFiles.add.file': 'Fichero',
    'project.attachedFiles.modify': 'Modificar fichero adjunto',
    'project.attachedFiles.description': 'Descripción',
    'project.attachedFiles.internalFilename': 'Nombre interno',
    'project.attachedFiles.originalFilename': 'Nombre original',
    'project.attachedFiles.size': 'Tamaño',
    'project.attachedFiles.fileFormat': 'Formato de fichero',
    'project.attachedFiles.noElements': 'No existen ficheros adjuntos',
    'project.attachedFiles.errors.maxSize': 'El archivo {filename} que intenta añadir supera el tamaño máximo permitido ({maxSize})',
    'project.attachedFiles.errors.formatNotPermitted': 'El archivo {filename} que intenta añadir tiene un formato no admitido',
    'project.attachedFiles.errors.mustBeImage': 'El fichero debe ser una imagen',
    'project.attachedFiles.error.cannotDownloadFileHeader': 'Error',
    'project.attachedFiles.error.cannotDownloadFileBody': 'El fichero no se pudo descargar'
}