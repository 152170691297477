import { combineReducers } from 'redux';

import * as actionTypes from './actionTypes';

const initialState = {
    totalConfigurationParameter: null
};

const ENABLE_GEOMETRIC_ELEMENT_QUERY = "ENABLE_GEOMETRIC_ELEMENT_QUERY";
const ENABLE_GEOMETRIC_ELEMENT_INSERTION = "ENABLE_GEOMETRIC_ELEMENT_INSERTION";

const totalConfigurationParameter = (state = initialState.totalConfigurationParameter, action) => {
    switch (action.type) {
        case actionTypes.FIND_TOTAL_CONFIGURATION_PARAMETER_COMPLETED:
            return action.totalConfigurationParameter ? action.totalConfigurationParameter.items : null;
        case actionTypes.UPDATE_CONFIGURATION_PARAMETER_COMPLETED:
            let updatedConfigurationParameter = action.updatedConfigurationParameter;
            if (updatedConfigurationParameter && updatedConfigurationParameter.attributeType.dataType === "IMAGE") {
                if (updatedConfigurationParameter.value)
                    updatedConfigurationParameter.value = updatedConfigurationParameter.value + `?${new Date().getTime()}`
            }
            if (updatedConfigurationParameter.code === ENABLE_GEOMETRIC_ELEMENT_QUERY) {
                if (updatedConfigurationParameter.value === "false") {
                    let enableInsertionParameterIndex = state.findIndex(configurationParameter => configurationParameter.code === ENABLE_GEOMETRIC_ELEMENT_INSERTION);
                    state[enableInsertionParameterIndex].value = updatedConfigurationParameter.value;
                }
            }
            if (updatedConfigurationParameter.code === ENABLE_GEOMETRIC_ELEMENT_INSERTION) {
                if (updatedConfigurationParameter.value === "true") {
                    let enableQueryParameterIndex = state.findIndex(configurationParameter => configurationParameter.code === ENABLE_GEOMETRIC_ELEMENT_QUERY);
                    state[enableQueryParameterIndex].value = updatedConfigurationParameter.value;
                }
            }
            let index = state.map(function (configurationParameter) { return configurationParameter.id })
                .indexOf(updatedConfigurationParameter.id);
            return state.map((currentConfigurationParameter, currentIndex) =>
                index === currentIndex ? updatedConfigurationParameter : currentConfigurationParameter);
        default:
            return state;
    }
}

const reducer = combineReducers({
    totalConfigurationParameter
});

export default reducer;