import * as actionTypes from './actionTypes';
import * as appSelectors from '../app/selectors';
import * as appActions from '../app/actions';
import backend from '../../backend';

const findAllFileFormatCompleted = allFileFormat => ({
    type: actionTypes.FIND_ALL_FILE_FORMAT,
    allFileFormat
})

export const findAllFileFormat = (onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.attachedFileService.findAllFileFormat(appSelectors.getLanguage(getState()),
        result => {
            dispatch(findAllFileFormatCompleted(result));
            if (typeof onSuccess === "function") {
                onSuccess();
            }
            dispatch(appActions.loaded());
        },
        error => {
            if (typeof onErrors === "function") {
                onSuccess(error);
            }
            dispatch(appActions.loaded());
        });
}

const findTotalGeneralAttachedFilesCompleted = totalGeneralAttachedFiles => ({
    type: actionTypes.FIND_TOTAL_GENERAL_ATTACHED_FILES_COMPLETED,
    totalGeneralAttachedFiles
})

export const findTotalGeneralAttachedFiles = (page, size, sortField, sortOrder, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.attachedFileService.findTotalGeneralAttachedFiles(page, size, sortField, sortOrder, appSelectors.getLanguage(getState()),
        result => {
            dispatch(findTotalGeneralAttachedFilesCompleted(result));
            if (typeof onSuccess === "function") {
                onSuccess();
            }
            dispatch(appActions.loaded());
        },
        error => {
            if (typeof onErrors === "function") {
                onSuccess(error);
            }
            dispatch(appActions.loaded());
        });
}

const addGeneralAttachedFileCompleted = (addedGeneralAttachedFile) => ({
    type: actionTypes.ADD_GENERAL_ATTACHED_FILE_COMPLETED,
    addedGeneralAttachedFile
});

export const addGeneralAttachedFile = (json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.attachedFileService.addGeneralAttachedFile(json, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(addGeneralAttachedFileCompleted(result))
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

const modifyGeneralAttachedFileCompleted = (modifiedGeneralAttachedFile) => ({
    type: actionTypes.MODIFY_GENERAL_ATTACHED_FILE_COMPLETED,
    modifiedGeneralAttachedFile
});

export const modifyGeneralAttachedFile = (id, json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.attachedFileService.modifyGeneralAttachedFile(id, json, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(modifyGeneralAttachedFileCompleted(result));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

const deleteGeneralAttachedFileCompleted = (generalAttachedFileId) => ({
    type: actionTypes.DELETE_GENERAL_ATTACHED_FILE_COMPLETED,
    generalAttachedFileId
});

export const deleteGeneralAttachedFile = (id, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.attachedFileService.deleteGeneralAttachedFile(id, appSelectors.getLanguage(getState()),
        () => {
            dispatch(deleteGeneralAttachedFileCompleted(id));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}