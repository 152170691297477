import React from "react";
import * as appSelectors from '../../app/selectors'
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as attachedFileSelectors from '../../attachedFile/selectors';
import * as configurationParameterSelectors from '../../configurationParameter/selectors';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Errors } from '../../common';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import jQuery from 'jquery';
import { getInternationalization } from '../../app/components/InternationalizationRender';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import autocomplete from 'autocompleter';
import { AddModifyGeneralAttachedFileFormFieds } from "../../attachedFile";
import { validateGeneralAttachedFileField } from "../../attachedFile/components/AddModifyGeneralAttachedFileFormFieds";

const mapStateToProps = function (state) {
    return ({
        locale: appSelectors.getLanguage(state),
        allCodes: selectors.getAllCodes(state),
        listAllGeometricLayerGroup: selectors.getTotalGeometricLayerGroup(state),
        allGeneralAttachedFiles: attachedFileSelectors.getTotalGeneralAttachedFiles(state),
        allFileFormat: attachedFileSelectors.getAllFileFormat(state),
        allConfigurationParameters: configurationParameterSelectors.getTotalConfigurationParameters(state)
    });
}

const WMS_CODE = "WMS";
const OSM_CODE = "OSM";

const Schema = Yup.object().shape({
    internalName: Yup.string().when('type', {
        is: WMS_CODE,
        then: Yup.string().required(<FormattedMessage
            id="project.app.Body.field.required"
            values={{
                field: <FormattedMessage id="project.elements.geometricLayer.layer.internalName" />,
            }} />),
        otherwise: Yup.string().notRequired().nullable()
    }),
    geometricLayerGroup: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.elements.geometricLayer.layer.geometricLayerGroup" />,
        }} />),
    serverUrl: Yup.string().when(['isInternal', "type"], {
        is: (isInternal, type) => !isInternal && type !== OSM_CODE,
        then: Yup.string().required(<FormattedMessage
            id="project.app.Body.field.required"
            values={{
                field: <FormattedMessage id="project.elements.geometricLayer.layer.serverUrl" />,
            }} />),
        otherwise: Yup.string().notRequired().nullable()
    }),
    type: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.elements.geometricLayer.layer.type" />,
        }} />),
    geometricLayerGl: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.users.locale.galician" />,
        }} />),
    geometricLayerEs: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.users.locale.spanish" />,
        }} />),
    geometricLayerEn: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.users.locale.english" />,
        }} />),
    order: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.elements.geometricLayer.layer.order" />,
        }} />),
    defaultOpacity: Yup.number().default(null)
        .test("defaultOpacity", <FormattedMessage id="project.elements.geometricLayer.layer.opacity.defaultOpacity.error" />, function (value) {
            return value >= 0 && value <= 100;
        }).nullable(true),
    legendType: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.common.legendType" />
        }} />),
    legendTypeValue: Yup.string().when("legendType", {
        is: (val) => val === "LEGEND_TYPE_URL",
        then: Yup.string().required(<FormattedMessage
            id="project.app.Body.field.required"
            values={{
                field: <FormattedMessage id="project.common.legendType.url" />
            }} />),
        otherwise: Yup.string().notRequired()
    }),
    fileId: Yup.string().when(["legendType", "existentFile"], {
        is: (legendType, existentFile) => legendType === "LEGEND_TYPE_IMAGE" && existentFile,
        then: Yup.string().required(<FormattedMessage
            id="project.common.legendType.file.error" />),
        otherwise: Yup.string().notRequired()
    }).nullable(),
    description: Yup.string().when(["legendType", "existentFile"], {
        is: (legendType, existentFile) => legendType === "LEGEND_TYPE_IMAGE" && !existentFile,
        then: Yup.string().required(<FormattedMessage
            id="project.app.Body.field.required"
            values={{
                field: <FormattedMessage id="project.attachedFiles.description" />,
            }} />),
        otherwise: Yup.string().notRequired()
    }).nullable()
});

class AddModifyGeometricLayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formikErrors: null,
            formikValueType: "",
            formikShowAllLayersOnLegend: ""
        };
    }

    geometricLayerTypeSelectorRender = () => {
        if (this.props.allCodes) {
            let geometricLayerTypeList = this.props.allCodes.filter(code => code.codeGroup.groupCode
                .indexOf('GEOMETRIC_LAYER_TYPE_GROUP_CODE') !== -1);
            return (
                geometricLayerTypeList.map(geometricLayerType =>
                    <option value={geometricLayerType.code} key={geometricLayerType.code}>
                        {getInternationalization(this.props.locale, geometricLayerType.code, geometricLayerTypeList)}
                    </option>
                )
            );
        } else {
            return (null)
        }
    }

    geometricLayerGroupSelectorRender = () => {
        if (this.props.listAllGeometricLayerGroup && this.props.allCodes) {
            return (
                this.props.listAllGeometricLayerGroup.map(geometricLayerGroup =>
                    <option value={geometricLayerGroup.code.code} key={geometricLayerGroup.code.code}>
                        {getInternationalization(this.props.locale, geometricLayerGroup.code.code, this.props.allCodes)}
                    </option>
                )
            )
        } else {
            return (null)
        }
    }

    legendTypeRender = () => {
        if (this.props.allCodes) {
            let allLegendType = this.props.allCodes.filter(code => code.codeGroup.groupCode.indexOf('LEGEND_TYPE_GROUP_CODE') !== -1);
            return (
                allLegendType.length > 0 ?
                    <div className="form-group">
                        <label htmlFor="legendType" className="font-weight-bold required">
                            <FormattedMessage id="project.common.legendType" />
                        </label>
                        <Field as="select" id="legendType" name="legendType" className="form-control">
                            {allLegendType.map(legendType =>
                                <option value={legendType.code} key={legendType.code}>
                                    {getInternationalization(this.props.locale, legendType.code, allLegendType)}
                                </option>)}
                        </Field>
                    </div>
                    :
                    null
            )
        }

        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.modalShow !== this.props.modalShow) {
            return true;
        }

        if (nextProps.geometricLayerToModify !== this.props.geometricLayerToModify) {
            return true;
        }

        if (nextProps.backendErrors !== this.props.backendErrors) {
            return true;
        }

        if (nextProps.setBackendErrors !== this.props.setBackendErrors) {
            return true;
        }

        if (nextProps.handleSubmit !== this.props.handleSubmit) {
            return true;
        }

        if (nextProps.hideModalWindow !== this.props.hideModalWindow) {
            return true;
        }

        if (nextState.formikValueType !== this.state.formikValueType) {
            return true;
        }

        if (nextState.formikShowAllLayersOnLegend !== this.state.formikShowAllLayersOnLegend) {
            return true;
        }

        if (
            (nextState.formikErrors !== this.state.formikErrors) ||
            (nextProps.backendErrors !== this.props.backendErrors)
        ) {
            return true;
        }
        return false;
    }

    componentDidUpdate() {
        if (this.state.formikErrors || this.props.backendErrors) {
            let container = jQuery('.modal-body');
            if (this.props.backendErrors) {
                container.scrollTop(0);
            } else {
                if (this.state.formikErrors) {
                    let field = jQuery('.alert:visible:first').prev();
                    if (field.length)
                        jQuery(container).animate({
                            scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                        });
                }
            }
        }
        jQuery(function () {
            //show tooltip
            jQuery('.helpTooltip').tooltip({
                html: true,
                placement: 'right',
                trigger: 'click',
                // Prevent placement flip
                fallbackPlacement: ['right'],
                boundary: 'window',
                // Show tables and custom styles inside tooltip
                sanitize: false,
                template: '<div class="help-tooltip tooltip" role="tooltip"><div class="help-tooltip arrow">' +
                    '</div><div class="help-tooltip tooltip-inner"></div></div>'
            });
            //hide it when clicking anywhere else
            jQuery('body').on('click', function (e) {
                jQuery('.helpTooltip').each(function () {
                    //the 'is' for buttons that trigger popups
                    //the 'has' for icons within a button that triggers a popup
                    if (!jQuery(this).is(e.target) && jQuery(this).has(e.target).length === 0 && jQuery('.tooltip').has(e.target).length === 0) {
                        jQuery(this).tooltip('hide');
                    }
                });
            });
        });

        //Prevent clicking on help button activates input associated with label
        jQuery(document).on('tap click', 'label sup', function (event, data) {
            event.stopPropagation();
            event.preventDefault();
            return false;
        });
    }

    render() {

        let allowChangeOpacityConfigurationParameter = false;
        if (this.props.allConfigurationParameters) {
            allowChangeOpacityConfigurationParameter = Object.values(this.props.allConfigurationParameters).filter(parameter =>
                parameter.code.indexOf("ALLOW_CONFIGURE_LAYERS_OPACITY") !== -1);
            if (allowChangeOpacityConfigurationParameter.length > 0) {
                allowChangeOpacityConfigurationParameter = allowChangeOpacityConfigurationParameter[0].value;
                allowChangeOpacityConfigurationParameter = (allowChangeOpacityConfigurationParameter === 'true');
            } else {
                allowChangeOpacityConfigurationParameter = true;
            }
        }

        let initialValues = {
            internalName: "",
            order: "",
            geometricLayerGroup: "",
            type: "",
            internalComments: "",
            serverUrl: "",
            geometricLayerEn: "",
            geometricLayerEs: "",
            geometricLayerGl: "",
            initiallyVisible: false,
            isInternal: false,
            version: "",
            legendType: "LEGEND_TYPE_NONE",
            legendTypeValue: "",
            fileId: null,
            existentFile: false,
            file: {
                filename: "",
                size: "",
                type: "",
                base64: ""
            },
            showAllLayersOnLegend: false,
            defaultOpacity: null,
            allowChangeOpacity: false
        };

        if (this.props.geometricLayerToModify) {
            initialValues = {
                internalName: this.props.geometricLayerToModify.internalName,
                order: this.props.geometricLayerToModify.order,
                geometricLayerGroup: this.props.geometricLayerToModify.geometricLayerGroup.code.code,
                type: this.props.geometricLayerToModify.type.code,
                internalComments: this.props.geometricLayerToModify.internalComments,
                serverUrl: this.props.geometricLayerToModify.serverUrl,
                geometricLayerCode: this.props.geometricLayerToModify.code.code,
                geometricLayerGl: getInternationalization("gl", this.props.geometricLayerToModify.code.code, this.props.allCodes),
                geometricLayerEs: getInternationalization("es", this.props.geometricLayerToModify.code.code, this.props.allCodes),
                geometricLayerEn: getInternationalization("en", this.props.geometricLayerToModify.code.code, this.props.allCodes),
                initiallyVisible: this.props.geometricLayerToModify.initiallyVisible,
                isInternal: this.props.geometricLayerToModify.isInternal ? this.props.geometricLayerToModify.isInternal : false,
                version: this.props.geometricLayerToModify.version ? this.props.geometricLayerToModify.version : "",
                legendType: this.props.geometricLayerToModify.legendType,
                legendTypeValue: this.props.geometricLayerToModify.legendTypeValue ? this.props.geometricLayerToModify.legendTypeValue : "",
                fileId: this.props.geometricLayerToModify.legendTypeValue,
                showAllLayersOnLegend: this.props.geometricLayerToModify.showAllLayersOnLegend,
                defaultOpacity: this.props.geometricLayerToModify.defaultOpacity ? this.props.geometricLayerToModify.defaultOpacity * 100 : null,
                allowChangeOpacity: this.props.geometricLayerToModify.allowChangeOpacity
            }

            if (initialValues.legendType === "LEGEND_TYPE_IMAGE") {
                initialValues.existentFile = true;
                let attachedFile = this.props.allGeneralAttachedFiles.filter(
                    // eslint-disable-next-line
                    generalAttachedFile => generalAttachedFile.id == this.props.geometricLayerToModify.legendTypeValue
                );
                if (attachedFile.length) {
                    attachedFile = attachedFile[0];
                    initialValues.legendTypeValue = `${attachedFile.description} - ${attachedFile.originalFilename}`;

                }
            }
        }

        let initAutocomplete = (setFieldValue) => {
            let allGeneralAttachedFiles = this.props.allGeneralAttachedFiles;
            autocomplete({
                minLength: 3,
                input: document.getElementById('legendTypeValue'),
                fetch: function (text, update) {
                    setFieldValue("fileId", null)
                    text = text.toLowerCase();
                    var suggestions = allGeneralAttachedFiles.filter(val => {
                        return val.fileFormat.image && (val.description.toLowerCase().includes(text) || val.originalFilename.toLowerCase().includes(text))
                    });
                    update(suggestions)
                },
                render: function (item, currentValue) {
                    var div = document.createElement("div");
                    div.textContent = `${item.description} - ${item.originalFilename}`;
                    div.style.zIndex = 999999999999
                    var modalDiv = document.getElementById('modal');
                    item.label = div.textContent;
                    modalDiv.appendChild(div);

                    return div;
                },
                onSelect: function (item, input) {
                    jQuery('div.autocomplete').hide();
                    setFieldValue("legendTypeValue", item.label);
                    setFieldValue("fileId", item.id);
                },
                customize: function (input, inputRect, container, maxHeight) {
                    container.style.zIndex = 999999
                }
            });
        }

        return (
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={Schema}
                validate={(values) => validateGeneralAttachedFileField(values.legendType === "LEGEND_TYPE_IMAGE" && !values.existentFile, true, this.props.allFileFormat, values)}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    this.props.setBackendErrors(null);
                    let geometricLayerType = this.props.allCodes.filter(code => code.code
                        .indexOf(values.type) !== -1)[0];
                    let geometricLayerGroup = this.props.listAllGeometricLayerGroup.filter(geometricLayerGroup =>
                        geometricLayerGroup.code.code.indexOf(values.geometricLayerGroup) !== -1)[0];
                    let geometricLayerForInsert = {
                        "code": {
                            "id": -1,
                            "code": this.props.geometricLayerToModify ?
                                values.geometricLayerCode : Math.random().toString(36).substring(2).toUpperCase() + '_LAYER',
                            "codeGroup": {
                                "id": -1,
                                "codeGroup": "GEOMETRIC_LAYER_GROUP_CODE"
                            },
                            "listInternationalizationDto": [{
                                "codeDto": {
                                    "id": -1,
                                    "code": this.props.geometricLayerToModify ?
                                        values.geometricLayerCode : Math.random().toString(36).substring(2).toUpperCase() + '_LAYER',
                                    "codeGroup": {
                                        "id": -1,
                                        "groupCode": this.props.geometricLayerToModify ?
                                            values.geometricLayerCode : Math.random().toString(36).substring(2).toUpperCase() + '_LAYER',
                                    }
                                },
                                "language": "gl",
                                "description": values.geometricLayerGl
                            }, {
                                "codeDto": {
                                    "id": -1,
                                    "code": this.props.geometricLayerToModify ?
                                        values.geometricLayerCode : Math.random().toString(36).substring(2).toUpperCase() + '_LAYER',
                                    "codeGroup": {
                                        "id": -1,
                                        "groupCode": this.props.geometricLayerToModify ?
                                            values.geometricLayerCode : Math.random().toString(36).substring(2).toUpperCase() + '_LAYER',
                                    }
                                },
                                "language": "es",
                                "description": values.geometricLayerEs
                            }, {
                                "codeDto": {
                                    "id": -1,
                                    "code": this.props.geometricLayerToModify ?
                                        values.geometricLayerCode : Math.random().toString(36).substring(2).toUpperCase() + '_LAYER',
                                    "codeGroup": {
                                        "id": -1,
                                        "groupCode": this.props.geometricLayerToModify ?
                                            values.geometricLayerCode : Math.random().toString(36).substring(2).toUpperCase() + '_LAYER',
                                    }
                                },
                                "language": "en",
                                "description": values.geometricLayerEn
                            }]
                        },
                        "internalName": values.internalName,
                        "type": geometricLayerType,
                        "geometricLayerGroup": geometricLayerGroup,
                        "order": values.order,
                        "serverUrl": values.isInternal ? null : values.serverUrl,
                        "internalComments": values.internalComments,
                        "initiallyVisible": values.initiallyVisible,
                        "isInternal": values.isInternal,
                        "legendType": values.legendType,
                        "legendTypeValue": !["LEGEND_TYPE_NONE", "LEGEND_TYPE_GET_LEGEND_GRAPHIC"].includes(values.legendType) ?
                            values.legendType === "LEGEND_TYPE_IMAGE" ? values.fileId : values.legendTypeValue
                            : null,
                        "showAllLayersOnLegend": values.showAllLayersOnLegend,
                        "defaultOpacity": values.defaultOpacity != null && values.defaultOpacity !== "" ? values.defaultOpacity / 100 : null,
                        "allowChangeOpacity": values.allowChangeOpacity
                    }
                    if (values.isInternal) {
                        geometricLayerForInsert.version = null;
                    } else {
                        if (values.version) {
                            geometricLayerForInsert.version = values.version;
                        } else {
                            geometricLayerForInsert.version = "1.3.0";
                        }
                    }
                    if (values.legendType === "LEGEND_TYPE_IMAGE" && !values.existentFile) {
                        geometricLayerForInsert.legendFile = {
                            description: values.description,
                            file: values.file
                        };
                    }

                    if (this.props.geometricLayerToModify) {
                        this.props.dispatch(actions.modifyGeometricLayer(this.props.geometricLayerToModify.id, geometricLayerForInsert, () => {
                            this.props.dispatch(actions.findAllCodes(
                                () => {
                                    this.props.handleSubmit()
                                    resetForm();
                                }
                            ));
                        },
                            errors => {
                                this.props.setBackendErrors(errors);
                            }));
                    } else {
                        this.props.dispatch(actions.addGeometricLayer(geometricLayerForInsert, () => {
                            this.props.dispatch(actions.findAllCodes(
                                () => {
                                    this.props.handleSubmit();
                                    resetForm();
                                }
                            ));
                        },
                            errors => {
                                this.props.setBackendErrors(errors);
                            }));
                    }
                    setSubmitting(false);
                }}
            >
                {({ values, errors, setFieldError, setFieldValue, resetForm }) =>
                    <Modal id="modal" show={this.props.modalShow}
                        onHide={() => {
                            this.props.hideModalWindow();
                            resetForm();
                        }}
                        scrollable={true} size="lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.props.geometricLayerToModify ?
                                    <FormattedMessage id="project.elements.geometricLayer.layer.modify" />
                                    :
                                    <FormattedMessage id="project.elements.geometricLayer.layer.add" />
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="needs-validation novalidate" id="addGeometricLayerForm">
                                {errors ? this.setState({ formikErrors: errors }) : ""}
                                {document.getElementById('legendTypeValue') ? initAutocomplete(setFieldValue) : ""}
                                <Errors errors={this.props.backendErrors} onClose={() => this.props.setBackendErrors(null)} />

                                <div className="form-group">
                                    <label htmlFor="type" className="font-weight-bold required">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.type" />
                                        &nbsp;
                                        <sup id="typeHelp"
                                            className="helpTooltip btn-link"
                                            style={{ cursor: "pointer" }}
                                            aria-hidden={true}
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            data-original-title={
                                                this.props.intl.formatMessage({
                                                    id: "project.elements.geometricLayer.layer.type.help"
                                                })
                                            }
                                        >
                                            <FontAwesomeIcon icon={faQuestionCircle} />
                                        </sup>
                                    </label>
                                    <Field as="select" id="type" name="type" className="form-control">
                                        <FormattedMessage id="project.common.selectOneOption">
                                            {(msg) => <option value="">{msg}</option>}
                                        </FormattedMessage>
                                        {this.geometricLayerTypeSelectorRender()}
                                    </Field>
                                </div>
                                <ErrorMessage name="type" render={(msg) =>
                                    <div id="typeError" className="alert alert-danger alert-dismissible fade show"
                                        role="alert" hidden={typeof errors.type === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("type", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />
                                {this.setState({ formikValueType: values.type })}

                                {values.type === WMS_CODE && !values.isInternal ?
                                    <div className="form-group">
                                        <label htmlFor="version" className="font-weight-bold">
                                            <FormattedMessage id="project.elements.geometricLayer.layer.version" />
                                            &nbsp;
                                            <sup id="versionHelp"
                                                className="helpTooltip btn-link"
                                                style={{ cursor: "pointer" }}
                                                aria-hidden={true}
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                data-original-title={
                                                    this.props.intl.formatMessage({
                                                        id: "project.elements.geometricLayer.layer.version.help"
                                                    })
                                                }
                                            >
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </sup>
                                        </label>
                                        <Field as="select" id="version" name="version" className="form-control">
                                            <FormattedMessage id="project.common.selectOneOption">
                                                {(msg) => <option value="">{msg}</option>}
                                            </FormattedMessage>
                                            <option value="1.1.1">1.1.1</option>
                                            <option value="1.3.0">1.3.0</option>
                                        </Field>
                                    </div>
                                    :
                                    ""
                                }

                                {values.type === WMS_CODE &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="internalName" className="font-weight-bold required">
                                                <FormattedMessage id="project.elements.geometricLayer.layer.internalName" />
                                            </label>
                                            <Field id="internalName" name="internalName" className="form-control" />
                                        </div>
                                        <ErrorMessage name="internalName" render={(msg) =>
                                            <div id="internalNameError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                                hidden={typeof errors.internalName === "undefined"}
                                            >
                                                <button type="button" className="close" aria-label="Close"
                                                    onClick={() => setFieldError("internalName", undefined)}
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                {msg}
                                            </div>
                                        }
                                        />
                                    </>
                                }

                                {values.type !== "" && !values.isInternal && values.type !== OSM_CODE ?
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="serverUrl" className="font-weight-bold required">
                                                <FormattedMessage id="project.elements.geometricLayer.layer.serverUrl" />
                                            </label>
                                            <Field id="serverUrl" name="serverUrl" className="form-control" />
                                        </div>
                                        <ErrorMessage name="serverUrl" render={(msg) =>
                                            <div id="serverUrlError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                                hidden={typeof errors.serverUrl === "undefined"}
                                            >
                                                <button type="button" className="close" aria-label="Close"
                                                    onClick={() => setFieldError("serverUrl", undefined)}
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                {msg}
                                            </div>
                                        }
                                        />
                                    </>
                                    :
                                    ""
                                }

                                <Field id="geometricLayerCode" name="geometricLayerCode"
                                    disabled={true} hidden={true} />

                                <div className="card" style={{ marginBottom: "1rem" }}>

                                    <h5 className="card-header">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.name" />
                                    </h5>

                                    <div className="card-body">
                                        <div className="form-group">
                                            <div className="input-group mb-3 no-gutters">
                                                <div className="input-group-prepend col-2">
                                                    <span className="input-group-text col-12 required">
                                                        <FormattedMessage id="project.users.locale.galician" />
                                                    </span>
                                                </div>
                                                <Field id="geometricLayerGl" name="geometricLayerGl" as="input" className="form-control" />
                                            </div>
                                            <ErrorMessage name="geometricLayerGl" render={(msg) =>
                                                <div id="geometricLayerGlError" className="alert alert-danger alert-dismissible fade show"
                                                    role="alert" hidden={typeof errors.geometricLayerGl === "undefined"}
                                                >
                                                    <button type="button" className="close" aria-label="Close"
                                                        onClick={() => setFieldError("geometricLayerGl", undefined)}
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                    {msg}
                                                </div>
                                            }
                                            />
                                            <div className="input-group mb-3 no-gutters">
                                                <div className="input-group-prepend col-2">
                                                    <span className="input-group-text col-12 required">
                                                        <FormattedMessage id="project.users.locale.spanish" />
                                                    </span>
                                                </div>
                                                <Field id="geometricLayerEs" name="geometricLayerEs" as="input" className="form-control" />
                                            </div>
                                            <ErrorMessage name="geometricLayerEs" render={(msg) =>
                                                <div id="geometricLayerEsError" className="alert alert-danger alert-dismissible fade show"
                                                    role="alert" hidden={typeof errors.geometricLayerEs === "undefined"}
                                                >
                                                    <button type="button" className="close" aria-label="Close"
                                                        onClick={() => setFieldError("geometricLayerEs", undefined)}
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                    {msg}
                                                </div>
                                            }
                                            />
                                            <div className="input-group mb-3 no-gutters">
                                                <div className="input-group-prepend col-2">
                                                    <span className="input-group-text col-12 required">
                                                        <FormattedMessage id="project.users.locale.english" />
                                                    </span>
                                                </div>
                                                <Field id="geometricLayerEn" name="geometricLayerEn" as="input" className="form-control" />
                                            </div>
                                            <ErrorMessage name="geometricLayerEn" render={(msg) =>
                                                <div id="geometricLayerEnError" className="alert alert-danger alert-dismissible fade show"
                                                    role="alert" hidden={typeof errors.geometricLayerEn === "undefined"}
                                                >
                                                    <button type="button" className="close" aria-label="Close"
                                                        onClick={() => setFieldError("geometricLayerEn", undefined)}
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                    {msg}
                                                </div>
                                            }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="order" className="font-weight-bold required">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.order" />
                                    </label>
                                    <Field type="number" id="order" name="order" className="form-control" />
                                </div>
                                <ErrorMessage name="order" render={(msg) =>
                                    <div id="orderError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.order === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("order", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <Field type="checkbox" id="initiallyVisible" name="initiallyVisible" />
                                        </div>
                                    </div>
                                    <label htmlFor="initiallyVisible" className="btn input-group-text">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.initiallyVisible" />
                                    </label>
                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <Field type="checkbox" id="isInternal" name="isInternal" checked={values.isInternal}
                                                onChange={() => {
                                                    setFieldValue('isInternal', !values.isInternal);
                                                    setFieldValue('serverUrl', "");
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <label htmlFor="isInternal" className="btn input-group-text">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.isInternal" />
                                    </label>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="geometricLayerGroup" className="font-weight-bold required">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.geometricLayerGroup" />
                                    </label>
                                    <Field as="select" id="geometricLayerGroup" name="geometricLayerGroup" className="form-control">
                                        <FormattedMessage id="project.common.selectOneOption">
                                            {(msg) => <option value="">{msg}</option>}
                                        </FormattedMessage>
                                        {this.geometricLayerGroupSelectorRender()}
                                    </Field>
                                </div>
                                <ErrorMessage name="geometricLayerGroup" render={(msg) =>
                                    <div id="geometricLayerGroupError" className="alert alert-danger alert-dismissible fade show"
                                        role="alert" hidden={typeof errors.geometricLayerGroup === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("geometricLayerGroup", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                <div className="form-group">
                                    <label className="font-weight-bold" htmlFor="defaultOpacity">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.opacity.defaultOpacity" />
                                        &nbsp;
                                        <sup id="defaultOpacityHelp"
                                            className="helpTooltip btn-link"
                                            style={{ cursor: "pointer" }}
                                            aria-hidden={true}
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            data-original-title={
                                                this.props.intl.formatMessage({
                                                    id: "project.elements.geometricLayer.layer.opacity.defaultOpacity.help"
                                                })
                                            }
                                        >
                                            <FontAwesomeIcon icon={faQuestionCircle} />
                                        </sup>
                                    </label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">%</div>
                                        </div>
                                        <Field as="input" type="number" id="defaultOpacity" name="defaultOpacity" className="form-control" />
                                    </div>
                                </div>

                                {allowChangeOpacityConfigurationParameter &&
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <Field as="input" type="checkbox" id="allowChangeOpacity" name="allowChangeOpacity" />
                                            </div>
                                        </div>
                                        <label htmlFor="allowChangeOpacity" className="btn input-group-text">
                                            <FormattedMessage id="project.elements.geometricLayer.layer.opacity.allowChangeOpacity.form" />
                                        </label>
                                    </div>
                                }

                                {this.legendTypeRender()}

                                {values.internalName && this.setState({ formikShowAllLayersOnLegend: values.legendType + values.internalName.includes(",") })}

                                {values.legendType === "LEGEND_TYPE_GET_LEGEND_GRAPHIC" && values.internalName && values.internalName.includes(",") ?
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <Field type="checkbox" id="showAllLayersOnLegend" name="showAllLayersOnLegend" />
                                            </div>
                                        </div>
                                        <label htmlFor="showAllLayersOnLegend" className="btn input-group-text">
                                            <FormattedMessage id="project.elements.geometricLayer.layer.showAllLayersOnLegend" />
                                            &nbsp;
                                            <sup id="showAllLayersOnLegendHelp"
                                                className="helpTooltip btn-link"
                                                style={{ cursor: "pointer" }}
                                                aria-hidden={true}
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                data-original-title={
                                                    this.props.intl.formatMessage({
                                                        id: "project.elements.geometricLayer.layer.showAllLayersOnLegend.help"
                                                    })
                                                }
                                            >
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </sup>
                                        </label>
                                    </div>
                                    :
                                    ""
                                }

                                {values.legendType === "LEGEND_TYPE_URL" ?
                                    <>
                                        <div className="form-group">
                                            <label className="font-weight-bold required" htmlFor="legendTypeValue">
                                                <FormattedMessage id="project.common.legendType.url" />
                                            </label>
                                            <Field as="input" id="legendTypeValue" name="legendTypeValue" className="form-control" />
                                        </div>
                                        <ErrorMessage name="legendTypeValue" render={(msg) =>
                                            <div id="legendTypeValueError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                                hidden={typeof errors.legendTypeValue === "undefined"}
                                            >
                                                <button type="button" className="close" aria-label="Close"
                                                    onClick={() => setFieldError("legendTypeValue", undefined)}
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                {msg}
                                            </div>
                                        }
                                        />
                                    </>
                                    :
                                    ""
                                }

                                {values.legendType === 'LEGEND_TYPE_IMAGE' ?
                                    <>
                                        <div className="custom-control custom-radio custom-control-inline mb-2">
                                            <Field type="radio"
                                                id="newImageRadio"
                                                name="existentFile"
                                                className="custom-control-input"
                                                value={false}
                                                checked={!values.existentFile}
                                                onChange={() => setFieldValue("existentFile", false)}
                                            />
                                            <label className="custom-control-label" htmlFor="newImageRadio">
                                                <FormattedMessage id="project.common.legendType.file.unexistent" />
                                            </label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <Field type="radio"
                                                id="existentImageRadio"
                                                name="existentFile"
                                                className="custom-control-input"
                                                value={true}
                                                checked={values.existentFile}
                                                onChange={() => setFieldValue("existentFile", true)}
                                            />
                                            <label className="custom-control-label" htmlFor="existentImageRadio">
                                                <FormattedMessage id="project.common.legendType.file.existent" />
                                            </label>
                                        </div>
                                    </>
                                    : ""
                                }

                                {values.legendType === 'LEGEND_TYPE_IMAGE' && values.existentFile ?
                                    <>
                                        <div className="form-group">
                                            <label className="font-weight-bold required" htmlFor="legendTypeValue">
                                                <FormattedMessage id="project.common.legendType.file" />
                                            </label>
                                            <Field as="input" id="legendTypeValue" name="legendTypeValue" className="form-control" />
                                        </div>
                                        <ErrorMessage name="fileId" render={(msg) =>
                                            <div id="fileIdError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                                hidden={typeof errors.fileId === "undefined"}
                                            >
                                                <button type="button" className="close" aria-label="Close"
                                                    onClick={() => setFieldError("fileId", undefined)}
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                {msg}
                                            </div>
                                        }
                                        />
                                    </>
                                    :
                                    values.legendType === 'LEGEND_TYPE_IMAGE' && !values.existentFile ?
                                        <div className="card mb-2">
                                            <div className="card-body">
                                                <AddModifyGeneralAttachedFileFormFieds
                                                    mustBeImage={true}
                                                    setFieldValue={setFieldValue}
                                                    setFieldError={setFieldError}
                                                    errors={errors}
                                                    setHasAddedFiles={(hasAddedFiles) => this.setState({ hasAddedFiles })}
                                                    files={values.files}
                                                    resetValues={{}}
                                                />
                                            </div>
                                        </div>
                                        :
                                        ""
                                }

                                <div className="form-group">
                                    <label htmlFor="internalComments" className="font-weight-bold">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.internalComments" />
                                    </label>
                                    <Field as="textarea" id="internalComments" name="internalComments" className="form-control" />
                                </div>
                                <ErrorMessage name="internalComments" render={(msg) =>
                                    <div id="internalCommentsError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.internalComments === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("internalComments", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button id="createGeometricLayerSubmit" type="submit" className="btn btn-primary"
                                form="addGeometricLayerForm"
                                disabled={values.legendType === "LEGEND_TYPE_IMAGE" && !this.state.hasAddedFiles && !values.existentFile}
                            >
                                <FormattedMessage id="project.app.Body.accept" />
                            </button>
                            <button id="createGeometricLayerGroupCancel" type="button" className="btn btn-danger"
                                onClick={() => {
                                    this.props.hideModalWindow();
                                    resetForm();
                                }}
                            >
                                <FormattedMessage id="project.app.Body.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                }
            </Formik >
        );
    }
}

AddModifyGeometricLayer.propTypes = {
    modalShow: PropTypes.bool.isRequired,
    geometricLayerToModify: PropTypes.object,
    backendErrors: PropTypes.object,
    setBackendErrors: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hideModalWindow: PropTypes.func.isRequired
}


export default connect(mapStateToProps)(injectIntl(AddModifyGeometricLayer));
