import { config, appFetch } from './appFetch';

export const findAllProvinces = (language, onSuccess) => {
    appFetch('/location/provinces/', config('GET', null, language), onSuccess);
}

export const findAllMunicipalitiesByProvince = (provinceCode, language, onSuccess) => {
    appFetch(`/location/municipality?provinceCode=${provinceCode}`, config('GET', null, language), onSuccess);
}

export const findAllParishesByMunicipality = (provinceCode, municipalityCode, language, onSuccess) => {
    appFetch(`/location/parish?provinceCode=${provinceCode}&municipalityCode=${municipalityCode}`, config('GET', null, language), onSuccess);
}