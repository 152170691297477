import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as actions from '../actions';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import jQuery from 'jquery';
import { Errors } from '../../common';
import { Modal } from 'react-bootstrap';

const Schema = Yup.object().shape({
    currentPassword: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.users.currentPassword" />,
        }} />),
    newPassword: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.users.newPassword" />,
        }} />),
    confirmNewPassword: Yup.string().when("newPassword", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref("newPassword")],
            <FormattedMessage id="project.users.confirmPassword.error" />
        ).required(<FormattedMessage
            id="project.app.Body.field.required"
            values={{
                field: <FormattedMessage id="project.users.confirmNewPassword" />
            }} />),
        otherwise: Yup.string().notRequired()
    }),
});

class ChangePassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formikErrors: null
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.modalShow !== this.props.modalShow) {
            return true;
        }

        if (
            (nextState.formikErrors !== this.state.formikErrors) ||
            (nextProps.backendErrors !== this.props.backendErrors)
        ) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps) {

        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });

        if (this.state.formikErrors || this.props.backendErrors) {
            let container = jQuery('.modal-body');
            if (container.length > 0) {
                if (this.props.backendErrors) {
                    container.scrollTop(0);
                } else {
                    if (this.state.formikErrors) {
                        let field = jQuery('.alert:visible:first').prev();
                        if (field.length)
                            jQuery(container).animate({
                                scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                            });
                    }
                }
            }
        }
    }

    render() {

        return (
            <Formik
                enableReinitialize={true}
                initialValues={{
                    currentPassword: '',
                    newPassword: '',
                    confirmNewPassword: ''
                }}
                validationSchema={Schema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    this.props.dispatch(actions.changePassword(this.props.user.id, values.currentPassword, values.newPassword,
                        () => {
                            resetForm();
                            this.props.hideModalWindow()
                        },
                        errors => {
                            this.props.setBackendErrors(errors);
                        }));
                    setSubmitting(false);
                }}
            >
                {({ errors, setFieldError, resetForm }) =>
                    <Modal show={this.props.modalShow} scrollable={true}
                        onHide={() => {
                            resetForm();
                            this.props.hideModalWindow();
                        }}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="project.users.changePassword" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="needs-validation novalidate" id="changePassword">
                                {errors ? this.setState({ formikErrors: errors }) : ""}
                                <Errors errors={this.props.backendErrors} onClose={() => this.props.setBackendErrors(null)} />

                                <div className="form-group">
                                    <label className="font-weight-bold required" htmlFor="currentPassword">
                                        <FormattedMessage id='project.users.currentPassword' />
                                    </label>
                                    <Field id="currentPassword" name="currentPassword" type="password" className="form-control" />
                                </div>
                                <ErrorMessage name="currentPassword" render={(msg) =>
                                    <div id="currentPasswordError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.currentPassword === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("currentPassword", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                <div className="form-group">
                                    <label className="font-weight-bold required" htmlFor="newPassword">
                                        <FormattedMessage id='project.users.newPassword' />
                                    </label>
                                    <Field id="newPassword" name="newPassword" type="password" className="form-control" />
                                </div>
                                <ErrorMessage name="newPassword" render={(msg) =>
                                    <div id="newPasswordError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.newPassword === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("newPassword", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                <div className="form-group">
                                    <label className="font-weight-bold required" htmlFor="confirmNewPassword">
                                        <FormattedMessage id='project.users.confirmNewPassword' />
                                    </label>
                                    <Field id="confirmNewPassword" name="confirmNewPassword" type="password" className="form-control" />
                                </div>
                                <ErrorMessage name="confirmNewPassword" render={(msg) =>
                                    <div id="confirmNewPasswordError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.confirmNewPassword === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("confirmNewPassword", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="btn btn-primary" form="changePassword">
                                <FormattedMessage id="project.app.Body.save" />
                            </button>
                            <button type="reset" className="btn btn-danger"
                                onClick={() => {
                                    resetForm();
                                    this.props.hideModalWindow();
                                }}
                            >
                                <FormattedMessage id="project.app.Body.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                }
            </Formik>
        );
    }
}

export default withRouter(connect()(ChangePassword));