import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as geometricElementsSelector from '../../geometricElements/selectors'
import * as appSelectors from '../../app/selectors'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import InternationalizationRender, { getInternationalization } from '../../app/components/InternationalizationRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons'
import jQuery from 'jquery';
import filterFactory, { textFilter, selectFilter, Comparator } from 'react-bootstrap-table2-filter';
import LockUserAccount from './LockUserAccount';
import { SignUp } from '..';

const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        user: selectors.getUser(state),
        allRoles: selectors.getAllRoles(state),
        allCodes: geometricElementsSelector.getAllCodes(state),
        totalUserAccounts: selectors.getTotalUserAccounts(state)
    }
}

class AllUserAccounts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            totalUserAccounts: null,
            filters: {},
            sizePerPage: 10,
            backendErrors: null,
            lockUserAccountModalShow: false,
            userAccountToLock: null,
            addUserModalShow: false
        }
    }

    componentDidMount() {
        this.props.dispatch(actions.findAllRoles());
        if (!this.state.totalUserAccounts && this.props.totalUserAccounts) {
            this.setState({ totalUserAccounts: this.props.totalUserAccounts });
        }
        if (!this.state.totalUserAccounts && !this.props.totalUserAccounts) {
            this.props.dispatch(actions.findTotalUserAccount(() => {
                this.setState({ totalUserAccounts: this.props.totalUserAccounts });
            }));
        }
    }

    componentDidUpdate() {
        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });
    }

    render() {

        let setBackendErrors = (backendErrors) => {
            this.setState({ backendErrors: backendErrors });
        }

        let clearAllFilters = (userAccountId) => {
            let { idFilter, loginFilter, roleFilter, lockedFilter } = this.state.filters;
            if (userAccountId) {
                idFilter(`${userAccountId}`);
            } else {
                idFilter('');
            }
            loginFilter('');
            roleFilter('');
            lockedFilter('');
        }

        if (this.props.user) {

            if (this.props.user.userRoleDto.code === "ADMIN" && this.props.allRoles) {

                let booleanFilterOptions = [{
                    value: true,
                    label: this.props.intl.formatMessage({ id: 'project.common.yes' })
                }, {
                    value: false,
                    label: this.props.intl.formatMessage({ id: 'project.common.no' })
                }];

                let rolesFilterOptions = [];
                this.props.allRoles.forEach((role, index) => {
                    rolesFilterOptions[index] = {
                        value: role.code,
                        label: getInternationalization(this.props.language, role.code, this.props.allCodes)
                    }
                })

                jQuery(function () {
                    jQuery('.actionButton').tooltip({ trigger: "hover" });
                });

                jQuery(function () {
                    jQuery('.actionButton').tooltip().click(function () {
                        jQuery('.actionButton').tooltip("hide");
                    });
                });

                const columns = [{
                    dataField: 'id',
                    text: 'Id',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: "id" }),
                        comparator: Comparator.EQ,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.idFilter = filter;
                                return { filters }
                            })
                        }
                    })
                },
                {
                    dataField: 'login',
                    text: this.props.intl.formatMessage({ id: "project.users.username" }),
                    headerAlign: 'left',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.users.username" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.loginFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'userRoleDto.code',
                    text: this.props.intl.formatMessage({ id: "project.users.role" }),
                    headerAlign: 'left',
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={cellContent} listInternationalization={this.props.allCodes} />
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.users.role" }).toLocaleLowerCase() }),
                        options: rolesFilterOptions,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.roleFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'locked',
                    text: this.props.intl.formatMessage({ id: "project.users.locked" }),
                    headerAlign: 'left',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    formatter: (cellContent, row) => (
                        cellContent ? <span><FormattedMessage id="project.common.yes" /></span> : <span><FormattedMessage id="project.common.no" /></span>
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.users.locked" }).toLocaleLowerCase() }),
                        options: booleanFilterOptions,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.lockedFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'action',
                    isDummyField: true,
                    text: this.props.intl.formatMessage({ id: "project.common.action" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <span id={"modify" + row.login}
                            className="actionButton btn-link"
                            onClick={() =>
                                this.setState({
                                    backendErrors: null,
                                    lockUserAccountModalShow: true,
                                    userAccountToLock: row
                                })
                            }
                            style={{
                                cursor: "pointer",
                                marginLeft: "0.5em"
                            }}
                            data-toggle="tooltip"
                            data-placement="right"
                            title={this.props.intl.formatMessage({ id: "project.common.modify" })}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </span>
                    )
                },
                ];

                return (
                    <div className="card">
                        <br />
                        <div className="card-body">
                            <h3><FormattedMessage id="project.app.Header.admin.users" /></h3>
                            <div className="text-right">
                                <div className="dropdown">
                                    <a className="btn btn-primary dropdown-toggle" href="." role="button" id="addGeometricElementDropdownLink"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                        &nbsp;
                                        <FormattedMessage
                                            id="project.users.createUser"
                                        />
                                    </a>

                                    <div className="dropdown-menu" aria-labelledby="addGeometricElementDropdownLink">
                                        {this.props.allRoles.map(role =>
                                            <Link className="dropdown-item"
                                                key={role.code}
                                                id={role.code}
                                                onClick={() => {
                                                    this.props.dispatch(actions.findRoleByCode(role.code));
                                                    this.setState({
                                                        backendErrors: null,
                                                        addUserModalShow: true
                                                    })
                                                }}
                                            >
                                                <InternationalizationRender locale={this.props.language} value={role.code} listInternationalization={this.props.allCodes} />
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Add user dialog */}
                            <SignUp
                                modalShow={this.state.addUserModalShow}
                                backendErrors={this.state.backendErrors}
                                handleAddNewUser={() => {
                                    let addedUserAccount = this.props.totalUserAccounts[this.props.totalUserAccounts.length - 1];
                                    this.setState({ totalUserAccounts: this.props.totalUserAccounts });
                                    if (addedUserAccount) {
                                        clearAllFilters(addedUserAccount.id);
                                    } else {
                                        clearAllFilters();
                                    }
                                }}
                                setBackendErrors={setBackendErrors}
                                hideModalWindow={() => this.setState({ addUserModalShow: false })}
                            />

                            {/* Lock user account dialog */}
                            <LockUserAccount
                                modalShow={this.state.lockUserAccountModalShow}
                                userAccountToLock={this.state.userAccountToLock}
                                backendErrors={this.state.backendErrors}
                                setBackendErrors={setBackendErrors}
                                handleSubmit={() => {
                                    this.setState({
                                        lockUserAccountModalShow: false,
                                        totalUserAccounts: this.props.totalUserAccounts
                                    });
                                    clearAllFilters(this.state.userAccountToLock.id);
                                }}
                                hideModalWindow={() => this.setState({ lockUserAccountModalShow: false })}
                            />

                            <br />
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                columns={columns}
                                data={this.state.totalUserAccounts ?
                                    this.state.totalUserAccounts : []
                                }
                                noDataIndication={this.state.totalUserAccounts ?
                                    this.props.intl.formatMessage({ id: 'project.users.noDataIndication' }) :
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                }
                                defaultSorted={[{
                                    dataField: 'id',
                                    order: 'asc'
                                }]}
                                filter={filterFactory()}
                                filterPosition={"top"}
                                striped
                                condensed
                                rowClasses="text-left"
                                pagination={paginationFactory({
                                    sizePerPage: this.state.currentSize,
                                    showTotal: true,
                                    alwaysShowAllBtns: true,
                                    hidePageListOnlyOnePage: true,
                                    paginationTotalRenderer: (from, to, size) => {
                                        return <FormattedMessage id="project.common.tables.totalElements"
                                            values={{
                                                from: from,
                                                to: to,
                                                total: size
                                            }
                                            }
                                        />
                                    },
                                    onSizePerPageChange: (sizePerPage, page) => {
                                        this.setState({ sizePerPage: sizePerPage })
                                    },
                                    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => (
                                        <li
                                            key={text}
                                            role="presentation"
                                            className={`dropdown-item ${this.state.sizePerPage === Number(text) ? "active" : ""}`}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                onSizePerPageChange(page);
                                            }}
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {text}
                                        </li>
                                    ),
                                    nextPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.nextPageTitle' }),
                                    prePageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.prePageTitle' }),
                                    lastPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.lastPageTitle' }),
                                    firstPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.firstPageTitle' }),
                                })}
                            />
                            <br />
                        </div >
                    </div>
                );
            }

            if (this.props.user.userRoleDto.code !== "ADMIN") {
                return (
                    <div className="container text-center">
                        <br />
                        <div className="card-body alert alert-danger" role="alert">
                            <h4 className="card-text"><FormattedMessage id="project.common.permissionDenied" /></h4>
                        </div>
                    </div>
                );
            }

            return null;
        }

        if (!this.props.user) {
            return (
                <div className="container text-center">
                    <br />
                    <div className="card-body alert alert-danger" role="alert">
                        <h4 className="card-text"><FormattedMessage id="project.common.mustLogin" /></h4>
                    </div>
                </div>
            );
        }

        return null;
    }

}

export default withRouter(connect(mapStateToProps)(injectIntl(AllUserAccounts)));