import React from 'react';
import jQuery from 'jquery';
import { connect } from 'react-redux';

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidUpdate() {
        if (this.props.footerInfo) {
            jQuery('#footerinfo').html(this.props.footerInfo);
        }
        if (this.props.footerText1) {
            jQuery('#footerText1').html(this.props.footerText1);
        }
        if (this.props.footerText2) {
            jQuery('#footerText2').html(this.props.footerText2);
        }
        if (this.props.footerText3) {
            jQuery('#footerText3').html(this.props.footerText3);
        }
        if (this.props.footerText4) {
            jQuery('#footerText4').html(this.props.footerText4);
        }
        if (this.props.footerText5) {
            jQuery('#footerText5').html(this.props.footerText5);
        }
        if (this.props.footerText6) {
            jQuery('#footerText6').html(this.props.footerText6);
        }
        if (this.props.footerText7) {
            jQuery('#footerText7').html(this.props.footerText7);
        }
        if (this.props.footerText8) {
            jQuery('#footerText8').html(this.props.footerText8);
        }
    }

    render() {

        return (
            <footer className="page-footer font-small bg-light pt-4">
                <div className="container-fluid text-center text-md-left">

                    <div className="row justify-content-md-center">

                        {this.props.footerText || this.props.footerImage1 ?
                            <div className="col">

                                <ul className="list-unstyled">
                                    {this.props.footerText1 ?
                                        <li id="footerText1">
                                        </li>
                                        :
                                        ""
                                    }
                                    {this.props.footerImage1 ?
                                        <img src={`${this.props.footerImage1}`} className="img-fluid" alt=""></img>
                                        :
                                        ""
                                    }
                                </ul>

                            </div>
                            :
                            ""
                        }

                        {this.props.footerText2 || this.props.footerImage2 ?
                            <div className="col">

                                <ul className="list-unstyled">
                                    {this.props.footerText2 ?
                                        <li id="footerText2">
                                        </li>
                                        :
                                        ""
                                    }
                                    {this.props.footerImage2 ?
                                        <img src={`${this.props.footerImage2}`} className="img-fluid" alt=""></img>
                                        :
                                        ""
                                    }
                                </ul>

                            </div>
                            :
                            ""
                        }

                        {this.props.footerText3 || this.props.footerImage3 ?
                            <div className="col">

                                <ul className="list-unstyled">
                                    {this.props.footerText3 ?
                                        <li id="footerText3">
                                        </li>
                                        :
                                        ""
                                    }
                                    {this.props.footerImage3 ?
                                        <img src={`${this.props.footerImage3}`} className="img-fluid" alt=""></img>
                                        :
                                        ""
                                    }
                                </ul>

                            </div>
                            :
                            ""
                        }

                        {this.props.footerText4 || this.props.footerImage4 ?
                            <div className="col">

                                <ul className="list-unstyled">
                                    {this.props.footerText4 ?
                                        <li id="footerText4">
                                        </li>
                                        :
                                        ""
                                    }
                                    {this.props.footerImage4 ?
                                        <img src={`${this.props.footerImage4}`} className="img-fluid" alt=""></img>
                                        :
                                        ""
                                    }
                                </ul>

                            </div>
                            :
                            ""
                        }

                    </div>
                    <div className="row">

                        {this.props.footerText5 || this.props.footerImage5 ?
                            <div className="col">

                                <ul className="list-unstyled">
                                    {this.props.footerText5 ?
                                        <li id="footerText5">
                                        </li>
                                        :
                                        ""
                                    }
                                    {this.props.footerImage5 ?
                                        <img src={`${this.props.footerImage5}`} className="img-fluid" alt=""></img>
                                        :
                                        ""
                                    }
                                </ul>

                            </div>
                            :
                            ""
                        }

                        {this.props.footerText6 || this.props.footerImage6 ?
                            <div className="col">

                                <ul className="list-unstyled">
                                    {this.props.footerText6 ?
                                        <li id="footerText6">
                                        </li>
                                        :
                                        ""
                                    }
                                    {this.props.footerImage6 ?
                                        <img src={`${this.props.footerImage6}`} className="img-fluid" alt=""></img>
                                        :
                                        ""
                                    }
                                </ul>

                            </div>
                            :
                            ""
                        }

                        {this.props.footerText7 || this.props.footerImage7 ?
                            <div className="col">

                                <ul className="list-unstyled">
                                    {this.props.footerText7 ?
                                        <li id="footerText7">
                                        </li>
                                        :
                                        ""
                                    }
                                    {this.props.footerImage7 ?
                                        <img src={`${this.props.footerImage7}`} className="img-fluid" alt=""></img>
                                        :
                                        ""
                                    }
                                </ul>

                            </div>
                            :
                            ""
                        }

                        {this.props.footerText8 || this.props.footerImage8 ?
                            <div className="col">

                                <ul className="list-unstyled">
                                    {this.props.footerText8 ?
                                        <li id="footerText8">
                                        </li>
                                        :
                                        ""
                                    }
                                    {this.props.footerImage8 ?
                                        <img src={`${this.props.footerImage8}`} className="img-fluid" alt=""></img>
                                        :
                                        ""
                                    }
                                </ul>

                            </div>
                            :
                            ""
                        }
                    </div>
                </div>
                <div id="footerinfo" className="footer-copyright text-center py-3">
                    &copy; 2020
                </div>
            </footer>
        )
    }
}

export default connect()(Footer);