import React from 'react';
import * as actions from '../actions';
import * as selectors from '../selectors';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import * as appSelectors from '../../app/selectors';
import * as userSelectors from '../../users/selectors';
import * as configurationParameterSelector from '../../configurationParameter/selectors';
import InternationalizationRender, { getInternationalization } from '../../app/components/InternationalizationRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import jQuery from 'jquery';
import filterFactory, { textFilter, Comparator, selectFilter } from 'react-bootstrap-table2-filter';
import { DeleteDialog } from '../../common';
import AddModifyGeometricLayer from './AddModifyGeometricLayer';
import LegendPreviewComponent from './LegendPreviewComponent';

const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        user: userSelectors.getUser(state),
        allCodes: selectors.getAllCodes(state),
        totalGeometricLayer: selectors.getTotalGeometricLayer(state),
        allConfigurationParameters: configurationParameterSelector.getTotalConfigurationParameters(state)
    }
}

class AllGeometricLayer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allGeometricLayer: null,
            totalGeometricLayer: null,
            filters: {},
            sizePerPage: 10,
            backendErrors: null,
            deleteGeometricLayerDialogShow: false,
            deleteGeometricLayerId: "",
            deleteGeometricLayerName: "",
            addModifyGeometricLayerModalShow: false,
            geometricLayerToModify: null,
            previewLegendComponent: null,
            previewLegendModalShow: false
        }
    }

    componentDidMount() {
        if (!this.state.totalGeometricLayer && this.props.totalGeometricLayer) {
            this.setState({ totalGeometricLayer: this.props.totalGeometricLayer });
        }

        if (!this.state.totalGeometricLayer && !this.props.totalGeometricLayer) {
            this.props.dispatch(actions.findTotalGeometricLayer(null, null, null, null, () => {
                this.setState({ totalGeometricLayer: this.props.totalGeometricLayer });
            }));
        }
    }

    componentDidUpdate() {
        jQuery(function () {
            jQuery('.actionButton').tooltip({ trigger: "hover" });
        });

        jQuery(function () {
            jQuery('.actionButton').tooltip().click(function () {
                jQuery('.actionButton').tooltip("hide");
            });
        });
        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });
    }

    render() {

        let setBackendErrors = (backendErrors) => {
            this.setState({ backendErrors: backendErrors });
        }

        let clearFilters = (geometricLayerId) => {
            let { idFilter, internalNameFilter, publicNameFilter, orderFilter,
                initiallyVisibleFilter, typeFilter, internalCommentsFilter, geometricLayerGroupFilter,
                opacityFilter } = this.state.filters;
            if (geometricLayerId) {
                idFilter(`${geometricLayerId}`)
            } else {
                idFilter('');
            }
            internalNameFilter('');
            publicNameFilter('');
            orderFilter('');
            initiallyVisibleFilter('');
            typeFilter('');
            internalCommentsFilter('');
            geometricLayerGroupFilter('');
            opacityFilter('');
        }

        let allowChangeOpacityConfigurationParameter = false;
        if (this.props.allConfigurationParameters) {
            allowChangeOpacityConfigurationParameter = Object.values(this.props.allConfigurationParameters).filter(parameter =>
                parameter.code.indexOf("ALLOW_CONFIGURE_LAYERS_OPACITY") !== -1);
            if (allowChangeOpacityConfigurationParameter.length > 0) {
                allowChangeOpacityConfigurationParameter = allowChangeOpacityConfigurationParameter[0].value;
                allowChangeOpacityConfigurationParameter = (allowChangeOpacityConfigurationParameter === 'true');
            } else {
                allowChangeOpacityConfigurationParameter = true;
            }
        }

        if (this.props.user) {

            let booleanFilterOptions = [{
                value: true,
                label: this.props.intl.formatMessage({ id: 'project.common.yes' })
            }, {
                value: false,
                label: this.props.intl.formatMessage({ id: 'project.common.no' })
            }];

            if (this.props.user.userRoleDto.code === "ADMIN" && this.props.allCodes) {

                let legendTypeFilterOptions = [];
                let allLegendType = this.props.allCodes.filter(code => code.codeGroup.groupCode.indexOf('LEGEND_TYPE_GROUP_CODE') !== -1);
                allLegendType.forEach((legendType, index) => {
                    legendTypeFilterOptions[index] = {
                        value: legendType.code,
                        label: getInternationalization(this.props.language, legendType.code, this.props.allCodes)
                    }
                });

                const columns = [{
                    dataField: 'id',
                    text: 'Id',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    headerAlign: 'left',
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: "id" }),
                        comparator: Comparator.EQ,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.idFilter = filter;
                                return { filters }
                            })
                        },
                    })
                },
                {
                    dataField: 'internalName',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.internalName" }),
                    headerAlign: 'left',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.internalName" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.internalNameFilter = filter;
                                return { filters }
                            })
                        },
                    })
                }, {
                    dataField: 'code.code',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.name" }),
                    headerAlign: 'left',
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    formatter: (cellContent, row) => (
                        <InternationalizationRender
                            locale={this.props.language}
                            value={cellContent}
                            listInternationalization={this.props.allCodes}
                        />
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.name" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.publicNameFilter = filter;
                                return { filters }
                            })
                        },
                    }),
                    filterValue: (cell, row) => getInternationalization(this.props.language, row.code.code, this.props.allCodes)
                }, {
                    dataField: 'order',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.order" }),
                    headerAlign: 'left',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.order" }).toLocaleLowerCase() }),
                        comparator: Comparator.EQ,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.orderFilter = filter;
                                return { filters }
                            })
                        },
                    })
                }, {
                    dataField: 'initiallyVisible',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.initiallyVisible" }),
                    headerAlign: 'left',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    formatter: (cellContent, row) => (
                        cellContent ? <span><FormattedMessage id="project.common.yes" /></span> :
                            <span><FormattedMessage id="project.common.no" /></span>
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.initiallyVisible" }).toLocaleLowerCase() }),
                        options: booleanFilterOptions,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.initiallyVisibleFilter = filter;
                                return { filters }
                            })
                        },
                    })
                }, {
                    dataField: 'serverUrl',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.serverUrl" }),
                    headerAlign: 'left',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    formatter: (cellContent, row) => (
                        cellContent ? <span className="formattedUrl">{cellContent}</span> : <FormattedMessage id="project.elements.geometricLayer.layer.serverUrl.internalLayer" />
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.serverUrl" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.serverUrlFilter = filter;
                                return { filters }
                            })
                        },
                    })
                }, {
                    dataField: 'type.code',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.type" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        row.isInternal || cellContent !== "WMS"?
                            <span>
                                <InternationalizationRender
                                    locale={this.props.language}
                                    value={cellContent}
                                    listInternationalization={this.props.allCodes}
                                />
                            </span>
                            :
                            <span>
                                <InternationalizationRender
                                    locale={this.props.language}
                                    value={cellContent}
                                    listInternationalization={this.props.allCodes}
                                />
                                &nbsp;
                                -
                                &nbsp;
                                {row.version ? row.version : "1.3.0"}
                            </span>
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.type" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.typeFilter = filter;
                                return { filters }
                            })
                        },
                    }),
                    filterValue: (cell, row) =>
                        row.isInternal ?
                            getInternationalization(this.props.language, row.type.code, this.props.allCodes)
                            :
                            `${getInternationalization(this.props.language, row.type.code, this.props.allCodes)} - ${row.version ? row.version : "1.3.0"}`
                }, {
                    dataField: 'opacity',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.opacity" }),
                    headerAlign: 'left',
                    formatExtraData: allowChangeOpacityConfigurationParameter,
                    formatter: (cellContent, row, index, formatExtraData) => (
                        <ul className="list-unstyled">
                            <li>
                                <b><FormattedMessage id="project.elements.geometricLayer.layer.opacity.defaultOpacity" />:</b>
                                <ul className="list-unstyled">
                                    <li>{row.defaultOpacity != null ? `${row.defaultOpacity * 100}%` : 1 * 100 + '%'}</li>
                                </ul>
                            </li>

                            {formatExtraData ?
                                <li>
                                    <b><FormattedMessage id="project.elements.geometricLayer.layer.opacity.allowChangeOpacity.table" />:</b>
                                    <ul className="list-unstyled">
                                        <li>
                                            {row.allowChangeOpacity ?
                                                this.props.intl.formatMessage({ id: "project.common.yes" }) :
                                                this.props.intl.formatMessage({ id: "project.common.no" })}
                                        </li>
                                    </ul>
                                </li>
                                : ""
                            }
                        </ul>
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.opacity" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.opacityFilter = filter;
                                return { filters }
                            })
                        },
                    }),
                    filterValue: (cell, row) =>
                        `${row.defaultOpacity != null ? `${row.defaultOpacity * 100}%` : 1 * 100 + '%'} ${row.allowChangeOpacity ?
                            this.props.intl.formatMessage({ id: "project.common.yes" }) :
                            this.props.intl.formatMessage({ id: "project.common.no" })}`
                }, {
                    dataField: 'legendType',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.legend" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <>
                            <div className="d-block">
                                {row.legendType !== "LEGEND_TYPE_NONE" ?
                                    <>
                                        <span onClick={() => {
                                            this.setState({
                                                previewLegendModalShow: true,
                                                previewLegendComponent: {
                                                    type: "geometricLayer",
                                                    component: row
                                                }
                                            })
                                        }}
                                            className="actionButton btn-link"
                                            style={{
                                                cursor: "pointer"
                                            }}
                                            id={"modify" + row.id}
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title={this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.legend.show" })}
                                        >
                                            <FontAwesomeIcon icon={faEye} />
                                        </span>
                                        &nbsp;
                                    </> : ""
                                }
                                <InternationalizationRender value={cellContent} />
                            </div>
                            {row.legendType === "LEGEND_TYPE_URL" ?
                                <div className="formattedUrl d-block">
                                    {row.legendTypeValue}
                                </div>
                                : ""
                            }
                        </>
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.legend" }).toLocaleLowerCase() }),
                        options: legendTypeFilterOptions,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.legendFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'internalComments',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.internalComments" }),
                    headerAlign: 'left',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    formatter: (cellContent, row) => (
                        cellContent ? cellContent !== "" ? cellContent : "-" : "-"
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.internalComments" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.internalCommentsFilter = filter;
                                return { filters }
                            })
                        },
                    })
                }, {
                    dataField: 'geometricLayerGroup.code.code',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.geometricLayerGroup" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender
                            locale={this.props.language}
                            value={cellContent}
                            listInternationalization={this.props.allCodes}
                        />
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.geometricLayerGroup" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.geometricLayerGroupFilter = filter;
                                return { filters }
                            })
                        },
                    }),
                    filterValue: (cell, row) => getInternationalization(this.props.language, row.geometricLayerGroup.code.code, this.props.allCodes)
                }, {
                    dataField: 'action',
                    isDummyField: true,
                    text: this.props.intl.formatMessage({ id: "project.common.action" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <div className="text-left">
                            <span
                                onClick={() => {
                                    this.setState({
                                        backendErrors: null,
                                        addModifyGeometricLayerModalShow: true,
                                        geometricLayerToModify: row
                                    })
                                }}
                                className="actionButton btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"modify" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.modify" })}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </span>
                            <span onClick={() =>
                                this.setState({
                                    backendErrors: null,
                                    deleteGeometricLayerDialogShow: true,
                                    deleteGeometricLayerId: row.id,
                                    deleteGeometricLayerName: getInternationalization(this.props.language, row.code.code, this.props.allCodes)
                                })
                            }
                                className="actionButton btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"delete" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.delete" })}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </span>
                        </div>
                    )
                },
                ];

                return (
                    <div className="card">
                        <br />
                        <div className="card-body">
                            <h3><FormattedMessage id="project.app.Header.admin.layer" /></h3>
                            <div className="text-right">
                                <button className="btn btn-primary"
                                    onClick={() => {
                                        this.setState({
                                            backendErrors: null,
                                            addModifyGeometricLayerModalShow: true,
                                            geometricLayerToModify: null
                                        })
                                    }}
                                    id="addLayer"
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                    &nbsp;
                                    <FormattedMessage id="project.elements.geometricLayer.layer.add" />
                                </button>
                            </div>

                            {/* Add/modify geometric layer dialog */}
                            <AddModifyGeometricLayer
                                modalShow={this.state.addModifyGeometricLayerModalShow}
                                geometricLayerToModify={this.state.geometricLayerToModify}
                                backendErrors={this.state.backendErrors}
                                setBackendErrors={setBackendErrors}
                                handleSubmit={() => {
                                    this.setState({
                                        addModifyGeometricLayerModalShow: false,
                                        totalGeometricLayer: this.props.totalGeometricLayer
                                    });
                                    if (this.state.geometricLayerToModify) {
                                        clearFilters(this.state.geometricLayerToModify.id);
                                    } else {
                                        let newGeometricLayer =
                                            this.props.totalGeometricLayer[this.props.totalGeometricLayer.length - 1];
                                        if (newGeometricLayer && newGeometricLayer.id) {
                                            clearFilters(newGeometricLayer.id);
                                        } else {
                                            clearFilters();
                                        }
                                    }
                                }}
                                hideModalWindow={() => this.setState({ addModifyGeometricLayerModalShow: false })}
                            />

                            {/* Delete geometric layer dialog */}
                            <DeleteDialog
                                modalShow={this.state.deleteGeometricLayerDialogShow}
                                title={this.props.intl.formatMessage({ id: 'project.common.delete' })}
                                details={this.props.intl.formatMessage(
                                    { id: 'project.elements.delete.message' },
                                    { element: <b>{this.state.deleteGeometricLayerName}</b> })
                                }
                                backendErrors={this.state.backendErrors}
                                hideModalWindow={() => { this.setState({ deleteGeometricLayerDialogShow: false }) }}
                                handleSubmit={() => {
                                    this.props.dispatch(actions.deleteGeometricLayer(this.state.deleteGeometricLayerId,
                                        () => {
                                            this.setState({
                                                deleteGeometricLayerDialogShow: false,
                                                totalGeometricLayer: this.props.totalGeometricLayer
                                            });
                                        },
                                        (error) => setBackendErrors(error)))
                                }}
                                setBackendErrors={setBackendErrors}
                            />
                            {/* Preview legend */}
                            <LegendPreviewComponent
                                modalShow={this.state.previewLegendModalShow}
                                previewLegendComponent={this.state.previewLegendComponent}
                                hideModalWindow={() => {
                                    this.setState({
                                        previewLegendModalShow: false,
                                        previewLegendComponent: null
                                    });
                                }}
                            />
                            <br />
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                columns={columns}
                                rowClasses="text-left"
                                data={this.state.totalGeometricLayer ?
                                    this.state.totalGeometricLayer : []
                                }
                                noDataIndication={this.state.totalGeometricLayer ?
                                    this.props.intl.formatMessage(
                                        { id: "project.elements.geometricLayer.layer.noElements" }) :
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                }
                                defaultSorted={[{
                                    dataField: 'id',
                                    order: 'asc'
                                }]}
                                filter={filterFactory()}
                                filterPosition={"top"}
                                striped
                                condensed
                                pagination={paginationFactory({
                                    sizePerPage: this.state.currentSize,
                                    showTotal: true,
                                    alwaysShowAllBtns: true,
                                    hidePageListOnlyOnePage: true,
                                    paginationTotalRenderer: (from, to, size) => {
                                        return <FormattedMessage id="project.common.tables.totalElements"
                                            values={{
                                                from: from,
                                                to: to,
                                                total: size
                                            }
                                            }
                                        />
                                    },
                                    onSizePerPageChange: (sizePerPage, page) => {
                                        this.setState({ sizePerPage: sizePerPage })
                                    },
                                    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => (
                                        <li
                                            key={text}
                                            role="presentation"
                                            className={`dropdown-item ${this.state.sizePerPage === Number(text) ? "active" : ""}`}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                onSizePerPageChange(page);
                                            }}
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {text}
                                        </li>
                                    ),
                                    nextPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.nextPageTitle' }),
                                    prePageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.prePageTitle' }),
                                    lastPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.lastPageTitle' }),
                                    firstPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.firstPageTitle' }),
                                })}
                            />
                        </div>
                        <br />
                    </div>
                );
            }

            if (this.props.user.userRoleDto.code !== "ADMIN") {
                return (
                    <div className="container text-center">
                        <br />
                        <div className="card-body alert alert-danger" role="alert">
                            <h4 className="card-text"><FormattedMessage id="project.common.permissionDenied" /></h4>
                        </div>
                    </div>
                );
            }

            return null;

        }

        if (!this.props.user) {
            return (
                <div className="container text-center">
                    <br />
                    <div className="card-body alert alert-danger" role="alert">
                        <h4 className="card-text"><FormattedMessage id="project.common.mustLogin" /></h4>
                    </div>
                </div>
            );
        }

        return null;

    }
}

export default withRouter(connect(mapStateToProps)(injectIntl(AllGeometricLayer)));