import React from 'react';
import PropTypes from 'prop-types';
import {useIntl, FormattedMessage} from 'react-intl';
import { Modal, Button } from 'react-bootstrap';
import {NetworkError} from '../../../backend';

const ErrorDialog = ({error, onClose}) => {

    const intl = useIntl();
    let show = true;

    if(error == null) {
        return null;
    }

    const message = error instanceof NetworkError ? 
        intl.formatMessage({id: 'project.global.exceptions.NetworkError'}) :
        error.message;
    
    return (
        <Modal role="dialog" show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="project.common.ErrorDialog.title"/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-primary" 
                    data-dismiss="modal" 
                    onClick={() => {
                        onClose();
                    }}
                >
                    <FormattedMessage id="project.common.close"/>
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ErrorDialog.propTypes = {
    error: PropTypes.object,
    onClose: PropTypes.func.isRequired
}

export default ErrorDialog;
