import { config, appFetch } from './appFetch';

export const addGeometricLayerGroup = (json, language, onSuccess, onErrors) =>
    appFetch(`/geometricLayers/geometric_layer_group`, config('POST', json, language), onSuccess, onErrors);

export const modifyGeometricLayerGroup = (id, json, language, onSuccess, onErrors) =>
    appFetch(`/geometricLayers/geometric_layer_group/${id}`, config('POST', json, language), onSuccess, onErrors);

export const findAllGeometricLayerGroup = (page, size, sortField, sortOrder, language, onSuccess, onErrors) => {
    let path = `/geometricLayers/geometric_layer_group`;

    if (page) {
        path += `?page=${page}`;
    }

    if (size) {
        if (page) {
            path += `&size=${size}`;
        } else {
            path += `?size=${size}`;
        }
    }

    if (sortField) {
        if (page || size) {
            path += `&sortField=${sortField}`;
        } else {
            path += `?sortField=${sortField}`;
        }
    }

    if (sortOrder) {
        if (page || size || sortField) {
            path += `&sortOrder=${sortOrder}`;
        } else {
            path += `?sortOrder=${sortOrder}`;
        }
    }
    appFetch(path, config('GET', null, language), onSuccess, onErrors);
}

export const deleteGeometricLayerGroup = (id, language, onSuccess, onErrors) =>
    appFetch(`/geometricLayers/geometric_layer_group/${id}`, config('DELETE', null, language), onSuccess, onErrors);

export const addGeometricLayer = (json, language, onSuccess, onErrors) =>
    appFetch(`/geometricLayers/geometric_layer`, config('POST', json, language), onSuccess, onErrors);

export const modifyGeometricLayer = (id, json, language, onSuccess, onErrors) =>
    appFetch(`/geometricLayers/geometric_layer/${id}`, config('POST', json, language), onSuccess, onErrors);

export const findAllGeometricLayer = (page, size, sortField, sortOrder, language, onSuccess, onErros) => {
    let path = `/geometricLayers/geometric_layer`;

    if (page) {
        path += `?page=${page}`;
    }

    if (size) {
        if (page) {
            path += `&size=${size}`;
        } else {
            path += `?size=${size}`;
        }
    }

    if (sortField) {
        if (page || size) {
            path += `&sortField=${sortField}`;
        } else {
            path += `?sortField=${sortField}`;
        }
    }

    if (sortOrder) {
        if (page || size || sortField) {
            path += `&sortOrder=${sortOrder}`;
        } else {
            path += `?sortOrder=${sortOrder}`;
        }
    }
    appFetch(path, config('GET', null, language), onSuccess, onErros);
}

export const deleteGeometricLayer = (id, language, onSuccess, onErrors) =>
    appFetch(`/geometricLayers/geometric_layer/${id}`, config('DELETE', null, language), onSuccess, onErrors);