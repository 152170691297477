import React from 'react';
import PropTypes from 'prop-types';

const Errors = ({errors, onClose}) => {

    if(!errors){
        return null;
    }

    let globalError;
    let fieldErrors;

    if(errors.globalError) {
        globalError = errors.globalError;
    } else if (errors.fieldErrors){
        fieldErrors = [];
        // eslint-disable-next-line
        errors.fieldErrors.map(e => {
            fieldErrors.push(`${e.fieldName}: ${e.message}`);
        });
    }

    return (
        <div className="alert alert-danger alert-dismissible fade show" role="alert" id="backendErrors">
            {globalError ? globalError : ''}

            {fieldErrors ?
                <ul>
                    {fieldErrors.forEach((fieldError, index) =>
                        <li key={index}>{fieldError}</li>
                    )}
                </ul>
            : ""
            }

            <button type="button" className="close" data-dismiss="alert" aria-label="Close"
                onClick={() => onClose()}
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    );
}

Errors.propTypes = {
    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func.isRequired
};

export default Errors;