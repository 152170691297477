import { combineReducers } from 'redux';

import * as actionTypes from './actionTypes';

const initialState = {
    geometricElementType: null,
    geometricElementsByElementType: null,
    allCodes: null,
    listProvinces: null,
    listCouncils: null,
    listParishes: null,
    listEntityPopulations: null,
    listCounties: null,
    allGeometricLayer: null,
    totalGeometricElementType: null,
    totalAttributeType: null,
    totalGeometricLayer: null,
    totalGeometricLayerGroup: null,
    mapCurrentExtent: null,
    geometricElementRelations: null,
    geometricElement: null
};

const geometricElementType = (state = initialState.geometricElementType, action) => {
    switch (action.type) {
        case actionTypes.FIND_GEOMETRIC_ELEMENT_TYPE_BY_ID_COMPLETED:
            return action.geometricElementType;
        default:
            return state;
    }
}

const geometricElementsByElementType = (state = initialState.geometricElementsByElementType, action) => {
    switch (action.type) {
        case actionTypes.FIND_PUBLIC_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED:
            return action.geometricElementsByElementType;
        case actionTypes.FIND_ADMIN_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED:
            return action.geometricElementsByElementType;
        case actionTypes.FIND_USER_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED:
            return action.geometricElementsByElementType;
        default:
            return state;
    }
}

const allCodes = (state = initialState.allCodes, action) => {
    switch (action.type) {
        case actionTypes.FIND_ALL_CODES_COMPLETED:
            return action.allCodes;
        default:
            return state;
    }
}

const totalGeometricElementType = (state = initialState.totalGeometricElementType, action) => {
    switch (action.type) {
        case actionTypes.FIND_TOTAL_GEOMETRIC_ELEMENT_TYPE_COMPLETED:
            return action.totalGeometricElementType ? action.totalGeometricElementType.items : null;
        case actionTypes.ADD_GEOMETRIC_ELEMENT_TYPE_COMPLETED: {
            const { legendFile, ...addedGeometricElementType } = action.addedGeometricElementType;
            return [...state, addedGeometricElementType];
        }
        case actionTypes.MODIFY_GEOMETRIC_ELEMENT_TYPE_COMPLETED: {
            const { legendFile, ...modifiedGeometricElementType } = action.modifiedGeometricElementType;
            let index = state.map(function (geometricElementType) { return geometricElementType.id })
                .indexOf(modifiedGeometricElementType.id);
            state[index] = modifiedGeometricElementType;
            return state;
        }
        case actionTypes.DELETE_GEOMETRIC_ELEMENT_TYPE_COMPLETED:
            return state.filter(geometricElementType => geometricElementType.id !== action.geometricElementTypeId);
        default:
            return state;
    }
}

const totalAttributeType = (state = initialState.totalAttributeType, action) => {
    switch (action.type) {
        case actionTypes.FIND_TOTAL_ATTRIBUTE_TYPE_COMPLETED:
            return action.totalAttributeType ? action.totalAttributeType.items : null;
        case actionTypes.ADD_ATTRIBUTE_TYPE_COMPLETED:
            return [...state, action.addedAttributeType];
        case actionTypes.MODIFY_ATTRIBUTE_TYPE_COMPLETED:
            let index = state.map(function (attributeType) { return attributeType.id })
                .indexOf(action.modifiedAttributeType.id);
            return state.map((currentAttributeType, currentIndex) =>
                index === currentIndex ? action.modifiedAttributeType : currentAttributeType);
        case actionTypes.DELETE_ATTRIBUTE_TYPE_COMPLETED:
            return state.filter(attributeType => attributeType.id !== action.attributeTypeId);
        default:
            return state;
    }
}


//Center by population entities reducers
const listProvinces = (state = initialState.listProvinces, action) => {
    switch (action.type) {
        case actionTypes.LIST_PROVINCES:
            return action.listProvinces;
        default:
            return state;
    }
}

const listCouncils = (state = initialState.listCouncils, action) => {
    switch (action.type) {
        case actionTypes.LIST_COUNCILS:
            return action.listCouncils;
        default:
            return state;
    }
}

const listParishes = (state = initialState.listParishes, action) => {
    switch (action.type) {
        case actionTypes.LIST_PARISHES:
            return action.listParishes;
        default:
            return state;
    }
}

const listEntityPopulations = (state = initialState.listEntityPopulations, action) => {
    switch (action.type) {
        case actionTypes.LIST_ENTITY_POPULATIONS:
            return action.listEntityPopulations;
        default:
            return state;
    }
}

const listCounties = (state = initialState.listCounties, action) => {
    switch (action.type) {
        case actionTypes.LIST_COUNTIES:
            return action.listCounties;
        default:
            return state;
    }
}

//GeometricLayerService reducers
const totalGeometricLayerGroup = (state = initialState.totalGeometricLayerGroup, action) => {
    let index;
    switch (action.type) {
        case actionTypes.FIND_TOTAL_GEOMETRIC_LAYER_GROUP_COMPLETED:
            return action.totalGeometricLayerGroup ? action.totalGeometricLayerGroup.items : null;
        case actionTypes.ADD_GEOMETRIC_LAYER_GROUP_COMPLETED:
            return [...state, action.addedGeometricLayerGroup];
        case actionTypes.MODIFY_GEOMETRIC_LAYER_GROUP_COMPLETED:
            index = state.map(function (geometricLayerGroup) { return geometricLayerGroup.id })
                .indexOf(action.modifiedGeometricLayerGroup.id);
            return state.map((currentGeometricLayerGroup, currentIndex) =>
                index === currentIndex ? action.modifiedGeometricLayerGroup : currentGeometricLayerGroup);
        case actionTypes.DELETE_GEOMETRIC_LAYER_GROUP_COMPLETED:
            return state.filter(geometricLayerGroup => geometricLayerGroup.id !== action.geometricLayerGroupId);
        case actionTypes.ADD_GEOMETRIC_LAYER_COMPLETED: {
            let addedGeometricLayerGeometricLayerGoup = action.addedGeometricLayer.geometricLayerGroup;
            index = state.map(function (geometricLayer) { return geometricLayer.id })
                .indexOf(addedGeometricLayerGeometricLayerGoup.id);
            let newTotalGeometricLayerGroup = [...state];
            let newListGeometricLayers = newTotalGeometricLayerGroup[index].listGeometricLayer;
            let { legendFile, ...addedGeometricLayer } = action.addedGeometricLayer;
            newListGeometricLayers.push(addedGeometricLayer);
            newTotalGeometricLayerGroup[index].listGeometricLayer = newListGeometricLayers;
            return newTotalGeometricLayerGroup;
        }
        case actionTypes.MODIFY_GEOMETRIC_LAYER_COMPLETED: {
            let modifiedGeometricLayerGeometricLayerGoup = action.modifiedGeometricLayer.geometricLayerGroup;
            index = state.findIndex(geometricLayerGroup => geometricLayerGroup.id === modifiedGeometricLayerGeometricLayerGoup.id);
            let layerIndex = state[index].listGeometricLayer.findIndex(geometricLayer => geometricLayer.id === action.modifiedGeometricLayer.id);
            let totalGeometricLayerGroupCopy = [...state];
            let { legendFile, ...modifiedGeometricLayer } = action.modifiedGeometricLayer;
            totalGeometricLayerGroupCopy[index].listGeometricLayer[layerIndex] = modifiedGeometricLayer;
            return totalGeometricLayerGroupCopy;
        }
        case actionTypes.DELETE_GEOMETRIC_LAYER_COMPLETED:
            return state.map(geometricLayerGroup => {
                geometricLayerGroup.listGeometricLayer =
                    geometricLayerGroup.listGeometricLayer.filter(geometricLayer => geometricLayer.id !== action.geometricLayerId);
                return geometricLayerGroup;
            });
        default:
            return state;
    }
}

const totalGeometricLayer = (state = initialState.totalGeometricLayer, action) => {
    switch (action.type) {
        case actionTypes.FIND_TOTAL_GEOMETRIC_LAYER_COMPLETED:
            return action.totalGeometricLayer ? action.totalGeometricLayer.items : null;
        case actionTypes.ADD_GEOMETRIC_LAYER_COMPLETED: {
            let { legendFile, ...addedGeometricLayer } = action.addedGeometricLayer;
            return [...state, addedGeometricLayer];
        }
        case actionTypes.MODIFY_GEOMETRIC_LAYER_COMPLETED: {
            let { legendFile, ...modifiedGeometricLayer } = action.modifiedGeometricLayer;
            let index = state.map(function (geometricLayer) { return geometricLayer.id })
                .indexOf(modifiedGeometricLayer.id);
            state[index] = modifiedGeometricLayer;
            return state;
        }
        case actionTypes.DELETE_GEOMETRIC_LAYER_COMPLETED:
            return state.filter(geometricLayer => geometricLayer.id !== action.geometricLayerId);
        default:
            return state;
    }
}

const mapCurrentExtent = (state = initialState.mapCurrentExtent, action) => {
    switch (action.type) {
        case actionTypes.MAP_CURRENT_EXTENT:
            return action.mapCurrentExtent;
        default:
            return state;
    }
}

//Geometric element relations
const geometricElementRelations = (state = initialState.geometricElementRelations, action) => {
    switch (action.type) {
        case actionTypes.FIND_PUBLIC_GEOMETRIC_ELEMENT_RELATION_COMPLETED:
            return action.geometricElementRelations;
        case actionTypes.FIND_USER_GEOMETRIC_ELEMENT_RELATION_COMPLETED:
            return action.geometricElementRelations;
        case actionTypes.FIND_ADMIN_GEOMETRIC_ELEMENT_RELATION_COMPLETED:
            return action.geometricElementRelations;
        default:
            return state;
    }
}

//Geometric element by id
const geometricElement = (state = initialState.geometricElement, action) => {
    switch (action.type) {
        case actionTypes.FIND_PUBLIC_GEOMETRIC_ELEMENT_BY_ID_COMPLETED:
            return action.geometricElement;
        case actionTypes.FIND_USER_GEOMETRIC_ELEMENT_BY_ID_COMPLETED:
            return action.geometricElement;
        case actionTypes.FIND_ADMIN_GEOMETRIC_ELEMENT_BY_ID_COMPLETED:
            return action.geometricElement;
        default:
            return state;
    }
}

const reducer = combineReducers({
    geometricElementType,
    geometricElementsByElementType,
    allCodes,
    listProvinces,
    listCouncils,
    listParishes,
    listEntityPopulations,
    listCounties,
    totalAttributeType,
    totalGeometricElementType,
    totalGeometricLayer,
    totalGeometricLayerGroup,
    mapCurrentExtent,
    geometricElementRelations,
    geometricElement
});

export default reducer;