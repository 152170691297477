import React from 'react';
import { Link } from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

class Component404 extends React.Component {
    render(){
        return(
            <div className="container text-center">
                <div className="card-body">
                    <h1 className="head404">404</h1>
                    <h1 className="headline">Oops!</h1>
                    <Link to="/" className="card-text">
                        <FormattedMessage id="project.app.Body.return"/>
                    </Link>
                </div>
            </div>
        )
    }
}

export default Component404;